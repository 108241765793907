//
//
//--------------
// ** gallery with 2 img's
//--------------
// 2 img's on the desktop and tablet, 1 on the mobile devices
//
//
.gallery-container-collection__2X {
  width: 100%;
  padding: 20px 0;
  min-height: 10vh;

  // background-color: pink;
  .gallery-lg-card-container-collection__2X {
    // ----- this to flexes end here below
    // are to position the div boxes on the bottom of the div, instead of the top
    justify-content: end;
    align-items: end;
    // --------
    width: 100%;
    height: 100%;
    // background-color: RED;
    padding: 5px 0;
    // ** GRID
    display: grid;
    grid-template-columns: 1fr 1fr; //1fr 2fr 1fr Here, you specify the columns with different widths, another option: 1fr 1fr 1fr 1fr;, will create 4 columns of the same size, but its not cool for mobile
    gap: 10px; // Adjust the gap between the boxes
    //--------- media queries
    //--------- **
    // -------- **
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      // background-color: rgb(101, 198, 186);

      grid-template-columns: 1fr 1fr;
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      // background-color: cadetblue;

      grid-template-columns: 1fr 1fr;
    }
    // -------- **
    // -------- **

    // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      // background-color: orangered;

      grid-template-columns: 1fr 1fr;
    }
    //
    // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      // background-color: rgb(143, 225, 225);

      grid-template-columns: 1fr 1fr;
    }
    //---------
    //--------- **
    // -------- **
    //
    // -------  UL - card --------
    .card-gallery-collection__2X {
      width: 98%;
      // background-color: red;
      overflow: hidden;
      height: auto;
      //   padding: 1% 0 0.1% 0;

      //
      // LI
      .img-container-gallery-collection__2X {
        // padding: 20px 10px;
        width: 100%;
        position: relative;
        overflow: hidden;
        border-radius: 30px;
        //
        //
        .img-box-gallery-collection__2X {
          width: 100%;
          height: 100%;
          overflow: hidden;
          // border: 3.5px solid rgba(45, 42, 38, 0.829);
          //------------------------

          border-radius: 30px;

          //
          // ----- dots pattern BG -----
          background-image: radial-gradient(rgba(246, 243, 236, 0.182) 1px);
          background-size: 10px 10px;
          // background-color: rgba(0, 0, 0, 0);
          // ----- dots pattern BG -----

          img {
            width: 100%;
            min-height: auto;
            object-fit: cover;
            // 0.57 very large imgs, 3.57/1 extremely small rectables
            aspect-ratio: 0.77/1; // square
            display: block;
            // aspect-ratio: 1.57/1; // kubrick 1 option
            cursor: pointer;
          }
        }
      }
    }
  }
}

//🍭 end gallery 4 imgs
//--------------------
