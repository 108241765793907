// you have the styles for 2 comps , so be careful

#wrapper-fixedImg-about {
  width: 100%;

  min-height: 48vh;
  height: 48vh;
  padding: 3vh 0 0 0;
  margin: 10px 0 0 0;
  background-color: #f0efeb;
  background-position: center;

  //
  position: relative;
  overflow: hidden;

  .overlay-about {
    width: 250%;
    height: 250%;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 100;
    pointer-events: none;
    //
    background-color: rgba(0, 0, 0, 0.326);
    z-index: 100;
    pointer-events: none;

    background: linear-gradient(
        0deg,
        rgba(58, 56, 52, 0.032),
        rgba(133, 168, 255, 0.018)
      ),
      url(https://grainy-gradients.vercel.app/noise.svg);
    filter: contrast(100%) brightness(30%);
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 100;
    pointer-events: none;
    //
    border-radius: 100px;
    border: 20px solid transparent;
    box-shadow: 0 0 0 0.1em transparent, inset 0 0 0 0.1em #eeeeee29;
    //   //
  }

  //
  // --------
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    background-color: sienna;

    display: none;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    background-color: lightblue;

    display: none;
  }

  // --------
  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    display: none;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: blue;
    display: none;
  }

  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: teal;
    display: none;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    //  background-color: powderblue;
    display: none;
  }
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: powderblue;
    display: none;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olive;
    display: none;
  }
  //-------------**
  //-------------**
  //-------------
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: fuchsia;
    width: 100%;
    display: block;
    background-position: bottom;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: yellow;
    display: block;
    width: 100%;
    background-position: center;
  }

  // .containerAttachedFooterImg {
  // }
}

//
//
//--------------------------
// ----- DANAE fixed styles
//

#wrapperFixedImg-cabinet {
  width: 100%;

  min-height: 80vh;
  height: 75vh;
  padding: 3vh 0 0 0;
  margin: 10px 0 0 0;
  background-color: #f0efeb;
  background-position: center;

  //
  // --------
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    background-color: sienna;

    display: none;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    background-color: lightblue;

    display: none;
  }

  // --------
  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    display: none;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: blue;
    display: none;
  }

  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: teal;
    display: none;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    //  background-color: powderblue;
    display: none;
  }
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: powderblue;
    display: none;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olive;
    display: none;
  }
  //-------------**
  //-------------**
  //-------------
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: fuchsia;
    width: 100%;
    display: block;
    background-position: bottom;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: yellow;
    display: block;
    width: 100%;
    background-position: center;
  }
}

/*


https://shapedividers.com/?dividerDirection=top&longAxisValue=100&shortAxisValue=90&positionValue=50&flipped=false&animate=false&animLength=10&shapeColor=fdfbf3&si=53&shapeRatio=false&mobileReady=true&tabletDividerDirection=top&tabletLongAxisValue=100&tabletShortAxisValue=90&tabletPositionValue=50&tabletFlipped=false&tabletAnimate=false&tabletAnimLength=10&tabletShapeColor=fbd8c2&tsi=1&tabletShapeRatio=false&mobileDividerDirection=top&mobileLongAxisValue=100&mobileShortAxisValue=90&mobilePositionValue=50&mobileFlipped=false&mobileAnimate=false&mobileAnimLength=10&mobileShapeColor=fbd8c2&msi=1&mobileShapeRatio=false

#wrapper-fixedImg-about {
  width: 100%;

  min-height: 48vh;
  height: 48vh;
  padding: 3vh 0 0 0;
  margin: 10px 0 0 0;
  background-color: #f0efeb;
  background-position: center;

  //
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    font-family: "shape divider from ShapeDividers.com";
    position: absolute;
    bottom: -1px;
    left: -1px;
    right: -1px;
    top: -1px;
    z-index: 3;
    pointer-events: none;
    background-repeat: no-repeat;
    background-size: 100% 90px;
    background-position: 50% 0%;
    background-image: url('data:image/svg+xml;charset=utf8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.28 2.17" preserveAspectRatio="none"><path d="M0 .5c3.07.55 9.27-.42 16.14 0 6.88.4 13.75.57 19.14-.11V0H0z" fill="fdfbf3"/><path d="M0 1c3.17.8 7.29-.38 10.04-.55 2.75-.17 9.25 1.47 12.67 1.3 3.43-.17 4.65-.84 7.05-.87 2.4-.02 5.52.88 5.52.88V0H0z" opacity=".5" fill="fdfbf3"/><path d="M0 1.85c2.56-.83 7.68-.3 11.79-.42 4.1-.12 6.86-.61 9.58-.28 2.73.33 5.61 1.17 8.61 1 3-.19 4.73-.82 5.3-.84V.1H0z" opacity=".5" fill="fdfbf3"/></svg>');





*/
