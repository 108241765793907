// HEADLINE WELCOME to meyoko and The Furies
@import "../../scss/font-sizes-welcome";
//
//
//
//
#wrapper_headlineSection_welcome {
  // background-color: rgb(156, 197, 133);
  // min-height: 190vh;
  width: 100%;
  min-height: 50vh;
  margin: 20px 0 0 0;
  padding: 40px 0 40px 0;
  // --------
  // MOBILE
  @media only screen and (max-width: 680px) and (min-width: 170px) {
    padding: 20px 0 20px 0;
    min-height: 30vh;
  }
  // --------
  // TABLET1
  @media only screen and (max-width: 992px) and (min-width: 681px) {
    // background-color: lavender;
    padding: 20px 0 20px 0;
    min-height: 20vh;
  }

  //
  // --------
  // TABLET2 / LAPTOP   768 x 360 device
  @media only screen and (max-width: 1366px) and (min-width: 993px) {
    // background-color: olive;
    padding: 20px 0 20px 0;
    min-height: 20vh;
  }
  //
  //-------------
  // DESKTOP
  //  1440 x 900
  // http://responsivetesttool.com/
  //
  @media only screen and (max-width: 1666px) and (min-width: 1367px) {
    // background-color: green;
    padding: 20px 0 20px 0;
    min-height: 30vh;
  }
  //-------------
  // DESKTOP large / 1920 x 1080 (mine)
  @media only screen and (max-width: 1966px) and (min-width: 1667px) {
    // background-color: turquoise;
    padding: 20px 0 20px 0;
    min-height: 50vh;
  }
  //
  //
  //---------
  .is--containerHeadline-section_welcome-white,
  .is--containerHeadline-section_welcome-black {
    width: 95%;
    height: 100%;
    min-height: auto;
    margin: 0 auto;
    // background-color: orange;
  }

  #container-headline-welcome-grid {
    // justify-content: end;
    // align-items: end;
    // --------
    padding: 10px 0;
    // ** GRID  --------
    display: grid;
    grid-template-columns: 2fr 1fr;

    gap: 10px;

    // -------- **
    // -------- **
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      // background-color: rgb(193, 220, 217);
      grid-template-columns: 1fr;
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      // background-color: rgb(0, 0, 255);
      grid-template-columns: 1fr;
    }

    // -------- **
    // -------- **

    // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      // background-color: orangered;
      grid-template-columns: 1fr;
    }
    //
    // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      // background-color: rgb(204, 144, 32);
      grid-template-columns: 1fr;
    }
    //-------------**
    //-------------**
    // TABLET2 - PORTRAIT 994 x 1367 (on google) or (1024 x 600 [ Desktop/Laptop ])  http://responsivetesttool.com/?url=http://localhost:6255/
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      // background-color: teal;
      grid-template-columns: 1.7fr 0.3fr;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      // background-color: rgb(255, 225, 0);
      grid-template-columns: 1.7fr 0.3fr;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT  ( row , desktop > )
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      // background-color: purple;
      grid-template-columns: 1.5fr 0.5fr;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      // background-color: olivedrab;
      grid-template-columns: 1.5fr 0.5fr;
    }
    //-------------**
    //-------------**

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      // background-color: pink;
      grid-template-columns: 1.5fr 0.5fr;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      // background-color: rgb(9, 87, 230);
      grid-template-columns: 1.5fr 0.5fr;
    }

    //-------------**
    //-------------**
  }

  /*



  */
  .is--containerHeadline-section_welcome-white,
  .is--containerHeadline-section_welcome-black {
    //
    #container-welcome-left {
      display: flex;
      flex-direction: column;
      align-self: flex-end;

      /*

                  FONT - TEXT



      */
      // ((H1))
      // ** lARGE text "Welcome"
      h1 {
        transition: 1s all ease-in-out;

        // ---- Font color ---------
        // depends on the section-2 or section-3
        // -------------------------
        // if section-3 is on VIEW and BG is black:
        // font color will be white.
        // if section-2 is on VIEW and BG is white:
        // font color will be black.
        &#is--h1-section_welcome-white {
          color: #fefcf6;
          // color: #f1735c;
          // color: #ec5151;
        }
        &#is--h1-section_welcome-black {
          color: #0d0d0d;
        }
        //
        //
        //
        &#is--h1-section_welcome-white,
        &#is--h1-section_welcome-black {
          // opacity: 0.9;
          // will-change: "opacity, transform";
          //
          &.is-line-1_welcome-white,
          &.is-line-1_welcome-black,
          &.is-line-2_welcome-white,
          &.is-line-2_welcome-black {
            padding: 0;
            margin: 0;
            font-family: "Wagon-Bold";
            font-family: "Kenoky-Light";

            font-weight: 400;
            letter-spacing: -2px;
            //
          }
          /*




          */
          // **  -------- * --------
          // 'THE' or 'welcome' on black section
          // **  -------- * --------
          //
          &.is-line-1_welcome-white {
            // background-color: rgb(5, 5, 5);
            letter-spacing: -10px;
            font-size: calc(395px + 1.1vmin);
            line-height: calc(390px + 1.1vmin);
            // -------- **
            // -------- **
            // ** FONT SIZE
            //  MEDIA queries (portrait and landscape)
            // Here you will find all, check the file on the scss folder
            @include Headline_H1_Welcome_and_The;
          }
          // **  -------- * --------
          // 'THE' or 'welcome' on white section
          // **  -------- * --------
          &.is-line-1_welcome-black {
            // background-color: #fdfbf3;
            align-self: flex-start;
            width: 100%;
            //
            // STICKY
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            //----------
            letter-spacing: -10px;
            max-width: 1350px;
            font-weight: 300;
            font-size: calc(395px + 1.1vmin);
            line-height: calc(390px + 1.1vmin);
            //
            // -------- **
            // -------- **
            // ** FONT SIZE
            // ** MEDIA queries (portrait and landscape)
            // Here you will find all, check the file on the scss folder
            @include Headline_H1_Welcome_and_The;
            // -------- **
            // -------- **
          }

          //
          // **  -------- * --------
          // ** 'to meyoko' or 'furies' on on black section
          // **  -------- * --------
          //
          &.is-line-2_welcome-white {
            // background-color: rgb(5, 5, 5);
            font-size: calc(395px + 1.1vmin);
            line-height: calc(390px + 1.1vmin);
            align-self: end;

            // -------- **
            // ** FONT SIZE
            //  MEDIA queries (portrait and landscape)
            // Here you will find all, check the file on the scss folder
            @include Headline_H1_ToMEYOKO_and_Furies;
            // -------- **
            // -------- **
          }
          //
          // **  -------- * --------
          // ** 'to meyoko' or 'furies' on on white section
          // **  -------- * --------
          //
          &.is-line-2_welcome-black {
            // background-color: #fdfbf3;
            font-size: calc(395px + 1.1vmin);
            line-height: calc(390px + 1.1vmin);
            align-self: end;
            // -------- **
            // -------- **
            // ** FONT SIZE
            // ** MEDIA queries (portrait and landscape)
            // Here you will find all, check the file on the scss folder
            @include Headline_H1_ToMEYOKO_and_Furies;
            // -------- **
          }
          //
          //
          // ---
          &.is-line-2_welcome-black,
          &.is-line-2_welcome-white {
            // ------
            // ** the line-2 on white BG,
            // will have the align-self: end & margin-right: 20px, it gives a nice effect, the margin is because it needs to be pushed a bit to right, check the z_alignSelft-margin-headline.md, dont forget to also add 100% width to the line-1 , check the VIDEO explanation on the md
            //

            margin-right: 20px;
          }
          //
        }
      }
    }

    /*





    */
    // ------------------
    // ------------------
    // ** RIGHT ----------

    #container-welcome-right {
      display: flex;
      flex-direction: column;
      align-self: flex-end;

      // background-color: rgb(157, 223, 222);

      //
      //
      // ** Collection
      h1 {
        padding: 0;
        margin: 0;
        transition: 1s all ease-in-out;
        text-transform: capitalize;
        //
        // text-align: center;
        //
        // position: sticky;
        // position: -webkit-sticky;
        // top: 0;
        //
        align-self: end;

        // background-color: red;
        letter-spacing: 1px;
        font-weight: 400;
        font-size: calc(9px + 1.1vmin);
        line-height: calc(20px + 1.1vmin);

        &#is--h1-section_welcome-right-white {
          color: #c1b9aa;
        }
        &#is--h1-section_welcome-right-black {
          color: #989185;
        }

        &#is--h1-section_welcome-right-white,
        &#is--h1-section_welcome-right-black {
          // -------- **
          // ** FONT SIZE
          // ** MEDIA queries (portrait and landscape)
          // Here you will find all, check the file on the scss folder
          @include Headline_H1_Welcome__titleOfTheCollection;
          // -------- **
          //
          //
        }
      }
      //
      //---------- ** ()
      //
      h2 {
        transition: 1s all ease-in-out;
        align-self: end;
        text-transform: capitalize;
        font-weight: 400;
        font-size: calc(9px + 1.1vmin);
        line-height: calc(10px + 1.1vmin);

        //
        //
        &#is--h2-section_welcome-right-white {
          color: #c1b9aa;
        }

        &#is--h2-section_welcome-right-black {
          color: #989185;
        }
        //
        //
        // -------- **
        // ** FONT SIZE
        // ** MEDIA queries (portrait and landscape)
        // Here you will find all, check the file on the scss folder
        @include Headline_H2_Welcome__Collection;
        // -------- **
        //
        //
        //
      }

      //
      // 01.01.24

      h3 {
        margin: 10px 0;
        align-self: end;
        transition: 1s all ease-in-out;
        text-transform: uppercase;

        font-weight: 600;
        font-size: calc(10px + 1.1vmin);
        line-height: calc(20px + 1.1vmin);

        //
        &#is--h3-section_welcome-right-white {
          color: #c1b9aa;
        }

        &#is--h3-section_welcome-right-black {
          color: #2d2a26;
        }

        // -------- **
        // ** FONT SIZE
        // ** MEDIA queries (portrait and landscape)
        // Here you will find all, check the file on the scss folder
        @include Headline_H3_Welcome__date;
        // -------- **
        //
        //
      }
    }
  }
}
//
//
