@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

//
@import "../scss/width-container-global";
//
@import "../scss/font-sizes-drawingArchiveScreen";

//
//

#wrapper-drawingScreen__archive {
  background-color: #0a0a0a;
  padding-top: 0px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex; /*❎ if you don't add the flex and the flex-direction to ROW here, the div of the IMG sticky will position itself UNDER the Text DIV, that contains all the info

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

  Dont forget to add individually the row or column to each media Query withing the drawingArchiveScreen
  */

  // background-color: red;
  &:nth-child(even) {
    flex-direction: row-reverse;
  }

  //  -------- * --------
  // ** MEDIA queries
  //  -------- * --------
  @include mediaQueries___wrapper__drawingArchiveScreen;
  // **  -------- * --------
  // **  -------- * --------

  //----------------
  //  FULL IMG
  // ---------------
  //
  .full-img-drawingScreen__archive {
    // ----------
    // 🖐️ important: The width/height/ minHeight are found on the /scss/_font-sizes-drawingArchiveScreen.scss
    //
    // background-color: pink;
    display: flex;
    flex-wrap: wrap;

    //
    //  ** important: the minHeight was implemented on the jsx of this file, then the variable (  min-height: var(--min-height); ) used on the /scss/_font-sizes-drawingArchiveScreen.scss
    //
    //
    //    important:  I have also changed the top/bottom padding on the Gallery x2 and the large IMG collection component, img that is being used on this archive comp, this img LG contains a google icon to tell the user he can enlarge or zoom on the image
    // ----------

    //

    //  -------- * --------
    // ** MEDIA queries
    //  -------- * --------
    @include mediaQueries___fullImg____drawingArchiveScreen;
    //
    //       🍭
    // ----------------
    //       VID
    // ----------------
    //
    .responsive-video-wrapper {
      // background-color: red;
      margin: 5px 0;
      width: 100%;
      height: auto;
      // background-color: red;
      border-radius: 50px;
      overflow: hidden;

      .responsive-video-container {
        border-radius: 30px;
        position: relative;
        width: 100%;
        height: 100%;
        padding-bottom: 56.25%; // 16:9 aspect ratio for responsive videos

        video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    //
    //
    //       🍭
    // ----------------
    // gallery with 4 img's
    // ----------------
    //
    //
    .gallery-container-lg_1fr-2fr-1fr {
      margin: 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .gallery-lg-card-container_1fr-2fr-1fr {
        // ----- this to flexes end here below
        // are to position the div boxes on the bottom of the div, instead of the top
        // justify-content: end;
        // align-items: end;
        // background-color: red;
        // --------
        width: 100%;
        height: 100%;

        padding: 10px 0;
        // ** GRID
        display: grid;
        grid-template-columns: 1fr 1fr; //1fr 2fr 1fr Here, you specify the columns with different widths, another option: 1fr 1fr 1fr 1fr;, will create 4 columns of the same size, but its not cool for mobile
        gap: 10px; // Adjust the gap between the boxes

        //
        // -------  UL - card --------
        .card-gallery-lg__1fr-2fr-1fr {
          width: 100%;
          // background-color: red;
          overflow: hidden;
          height: auto;
          //   padding: 1% 0 0.1% 0;

          //
          // LI
          .img-container-gallery-lg_1fr-2fr-1fr {
            // padding: 20px 10px;
            width: 100%;
            position: relative;
            overflow: hidden;
            border-radius: 30px;
            //
            //
            .img-box-gallery-lg_1fr-2fr-1fr {
              width: 100%;
              height: 100%;
              overflow: hidden;
              // border: 3.5px solid rgba(45, 42, 38, 0.829);
              //------------------------

              border-radius: 30px;

              //
              // ----- dots pattern BG -----
              background-image: radial-gradient(rgba(246, 243, 236, 0.182) 1px);
              background-size: 10px 10px;
              // background-color: rgba(0, 0, 0, 0);
              // ----- dots pattern BG -----

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                // 0.57 very large imgs, 3.57/1 extremely small rectables
                aspect-ratio: 0.77/1; // square
                display: block;
                // aspect-ratio: 1.57/1; // kubrick 1 option
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  //
  //
  //
  //
  //       🌠
  // ----------------
  //      TEXT
  // ----------------
  //
  //
  //
  .wrapper_text-drawingScreen__archive {
    // background-color: pink;
    //  -------- * --------
    // ** MEDIA queries
    //  -------- * --------
    // Here you will find all, check the file on the scss folder
    @include mediaQueries_wrapper-TEXT____drawingArchiveScreen;
    //
    //
    //

    .container_text-drawingScreen__archive {
      //
      // background-color: rgb(0, 0, 0);
      //😆
      padding: 20px 0px 10px 0px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      height: 100%;

      // mediaQueries__container_text-drawingScreen__archive
      //  -------- * --------
      // ** MEDIA queries
      //  -------- * --------
      // Here you will find all, check the file on the scss folder
      @include mediaQueries__container_text-drawingScreen__archive;
      //
      // width: 70%;
      //   -----------------
      .card_text-container-drawingScreen__archive {
        //
        // padding: 30px 0; //👾 this one controls the padding of the parent card, which handles the 1 and 2 box, not the 3 "tag"
        margin-bottom: 8px;

        //
        //1
        .card-text-description-drawingScreen__archive {
          // background-color: lavender;
          width: 100%;
          //
          //
          //  -------- * --------
          // ** MEDIA queries
          //  -------- * --------
          //

          //
          .list-title-btn-desc-drawingScreen__archive {
            // ** TEXT and button
            padding: 5px 0;
            // background-color: orange;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .list-title-wrapper-drawingScreen__archive {
              // background-color: orange;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              //
              // ----------------
              //        H1
              // ----------------
              // ** Drawing title
              h1 {
                word-break: break-all;
                color: #fdfbf3f9;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1px;
                font-size: calc(30px + 1.1vmin);
                line-height: calc(31px + 1.1vmin);
                //
                font-family: "Kenoky-Light";
                //
                //
                // **  -------- * --------
                //  MEDIA queries (portrait and landscape)
                @include Headline_H1__DrawingTitle___drawingArchiveScreen;
              }
              //----------
              //    H2
              //----------
              h2 {
                // ** price or year
                // background-color: red;

                color: #fdfbf3db;
                font-weight: 400;
                font-size: calc(9px + 1.1vmin);
                line-height: calc(54px + 1.1vmin);
                //  -------- * --------
                // ** MEDIA queries
                //  -------- * --------
                @include Headline_H2__DrawingTitle___drawingArchiveScreen;
                //
                // **  -------- * --------
              }
            }
          }
          //
          //-----P
          .list-group-item-drawingScreen__archive {
            padding: 5px 0 10px 0;
            // border-top: 0.8px dotted #fdfbf3;
            // background-color: powderblue;
            //
            //
            p {
              // word-break: break-all;

              // font-family: "Noto Sans", sans-serif;
              color: #989185; //grey yellowish
              // color: red;
              margin: 0 0 0 0;
              font-weight: 300;
              font-size: calc(10px + 1.1vmin);
              line-height: calc(18px + 1.1vmin);
              //
              // **  -------- * --------
              //  MEDIA queries (portrait and landscape)

              @include Headline_P__DrawingTitle___drawingArchiveScreen;
              //
              // **  -------- * --------
              //
            }
          }
        }
      }
      //
      //
      //
      //       🌠
      // ----------------
      // 4    TAGS
      // ----------------
      //
      //
      .card-container-screenDraw__tags {
        // background-color: #f7f5f07f;
        // background-color: orange;
        padding: 5px 0;
        width: 100%;
        border-top: 0.8px solid #fdfbf32e;

        .card-tags-screen-draw {
          padding: 5px 0;
          // TITLE (TAGS)
          .listGroup-title-tags {
            h3 {
              color: #fdfbf37c;
              font-weight: 500;
              font-size: calc(9px + 1vmin);
              line-height: calc(19px + 1vmin);
            }
          }
          // ----------------
          //
          // ** UL
          .column-wrap-screen-draw {
            // background-color: pink;
            padding: 5px 0;
            // ** GRID
            display: grid;
            grid-template-columns: 1fr 2fr 1fr; // Here, you specify the columns with different widths
            gap: 10px; // Adjust the gap between the boxes

            //  ** LI
            .listGroupTags {
              // background-color: orange;
              text-transform: capitalize;
              font-weight: 200;
              margin-top: 5px;
              letter-spacing: 1px;

              display: flex;
              justify-content: center;
              align-items: center;
              //
              // NavLink
              a {
                color: #fdfbf352;
              }

              text-transform: capitalize;
              font-weight: 200;
              font-size: calc(7px + 1vmin);
              line-height: calc(5px + 1vmin);
              // btn --
              // border-radius: 15px;
              // border: 0.8px solid #101010f6;
              // padding: 8px 22px;
              // background-color: #101010f6;
              // btn --
              transition: all 0.5s;
              //
              &:hover {
                color: #f2e6d2; //grey yellowish
                // font-weight: 500;
                // border: 0.8px solid #fdfbf32e;
              }
            }
          }
        }
      }
    }
  }
}
