//
//-----------------
// -- IMG IMG XL
//-----------------
//
.largeImg-wrapper-collection {
  padding: 20px 0;
  width: 100%;
  min-height: 60vh;

  // background-color: red;
  //
  //
  .imgBox-drawingScreen__archive {
    position: relative;
    border-radius: 60px;
    overflow: hidden;
    //
    //
    // ** ICON zoom
    //its the only large img that will have this icon
    // the icon is coming from google icons, you can find the link on top of this file
    .zoom-google-icon {
      position: absolute;
      bottom: 20px;
      right: 35px;
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      text-decoration: none;

      z-index: 1;
      border: 1.5px solid #161616;
      border-radius: 50px;
      transition: all 0.8s ease-in-out;
      background-color: #161616;
      //
      //
      // icon
      &::after {
        text-align: center;
        border-radius: 0px;
        background-color: transparent;
        color: #fdfbf3;
        font-size: calc(17px + 1.1vmin);
        //🔴 icon
        font-family: "Material Icons";
        content: "pinch";
        // ---------
        transition: all 0.8s ease-in-out;
      } //
      // -- hover
      &:hover {
        transition: all 0.8s ease-in-out;
        border: 1.9px solid #161616;
        border-radius: 40px;
        background-color: #161616;
      }
      &:hover::after {
        //🔴 icon
        content: "zoom_in";
        color: #fdfbf3;
        transition: all 0.8s ease-in-out;
        background-color: transparent;
        // margin: 0 0 0 15px;
        // padding: 10px;
        // border-radius: 100px;
      }
    }
    //
    //
    //
    .img-lg-drawingScreenArch {
      display: block;
    }
    img {
      width: 100%;
      min-height: auto;
      object-fit: cover;
      cursor: pointer;
    }
    .zoom-in-cursor {
      position: absolute;
      bottom: 10px;
      right: 20px;
      width: 40px;
      height: 40px;
      // color: red;
      z-index: 1;
    }
  }
  // -- end of  IMG L1
  //
}
