#wrapper__Press-Screen {
  //   background-color: yellow;
  background-color: var(--bg-color);
  //
  width: 100%;
  height: 100%;
  padding: 50px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  //
  //
  //
  //-----------
  .headline-press {
    padding: 250px 40px 20px 40px;
    width: 100%;
    min-height: 20vh;
    overflow: hidden;
    // background-color: rgb(193, 13, 43);

    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr;
    gap: 15px;

    // -------- **
    // -------- **
    // -------- **
    // -------- **
    // MOBILE - PORTRAIT
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      grid-template-columns: 1fr;

      padding: 100px 0px 20px 0px;
      gap: 5px;
    }
    // MOBILE - LANDSCAPE
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      grid-template-columns: 1fr;
      padding: 100px 0px 20px 0px;
      gap: 5px;
    }

    // TABLET1 - PORTRAIT
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    }
    // TABLET1 - LANDSCAPE
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      grid-template-columns: 1fr;
    }

    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      grid-template-columns: 1fr;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      grid-template-columns: 1fr;
    }

    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      grid-template-columns: 1fr;
      padding: 200px 40px 20px 40px;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      grid-template-columns: 1fr;
      padding: 200px 40px 20px 40px;
    }

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      grid-template-columns: 1fr;
      padding: 250px 0px 20px 0px;
      width: 90%;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      grid-template-columns: 1fr;
      padding: 250px 0px 20px 0px;
      width: 90%;
      // background-color: palegoldenrod;
    }
    // -------- **
    // -------- **
    .container-svg {
      padding: 30px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: pink;
      margin: 0 auto;
      overflow: hidden;
      border-radius: 45px;

      .svg-box-press {
        width: 80%;
        height: 80%;
      }
    }

    // -------- **
    // -------- **
    //
    //
    //
    //🧧 i added the property form the array to the svg
    //
    //
    h1 {
      padding: 0;
      margin: 0;
      font-family: "Wagon-Bold";
      text-transform: capitalize;
      font-size: calc(90px + 1.1vmin);
      font-weight: 400;
      letter-spacing: -12px;

      color: var(--font-color-headlineTop);
      // background-color: red;
      // large text WELCOME

      // -------- **
      // -------- **
      // MOBILE - PORTRAIT
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        // background-color: rgb(193, 220, 217);
        font-size: calc(35px + 1.1vmin);
        line-height: calc(35px + 1.1vmin);
        letter-spacing: -1px;
        text-align: center;
      }
      // MOBILE - LANDSCAPE
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        // background-color: purple;
        font-size: calc(55px + 1.1vmin);
        line-height: calc(35px + 1.1vmin);
        letter-spacing: -1px;
        text-align: center;
      }

      // -------- **
      // -------- **

      // TABLET1 - PORTRAIT
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        // background-color: orangered;
        font-size: calc(80px + 1.1vmin);
        line-height: calc(85px + 1.1vmin);
        letter-spacing: -7px;
        text-align: center;
      }
      // TABLET1 - LANDSCAPE
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        // background-color: orange;
        font-size: calc(75px + 1.1vmin);
        line-height: calc(75px + 1.1vmin);
        letter-spacing: -7px;
        text-align: center;
      }
      //-------------**
      //-------------**
      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        // background-color: olivedrab;
        // color: teal;
        font-size: calc(90px + 1.1vmin);
        line-height: calc(80px + 1.1vmin);
        letter-spacing: 1px;
        text-align: center;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        // background-color: pink;
        // color: fuchsia;
        font-size: calc(90px + 1.1vmin);
        line-height: calc(80px + 1.1vmin);
        letter-spacing: -1px;
        text-align: center;
      }

      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        // color: purple;
        // background-color: rgb(238, 217, 238);
        font-size: calc(140px + 1.1vmin);
        line-height: calc(150px + 1.1vmin);
        letter-spacing: -1px;
        text-align: center;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        // color: rgb(0, 128, 255);
        font-size: calc(100px + 1.1vmin);
        line-height: calc(115px + 1.1vmin);
        letter-spacing: -1px;
        text-align: center;
      }
      //-------------**
      //-------------**

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        font-size: calc(150px + 1.1vmin);
        line-height: calc(170px + 1.1vmin);
        letter-spacing: -10px;
        text-align: center;
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        font-size: calc(140px + 1.1vmin);
        line-height: calc(140px + 1.1vmin);
        letter-spacing: -10px;
        text-align: center;
      }
      //-------------**
    }
  }

  //
  //

  .container___Press-Screen {
    width: 90%;
    padding-top: 30px;
    // background-color: pink;
    border-top: 1.5px solid var(--font-color-headlineTop);
    //
    //
    // MOBILE - PORTRAIT
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      width: 95%;
      padding-top: 50px;
    }
    // MOBILE - LANDSCAPE
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      width: 90%;
      padding-top: 50px;
    }

    // TABLET1 - PORTRAIT
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      width: 90%;
    }
    // TABLET1 - LANDSCAPE
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      width: 90%;
    }

    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      width: 90%;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      width: 90%;
    }

    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      width: 80%;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      width: 80%;
    }

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      width: 80%;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      width: 80%;
    }
    // -------- **
    // -------- **
    // -------- **
    //
    //
    // ----------------
    //    💮  TEXT  💮
    //-----------------
    .wrapper-description__Press-screen {
      min-height: 20vh;
      padding: 100px 0 40px 0;

      margin: 0 auto;
      width: 80%;
      height: 100%;

      // MOBILE - PORTRAIT
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        width: 95%;
        padding: 10px 0 40px 0;
      }
      // MOBILE - LANDSCAPE
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        width: 95%;
        padding: 10px 0 40px 0;
      }

      // TABLET1 - PORTRAIT
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        width: 90%;
      }
      // TABLET1 - LANDSCAPE
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        width: 90%;
      }

      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        width: 80%;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        width: 90%;
      }

      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        width: 80%;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        width: 80%;
      }

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        width: 80%;
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        width: 80%;
      }
      // -------- **
      // -------- **
      // -------- **
      // -------- **
      //
      //
      .container-description__Press-screen {
        margin: 0 auto; // to center the whole container
        width: 100%;
        padding: 30px 0 30px 0;
        // background-color: orange;

        display: grid;
        grid-template-columns: 0.5fr 1fr 0.4fr;
        gap: 15px;

        // -------- **
        // -------- **
        // -------- **
        // -------- **
        // MOBILE - PORTRAIT
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          grid-template-columns: 0.2fr 1fr;
          gap: 10px;
        }
        // MOBILE - LANDSCAPE
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          grid-template-columns: 0.2fr 1fr;
          gap: 10px;
        }

        // TABLET1 - PORTRAIT
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          grid-template-columns: 0.2fr 1fr;
          gap: 30px;
        }
        // TABLET1 - LANDSCAPE
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          grid-template-columns: 0.2fr 1fr;
          gap: 30px;
        }

        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          grid-template-columns: 0.5fr 1fr;
          gap: 10px;
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          grid-template-columns: 0.5fr 1fr;
          gap: 10px;
        }

        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          grid-template-columns: 0.5fr 1fr 0.4fr;
          gap: 10px;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          grid-template-columns: 0.5fr 1fr 0.4fr;
          gap: 10px;
        }

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          grid-template-columns: 0.5fr 1fr 0.4fr;
          gap: 15px;
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          grid-template-columns: 0.5fr 1fr 0.4fr;
          gap: 15px;
        }
        // -------- **
        // -------- **
        // -------- **
        // -------- **

        .desc_press-screen_left,
        .desc_press-screen_middle {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
        .desc_press-screen_right {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
        }
        //
        //

        // 1
        .desc_press-screen_left {
          // background-color: pink;
          h1 {
            // color: #c2c1bdce;
            color: var(--font-color-h1);
            margin-bottom: 0px;
            letter-spacing: 1px;
            font-size: calc(22px + 1vmin);
            font-family: "Wagon-Bold";

            // -------- **
            // -------- **
            // MOBILE - PORTRAIT
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              font-size: calc(12px + 1vmin);
            }
            // MOBILE - LANDSCAPE
            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              font-size: calc(12px + 1vmin);
            }

            // TABLET1 - PORTRAIT
            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              font-size: calc(14px + 1vmin);
            }
            // TABLET1 - LANDSCAPE
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              font-size: calc(14px + 1vmin);
            }

            // TABLET2 - PORTRAIT
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              font-size: calc(14px + 1vmin);
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              font-size: calc(14px + 1vmin);
            }

            // DESKTOP - PORTRAIT
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              font-size: calc(18px + 1vmin);
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              font-size: calc(18px + 1vmin);
            }

            // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              font-size: calc(20px + 1vmin);
            }
            // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              font-size: calc(20px + 1vmin);
            }
          }
          h3 {
            // color: #7d7c7bce;
            font-family: "Wagon-ExtraLightItalic";
            color: var(--font-color-h3);
            letter-spacing: 1px;
            font-weight: 700;
            font-family: "Wagon-Bold";
            margin-bottom: 20px;
            font-size: calc(10px + 1vmin);
            opacity: 0.6;
            //
            // h3 media queries
            // -------- **
            // -------- **
            // ** MOBILE - PORTRAIT
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              font-family: Inter, system-ui, Avenir, Helvetica, Arial;
              font-size: calc(8px + 1vmin);
              word-spacing: 3px;
            }
            // MOBILE - LANDSCAPE
            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              font-family: Inter, system-ui, Avenir, Helvetica, Arial;
              font-size: calc(9px + 1vmin);
              letter-spacing: -1px;
              word-spacing: 3px;
            }
            // TABLET1 - PORTRAIT
            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              font-family: Inter, system-ui, Avenir, Helvetica, Arial;
              font-size: calc(9px + 1vmin);
              letter-spacing: -1px;
              word-spacing: 3px;
              opacity: 1;
            }
            // TABLET1 - LANDSCAPE
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              font-family: Inter, system-ui, Avenir, Helvetica, Arial;
              font-size: calc(9px + 1vmin);
              letter-spacing: 0.1px;
              word-spacing: 3px;
              opacity: 1;
            }
            // TABLET2 - PORTRAIT
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              font-family: Inter, system-ui, Avenir, Helvetica, Arial;
              font-size: calc(8px + 1vmin);
              letter-spacing: -1px;
              word-spacing: 3px;
              opacity: 1;
              font-weight: 500;
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              font-family: Inter, system-ui, Avenir, Helvetica, Arial;
              font-size: calc(8px + 1vmin);
              letter-spacing: -1px;
              word-spacing: 3px;
              opacity: 1;
              font-weight: 500;
            }

            // DESKTOP - PORTRAIT
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              font-family: Inter, system-ui, Avenir, Helvetica, Arial;
              font-size: calc(7.5px + 1vmin);
              letter-spacing: -1px;
              word-spacing: 3px;
              font-weight: 500;
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              font-family: Inter, system-ui, Avenir, Helvetica, Arial;
              font-size: calc(7.5px + 1vmin);
              letter-spacing: -1px;
              word-spacing: 3px;
              font-weight: 500;
            }

            // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              font-family: Inter, system-ui, Avenir, Helvetica, Arial;
              font-size: calc(8.5px + 1vmin);
              letter-spacing: -1px;
              word-spacing: 3px;
              font-weight: 500;
            }
            // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              font-family: Inter, system-ui, Avenir, Helvetica, Arial;
              font-size: calc(8.5px + 1vmin);
              letter-spacing: -1px;
              word-spacing: 3px;
              font-weight: 500;
            }
            //
            //
            //

            span {
              font-family: Inter, system-ui, Avenir, Helvetica, Arial,
                sans-serif;
              // color: var(--font-color-h3);
              color: var(--font-color-h3);
              font-weight: 700;
              margin-bottom: 20px;
              letter-spacing: -1px;
              font-size: calc(8px + 1vmin);

              // ** MOBILE - PORTRAIT
              @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                font-size: calc(8px + 1vmin);
                font-weight: 400;
                margin-left: 3px;
              }
              // MOBILE - LANDSCAPE
              @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                font-size: calc(8px + 1vmin);
                font-weight: 400;
                margin-left: 3px;
              }
              // TABLET1 - PORTRAIT
              @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                font-size: calc(8px + 1vmin);
                font-weight: 400;
                margin-left: 5px;
              }
              // TABLET1 - LANDSCAPE
              @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                font-size: calc(8px + 1vmin);
                font-weight: 400;
                margin-left: 5px;
              }
              // TABLET2 - PORTRAIT
              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                font-size: calc(8px + 1vmin);
                font-weight: 400;
                margin-left: 5px;
              }

              // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                font-size: calc(8px + 1vmin);
                font-weight: 400;
                margin-left: 5px;
              }

              // DESKTOP - PORTRAIT
              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                font-size: calc(8px + 1vmin);
                font-weight: 400;
                margin-left: 5px;
              }

              // DESKTOP - LANDSCAPE
              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                font-size: calc(8px + 1vmin);
                font-weight: 400;
                margin-left: 5px;
              }

              // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
              @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                font-size: calc(8px + 1vmin);
                font-weight: 400;
                margin-left: 5px;
              }
              // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
              @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                font-size: calc(8px + 1vmin);
                font-weight: 400;
                margin-left: 5px;
              }
              //
              //
              //
            }
          }
          //-----
          //
        }
        // 2
        .desc_press-screen_middle {
          // background-color: orange;
          h1 {
            // color: rgba(194, 193, 189, 0.807);
            color: var(--font-color-h1);
            font-family: "Wagon-Bold";
            // background-color: #000e8a;
            margin-bottom: 20px;
            letter-spacing: 1px;
            font-size: calc(22px + 1vmin);

            // -------- **
            // -------- **
            // MOBILE - PORTRAIT
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              font-size: calc(12px + 1vmin);
            }
            // MOBILE - LANDSCAPE
            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              font-size: calc(12px + 1vmin);
            }

            // TABLET1 - PORTRAIT
            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              font-size: calc(14px + 1vmin);
            }
            // TABLET1 - LANDSCAPE
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              font-size: calc(14px + 1vmin);
            }

            // TABLET2 - PORTRAIT
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              font-size: calc(14px + 1vmin);
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              font-size: calc(14px + 1vmin);
            }

            // DESKTOP - PORTRAIT
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              font-size: calc(18px + 1vmin);
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              font-size: calc(18px + 1vmin);
            }

            // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              font-size: calc(20px + 1vmin);
            }
            // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              font-size: calc(20px + 1vmin);
            }
            // -------- **
            // -------- **
          }

          //-----
          // ** P
          //-----
          p {
            // --font-color-P
            // color: #7d7c7bce;
            color: var(--font-color-P);
            margin-top: 20px;
            font-size: calc(12px + 1vmin);
            line-height: calc(19px + 1vmin);

            // -------- **
            // -------- **
            // -------- **
            // -------- **
            // MOBILE - PORTRAIT
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              font-size: calc(11px + 1vmin);
              line-height: calc(16px + 1vmin);
            }
            // MOBILE - LANDSCAPE
            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              font-size: calc(11px + 1vmin);
              line-height: calc(16px + 1vmin);
            }

            // TABLET1 - PORTRAIT
            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              font-size: calc(12px + 1vmin);
              line-height: calc(24px + 1vmin);
            }
            // TABLET1 - LANDSCAPE
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              font-size: calc(12px + 1vmin);
              line-height: calc(24px + 1vmin);
            }

            // TABLET2 - PORTRAIT
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              font-size: calc(12px + 1vmin);
              line-height: calc(24px + 1vmin);
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              font-size: calc(12px + 1vmin);
              line-height: calc(24px + 1vmin);
            }

            // DESKTOP - PORTRAIT
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              font-size: calc(12px + 1vmin);
              line-height: calc(19px + 1vmin);
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              font-size: calc(12px + 1vmin);
              line-height: calc(19px + 1vmin);
            }

            // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              font-size: calc(12px + 1vmin);
              line-height: calc(25px + 1vmin);
            }
            // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              font-size: calc(12px + 1vmin);
              line-height: calc(25px + 1vmin);
            }
            // -------- **
            // -------- **
            // -------- **
            // -------- **
            //
            //
          }
        }

        //-----
        // 3
        //------
        .desc_press-screen_right {
          h1 {
            // color: #c2c1bdce;
            color: var(--font-color-h1);
            margin-bottom: 0px;
            letter-spacing: 1px;
            font-size: calc(12px + 1vmin);
            font-family: "Wagon-Bold";
          }
        }
      }
    }

    //
    //
    //----------------
    // ** 💮 img 2
    // ---------------
    // ** GRID (double img's)
    .wrapper-img-double__Press-screen {
      padding: 50px 10px 0 10px;
      // background-color: rgb(0, 0, 0);
      margin: 0 auto; // to center the whole container
      width: 100%;
      height: 100%;

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;

      // -------- **
      // -------- **

      // -------- **
      // -------- **
      // MOBILE - PORTRAIT
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        padding: 10px 10px 0 10px;
        grid-template-columns: 0.5fr 0.5fr;
        gap: 3px;
      }
      // MOBILE - LANDSCAPE
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        grid-template-columns: 0.5fr 0.5fr;
        gap: 3px;
      }

      // TABLET1 - PORTRAIT
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        grid-template-columns: 1fr;
      }
      // TABLET1 - LANDSCAPE
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        grid-template-columns: 1fr;
      }

      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        grid-template-columns: 1fr;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        grid-template-columns: 1fr;
      }

      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        grid-template-columns: 1fr 1fr;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        grid-template-columns: 1fr 1fr;
      }

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        grid-template-columns: 1fr 1fr;
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        grid-template-columns: 1fr 1fr;
      }
      // -------- **
      // -------- **
      // -------- **
      // -------- **
      //
      //
      .imgBox_Press-Screen {
        width: 100%;
        height: 100%;

        // border-top-right-radius: 550px;
        // border-top-left-radius: 550px;

        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 0.8/1;
        }
      }
    }

    // ----------------
    //   🌻  IMG 1 🌻
    //-----------------
    .container-single__Press-screen {
      //
      //
      padding: 10px 0 15px 0;
      .imgBox_Press-Screen {
        margin: 0 auto;
        width: 100%;
        overflow: hidden;
        // border-bottom-right-radius: 550px;
        // border-bottom-left-radius: 550px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 1/1;
        }
      }
    }
    //
    //
    //
    //
    //              💥
    //--------------------------
    // ** ----- EDITORIAL ------
    // -----------------------------
    //
    .wrapper-press-editorial-grid {
      // background-color: seagreen;
      padding: 50px 0 300px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // -------- **
      // MOBILE - PORTRAIT
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        padding: 50px 0 100px 0;
      }
      // MOBILE - LANDSCAPE
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        padding: 50px 0 100px 0;
      }

      // -------- **
      // -------- **

      // TABLET1 - PORTRAIT
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        padding: 50px 0 100px 0;
      }
      // TABLET1 - LANDSCAPE
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        padding: 50px 0 100px 0;
      }
      //-------------**
      //-------------**
      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        padding: 50px 0 100px 0;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        padding: 50px 0 100px 0;
      }

      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        padding: 50px 0 100px 0;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        padding: 50px 0 100px 0;
      }
      //-------------**
      //-------------**

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        padding: 50px 0 300px 0;
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        padding: 50px 0 300px 0;
      }
      //-------------**

      //
      //
      //   UL
      .press-editorial-grid {
        // background-color: pink;
        // --------
        overflow: hidden;
        margin: 0 auto; // to center the whole container
        width: 60%;
        height: 100%;

        // ** GRID

        display: grid;
        grid-template-columns: 1fr; //0.6fr 1fr 0.4fr Here, you specify the columns with different widths, another option: 1fr 1fr 1fr 1fr;, will create 4 columns of the same size, but its not cool for mobile
        gap: 10px; // Adjust the gap between the boxes

        // GALLERY
        // -------- **
        // -------- **
        // MOBILE - PORTRAIT
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          width: 90%;
          grid-template-columns: 1fr;
        }
        // MOBILE - LANDSCAPE
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          grid-template-columns: 1fr;
          width: 90%;
        }

        // -------- **
        // -------- **

        // TABLET1 - PORTRAIT
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          grid-template-columns: 1fr;
          width: 90%;
        }
        // TABLET1 - LANDSCAPE
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          grid-template-columns: 1fr;
          width: 90%;
        }
        //-------------**
        //-------------**
        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          grid-template-columns: 1fr;
          width: 70%;
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          grid-template-columns: 1fr;
          width: 70%;
        }

        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          grid-template-columns: 1fr;
          width: 70%;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          grid-template-columns: 1fr;
          width: 70%;
        }
        //-------------**
        //-------------**

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          width: 60%;
          grid-template-columns: 1fr;
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          grid-template-columns: 1fr;
          width: 60%;
        }
        //-------------**

        //
        //
        //
        // LI
        .container-imgPress-editorial {
          width: 100%;
          margin: 0 auto;
          position: relative;
          &::before {
            content: "";
            width: 100%;
            height: 100%;

            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;

            background-color: rgba(235, 228, 221, 0.299);
            z-index: 100;
            pointer-events: none;

            // background: linear-gradient(
            //     0deg,
            //     rgba(194, 191, 186, 0.032),
            //     rgba(194, 191, 186, 0.032)
            //   ),
            //   url(https://grainy-gradients.vercel.app/noise.svg);
            // filter: contrast(150%) brightness(80%);
          }

          &:hover::before {
            background-color: rgba(64, 61, 58, 0.21);
          }

          .imgPress-box-editorial {
            width: 100%;

            position: relative;
            overflow: hidden;

            // .overlay-about {
            //   width: 250%;
            //   height: 250%;

            //   top: 0;
            //   left: 0;
            //   right: 0;
            //   bottom: 0;
            //   position: absolute;
            //   z-index: 100;
            //   pointer-events: none;
            //   //
            //   background-color: rgba(0, 0, 0, 0.326);
            //   z-index: 100;
            //   pointer-events: none;

            //   background: linear-gradient(
            //       0deg,
            //       rgba(58, 56, 52, 0.032),
            //       rgba(133, 168, 255, 0.018)
            //     ),
            //     url(https://grainy-gradients.vercel.app/noise.svg);
            //   filter: contrast(100%) brightness(30%);
            // }

            &::before {
              content: "";
              width: 100%;
              height: 100%;

              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              position: absolute;

              z-index: 100;
              pointer-events: none;
              // --- the inner border
              border-radius: 100px;
              border: 20px solid transparent;
              box-shadow: 0 0 0 0.1em transparent, inset 0 0 0 0.1em #eeeeee29;
              // -----
            }

            //
            //

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              //   aspect-ratio: 1;
              display: block;
            }
          }
        }
      }
    }

    //
    // ** ----- EDITORIAL ------
    //
  }
}
