// --------------
//  GRID the furies
// --------------
//
//
//   container GRID
.wrapper_gallery_grid_4 {
  //
  // background-color: red;
  padding-top: 40px;
  padding-bottom: 20px;
  margin: 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    width: 90%;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    width: 90%;
  }
  //-------------**
  //-------------**
  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    width: 80%;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    width: 80%;
  }

  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    width: 90%;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    width: 90%;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    width: 97%;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    width: 92%;
  }

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    width: 95%;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    width: 95%;
  }
  //-------------**

  //
  // --------- GRID
  //
  //
  .container_gallery-card_grid-4 {
    // background-color: #50a287;
    //   justify-content: end;
    //   align-items: end;
    // --------

    .container-images-and-text_4 {
      // grid
      display: grid;
      width: 100%;
      height: 100%;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 0.3em;

      padding: 20px 0;

      // -------- **
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 0.3em;

      // -------- **
      // -------- **
      // MOBILE - PORTRAIT
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        grid-template-columns: 0.9fr;
        gap: 15px;
      }
      // MOBILE - LANDSCAPE
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        grid-template-columns: 0.9fr;
        gap: 15px;
      }
      //-------------**
      //-------------**
      // TABLET1 - PORTRAIT
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        grid-template-columns: 0.5fr 1fr;
        gap: 15px;
      }
      // TABLET1 - LANDSCAPE
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        grid-template-columns: 0.5fr 1fr;
        gap: 15px;
      }

      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        grid-template-columns: 0.5fr 1fr;
        gap: 15px;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        grid-template-columns: 0.5fr 0.3fr 0.5fr;
        gap: 20px;
      }

      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        grid-template-columns: 0.5fr 0.3fr 0.5fr;
        gap: 20px;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        grid-template-columns: 0.5fr 0.3fr 0.5fr;
        gap: 20px;
      }

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        grid-template-columns: 0.5fr 0.3fr 0.5fr;
        gap: 20px;
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        grid-template-columns: 0.5fr 0.3fr 0.5fr;
        gap: 20px;
      }

      // -------------
      //  IMG
      // -------------
      //
      .img-container-grid-4 {
        width: 100%;
        height: 100%;
        .container_img-box-grid-4 {
          overflow: hidden;
          margin: 0 auto;

          border-top-right-radius: 900px;
          border-top-left-radius: 900px;
          border-bottom-left-radius: 500px;
          border-bottom-right-radius: 500px;
          border: 1px solid #ffffff2c;
          .img-box-grid-4 {
            width: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              cursor: pointer;
              aspect-ratio: 0.95/1;
            }
          }
        }
      }
      //------

      .description-grid-4 {
        position: -webkit-sticky;
        position: sticky;
        top: 30px;
        // background-color: red;
        height: 120px;

        // -------- **
        // -------- **
        // MOBILE - PORTRAIT
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          height: 70px;
        }
        // MOBILE - LANDSCAPE
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          height: 70px;
        }
        //-------------**
        //-------------**
        // TABLET1 - PORTRAIT
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          height: 85px;
        }
        // TABLET1 - LANDSCAPE
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          height: 85px;
        }

        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          height: 95px;
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          height: 95px;
        }

        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          height: 110px;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          height: 110px;
        }

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          height: 120px;
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          height: 120px;
        }
        //-------------**

        //
        //
        //
        h1 {
          position: -webkit-sticky;
          position: sticky;
          top: 100px;
          padding: 10px 0 8px 0;
          //
          margin: 2px 0 0 0;
          // background-color: #111;
          // color: lemonchiffon;
          color: rgb(243, 239, 222);
          font-weight: 300;
          font-size: calc(11px + 1.1vmin);
        }
      }
    }
  }
}
