#allPrints_page2 {
  background-color: #e5e7ea;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  //
  //
  border-top: 3px solid #000;
  border-left: 5px solid #000;
  border-right: 15px solid #000;
  //
  //
  .allPrints_page2 {
    margin-top: 200px;
    width: 97%;

    // MOBILE - PORTRAIT
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      width: 100%;
    }
    // MOBILE - LANDSCAPE
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      width: 100%;
    }
    //-------------**
    //-------------**
    // TABLET1 - PORTRAIT
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      width: 90%;
    }
    // TABLET1 - LANDSCAPE
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      width: 90%;
    }

    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      width: 87%;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      width: 87%;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      width: 85%;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      width: 85%;
    }

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      width: 85%;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      width: 85%;
    }
    //-------------**
    //-------------**
    // background-color: #f1f3f5;
    //
    // border: 3px solid #282828;

    // border-top-right-radius: 900px;
    // border-top-left-radius: 900px;
    // border-bottom-left-radius: 100px;
    // border-bottom-right-radius: 100px;
    //
    //egg
    // border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    // --------------
    //  GRID the furies
    // --------------
    //
    //
    //   container GRID
    .wrapper_gallery_grid_2 {
      // background-color: #fdfbf3;
      // background-color: #eae8e3; //
      //
      padding-top: 50px;
      margin: 0 auto;
      width: 100%;
      // -------- **
      // -------- **
      // MOBILE - PORTRAIT
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        width: 90%;
      }
      // MOBILE - LANDSCAPE
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        width: 90%;
      }
      //-------------**
      //-------------**
      // TABLET1 - PORTRAIT
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        width: 80%;
      }
      // TABLET1 - LANDSCAPE
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        width: 80%;
      }

      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        width: 90%;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        width: 90%;
      }

      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        width: 97%;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        width: 92%;
      }

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        width: 95%;
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        width: 95%;
      }
      //-------------**

      //
      // --------- GRID
      //
      //
      .container_gallery-card_grid-2 {
        // background-color: #a29250;
        //   justify-content: end;
        //   align-items: end;
        // --------

        .container-images-and-text_2 {
          // grid
          display: grid;
          width: 100%;
          height: 100%;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 0.3em;

          padding: 20px 0;

          // -------- **
          // -------- **
          // MOBILE - PORTRAIT
          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            grid-template-columns: 1fr;
          }
          // MOBILE - LANDSCAPE
          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            grid-template-columns: 1fr;
          }
          //-------------**
          //-------------**
          // TABLET1 - PORTRAIT
          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            grid-template-columns: 1fr 1fr;
          }
          // TABLET1 - LANDSCAPE
          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            grid-template-columns: 1fr 1fr;
          }

          // TABLET2 - PORTRAIT
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            grid-template-columns: 0.5fr 0.5fr;
          }

          // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            grid-template-columns: 0.5fr 0.5fr;
          }

          //-------------**
          //-------------**
          // DESKTOP - PORTRAIT
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            grid-template-columns: 0.5fr 0.5fr 1fr;
          }

          // DESKTOP - LANDSCAPE
          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            grid-template-columns: 0.5fr 0.5fr 1fr;
          }

          // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            grid-template-columns: 0.5fr 0.5fr 1fr;
          }
          // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            grid-template-columns: 0.5fr 0.5fr 1fr;
          }
          //-------------**

          // -------------
          //  IMG
          // -------------
          //
          .img-container-grid-2_memento {
            width: 100%;
            height: 100%;
            padding: 10px;

            .container_img-box-grid-2 {
              overflow: hidden;
              margin: 0 auto;
              border-top-right-radius: 900px;
              border-top-left-radius: 900px;
              border-bottom-left-radius: 900px;
              border-bottom-right-radius: 900px;
              border: 3px solid #282828;
              background-color: #f9eddc;
              width: 90%;
              padding: 40px;
              .img-box-grid-2 {
                width: 100%;
                margin: 0 auto;
                // border: 1px solid #282828;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  cursor: pointer;
                  aspect-ratio: 0.65/1;
                  display: block;
                  //
                }
              }
            }
          }
          //------

          .description-grid-2 {
            position: -webkit-sticky;
            position: sticky;
            top: 30px;
            // background-color: red;
            height: 120px;

            // -------- **
            // -------- **
            // MOBILE - PORTRAIT
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              height: 70px;
            }
            // MOBILE - LANDSCAPE
            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              height: 70px;
            }
            //-------------**
            //-------------**
            // TABLET1 - PORTRAIT
            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              height: 85px;
            }
            // TABLET1 - LANDSCAPE
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              height: 85px;
            }

            // TABLET2 - PORTRAIT
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              height: 95px;
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              height: 95px;
            }

            //-------------**
            //-------------**
            // DESKTOP - PORTRAIT
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              height: 110px;
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              height: 110px;
            }

            // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              height: 120px;
            }
            // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              height: 120px;
            }
            //-------------**

            //
            //
            //
            h1 {
              position: -webkit-sticky;
              position: sticky;
              top: 100px;
              padding: 10px 0 8px 0;
              //
              margin: 2px 0 0 0;
              // background-color: #111;
              // color: lemonchiffon;
              color: black;
              font-weight: 300;
              text-align: center;
              font-size: calc(11px + 1.1vmin);
            }
          }
        }
      }
    }
    //
    //  -------
    //
  }
}
