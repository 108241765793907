@mixin user-select-none {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                              supported by Chrome, Edge, Opera and Firefox */
  // disable image drag -----
}

@mixin user-drag-none {
  // this will prevent the user from dragging any image on the website
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

// fade animation related to the fade in when entering the website ** dont touch this **

@mixin fadeInWelcomeWebsite {
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
@mixin fadeOutWelcomeWebsite {
  -webkit-animation: fadeOut 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeOut 3s; /* Firefox < 16 */
  -ms-animation: fadeOut 3s; /* Internet Explorer */
  -o-animation: fadeOut 3s; /* Opera < 12.1 */
  animation: fadeOut 3s;

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
// -----------

@mixin Scalingdd {
  //https://stackoverflow.com/questions/17767759/css3-scale-animation-keyframes
  -webkit-animation: scalingyass 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: scalingyass 3s; /* Firefox < 16 */
  -ms-animation: scalingyass 3s; /* Internet Explorer */
  -o-animation: scalingyass 3s; /* Opera < 12.1 */
  animation: scalingyass 3s;

  @keyframes scalingyass {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}
@mixin Scaling2 {
  //https://stackoverflow.com/questions/17767759/css3-scale-animation-keyframes
  -webkit-animation: scaling2 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: scaling2 3s; /* Firefox < 16 */
  -ms-animation: scaling2 3s; /* Internet Explorer */
  -o-animation: scaling2 3s; /* Opera < 12.1 */
  animation: scaling2 3s;

  @keyframes scaling2 {
    from {
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}
//
@mixin Scaling3 {
  //https://stackoverflow.com/questions/17767759/css3-scale-animation-keyframes
  -webkit-animation: scaling_3 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: scaling_3 3s; /* Firefox < 16 */
  -ms-animation: scaling_3 3s; /* Internet Explorer */
  -o-animation: scaling_3 3s; /* Opera < 12.1 */
  animation: scaling_3 3s;

  @keyframes scaling_3 {
    from {
      opacity: 0;
      transform: scale(0);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
}
//
//------- marquee start -------------

$color-text: navy;
$color-bg: papayawhip;
$color-bg-accent: #ecdcc0;

$size: clamp(10rem, 1rem + 40vmin, 30rem);
$gap: calc(#{$size} / 14);
$duration: 60s;

$scroll-start: 0;
$scroll-end: calc(-100% - #{$gap});

@keyframes scroll-x {
  from {
    transform: translateX(#{$scroll-start});
  }
  to {
    transform: translateX(#{$scroll-end});
  }
}
@media screen and (min-width: 1268px) {
  $size: clamp(10rem, 1rem + 40vmin, 10rem);
  $duration: 20s;
}

$bg__banner: #f5f5ee;
$font__banner: rgb(18, 18, 18);

$bg__services: #fefefe;
$font__black__services: rgb(18, 18, 18);

$font__white__services: #181818;

$font__black_contact: #181818;
$font__white_contact: #f5f5ee;
$black-app: #f9f9f9;
$bg__yellow: #ffff99;

$bg-green-elegant-dark-strong: rgb(5, 34, 12);
$color-of-all-contact-and-accordion-font-underline-white: #fcfcfcd2;
$black-services-bg: rgb(0, 0, 0);
$color-3boxes-white: #ffffff;
$bg-3boxes: rgb(0, 0, 0);
$bg-accordion-and-services: #ffff99;

@mixin font-accordion-H5-title-media-xs {
  padding: 5px 0;
  font-size: calc(8px + 1.1vmin);
  word-wrap: break-word;
  font-weight: 600;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;
}
@mixin font-accordion-H5-title-media668 {
  font-size: calc(10px + 1.1vmin);
}
@mixin font-accordion-H5-title-media868 {
  font-size: calc(12px + 1.1vmin);
}
@mixin font-accordion-H5-title-media1468 {
  font-size: calc(9px + 1.1vmin);
}
//
// P -  ACCORDION font-size
@mixin font-accordion-P-title-media-xs {
  margin: 10px 0;
  word-wrap: break-word;
  text-align: left;
  letter-spacing: 1px;
  font-weight: 300;
  //
  font-size: calc(11px + 1.1vmin);
  line-height: calc(12px + 1.1vmin);
}
@mixin font-accordion-P-title-media668 {
  font-size: calc(10px + 1.1vmin);
  line-height: calc(18px + 1.1vmin);
}
@mixin font-accordion-P-title-media868 {
  font-size: calc(11px + 1.1vmin);
  line-height: calc(18px + 1.1vmin);
}
@mixin font-accordion-P-title-media1468 {
  font-size: calc(11px + 1.1vmin);
  line-height: calc(18px + 1.1vmin);
}
//
// li -  ACCORDION font-size
@mixin font-accordion-li-title-media-xs {
  margin: 0 10px 0 0;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 1px;

  font-size: calc(8px + 1.1vmin);
  line-height: calc(18px + 1.1vmin);
}
@mixin font-accordion-li-title-media668 {
  font-size: calc(9px + 1.1vmin);
  line-height: calc(18px + 1.1vmin);
}
@mixin font-accordion-li-title-media868 {
  font-size: calc(12px + 1.1vmin);
  line-height: calc(18px + 1.1vmin);
}

//        ------------
// ***  GRADIENT CAHIER
//        ------------
//          radial
@mixin gradient__cahier-dots__soft_contrast-grey {
  background-image: radial-gradient(
    rgba(161, 161, 161, 0.096) 1.5px,
    transparent 1.5px
  );
  background-size: 10px 10px;
}
@mixin gradient__cahier-dots__soft_strong {
  background-image: radial-gradient(rgba(0, 0, 0, 0.803) 2px, transparent 2px);
  background-size: 10px 10px;
}
@mixin gradient__cahier-dots__soft_strong-blue {
  background-image: radial-gradient(
    rgba(157, 155, 184, 0.133) 1.5px,
    transparent 1.5px
  );
  background-size: 5px 5px;
}
//          squared
@mixin gradient_cahier_carreaux {
  background-image: linear-gradient(
      rgba(0, 0, 0, 0.05) 0.5px,
      transparent 0.8px
    ),
    linear-gradient(to right, rgba(0, 0, 0, 0.05) 0.5px, transparent 0.5px);
  background-size: 13px 13px;
}
// @include gradient-squared-works;
@mixin gradient-squared-works {
  background-image: linear-gradient(
      rgba(88, 88, 88, 0.053) 1.5px,
      transparent 1.5px
    ),
    linear-gradient(to right, rgba(88, 88, 88, 0.053) 1.5px, transparent 1.5px);
  background-size: 13px 13px;
}

@mixin gros-carreaux {
  background-image: linear-gradient(rgba(0, 0, 0, 0.16) 1px, transparent 1px),
    linear-gradient(to right, rgba(0, 0, 0, 0.16) 1px, transparent 1px);
  background-size: 45px 45px;
}

//      ***  SPACE CENTER
//

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin flex-center-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
@mixin flex-center-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
//
//      ***  FLEX START
//

@mixin flexStart-Col {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
@mixin flexStart-Row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}
@mixin flexStart-center {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

//
//      ***  SPACE BETWEEN#
@mixin flex-row-space-between-center-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  flex-direction: row;
}
//
@mixin flex-col-space-between-center-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
@mixin flex-column-space-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
@mixin flex-row-space-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
}
@mixin flex-row-space-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
}

@mixin flex-row-space-around-end {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-direction: row;
}
@mixin flex-center-row-around {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
}
@mixin flex-center-row-evenly-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}
@mixin flex-center-row-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
}
//
//      ***  FLEX END
//

@mixin flex-end-simple {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
@mixin flex-end-simple-col {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

@mixin flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
}
@mixin flex-center-end-column {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}
@mixin flex-end-center-col {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
}
@mixin flex-end-start-col {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
}

//
//      *** SPACE AROUND
//
@mixin flex-space-around-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

@mixin img-auto-cover {
  img {
    width: 100%;
    min-height: auto;
    object-fit: cover;
  }
}

@mixin header-slider-text-arrows {
  border-top: 1px solid $black-app;
  h1,
  h2 {
    text-transform: uppercase;
    font-family: "Lato-Regular";
    font-weight: 600;
    font-size: calc(7px + 1.2vmin);
    letter-spacing: 1px;
  }
  h2 {
    letter-spacing: 2px;
  }
}

@mixin arrows-slider {
  width: 30px;
  height: 30px;
  padding: 2px;

  margin: 0 4px;
  border-radius: 50%;
  color: rgb(16, 16, 16);
  background-color: transparent;
  border: 1px solid #000;

  transition: all 1s ease-in-out;
  &:hover {
    padding: 2px;
    color: rgb(248, 248, 248);
    background-color: #000;
    border: 1px solid #000;
  }
}

@mixin img-card-gallery-border-box30px-mobile-50px-desktop {
  img {
    width: 100%;
    min-height: auto;
    object-fit: cover;
    border-radius: 30px;
    @include clippyFooter;
  }
  @media screen and (min-width: 768px) {
    img {
      width: 100%;
      min-height: auto;
      object-fit: cover;
      border-radius: 50px;
    }
  }
}

@mixin Img3dHoverSliderIntro {
  .scene3dImgHover-container {
    width: 100%;
    @include flex-center-col;
    @include img3DRotatHoverProp;
  }
}

@mixin img3DRotationHoverProp {
  .img-container-cardHover {
    padding: 30px;
    min-height: 100px;
    overflow: hidden;
    position: relative;

    perspective: 1000px;

    .img-box-portfolio {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      min-height: 160px;
      border-radius: 20%;
      @include img-auto-cover;
    }
  }

  @media screen and (min-width: 1400px) {
    .img-container-cardHover {
      transform: rotateY(25deg);
      padding: 30px;
      min-height: 100px;
      overflow: hidden;
      position: relative;

      perspective: 1000px;

      .img-box-portfolio {
        margin: 0 auto;
        width: 100%;
        height: 100%;
        min-height: 160px;
        border-radius: 20%;
        @include img-auto-cover;
      }
    }
  }
}

@mixin buttonFooter {
  background-color: black;
  color: rgb(248, 248, 248);
  border: 1px solid #000;
  padding: 10px 38px;
  border-radius: 50px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #000;
    background-color: transparent;
    border: 1px solid #000;
  }
}

@mixin bouncingLogoSmooth {
  transition: color 0.6s ease-in-out;
  animation: bouncingLogo 1s infinite alternate;
  -webkit-animation: bouncingLogo 1s infinite alternate;
  //
  @keyframes bouncingLogo {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-5px);
    }
  }
}

@mixin underlineEffectAnim {
  &::after {
    content: "";
    position: absolute;
    transform: scaleX(0);
    height: 100%;
    bottom: -20%;
    left: 0;
    transform-origin: bottom right;
    transition: transform 0.35s ease-out;
    padding: 10px;
  }
  &:hover::after {
    width: 40%;
    transform: scaleX(0.9);
    transform-origin: bottom left;
    border-radius: 100px;
    @include gradientPurpleYellow;
  }
}

// ** contact email

@mixin underline-simpleEffectAnim {
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(1);
    transform-origin: bottom left;
    height: 0.05em;
    bottom: 0;
    left: 0;
    background-color: black;

    transition: transform 0.35s ease-out;
    padding-bottom: 4px;
    margin-bottom: -3px;
  }
  &:hover::after {
    transform: scaleX(0);
    transform-origin: bottom right;
  }
}

@mixin text-fill-stroke {
  -webkit-text-fill-color: rgb(255, 255, 255);
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
}

@mixin text-fillTransp-stroke {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
}

@mixin invertPlusHover {
  transition: all 0.3s ease-in-out;
  filter: invert(0);
  filter: blur(5px);
  opacity: 0.6;
  &:hover {
    filter: invert(1);
  }
}

@mixin BlurHover {
  transition: all 0.3s ease-in-out;
  opacity: 0.6;
  filter: blur(0px);
  &:hover {
    filter: blur(5px);
  }
}

@mixin border-boxShadow {
  &::after {
    content: "";
    position: absolute;

    transform: scaleX(0);
    height: 100%;
    bottom: -20%;
    left: 0;
    transform-origin: bottom right;
    transition: transform 0.35s ease-out;
    padding: 10px;
  }
  &:hover::after {
    width: 90%;
    transform: scaleX(0.9);
    transform-origin: bottom left;
    border-radius: 100px;
    border: 1px solid #000;
    box-shadow: 0 0 50px #ccc;
  }
}

@mixin boxShadowSpaceylinerWhite {
  box-shadow: 0px 15px 15px -15px #111;
  border-radius: 150px;
}

@mixin boxShadowTopBattery {
  box-shadow: 0px -15px 18px -15px rgb(82, 82, 82);
}

@mixin box-shadow-grey-gallery-card {
  -webkit-box-shadow: 0px 0px 40px -10px rgba(133, 133, 133, 1);
  -moz-box-shadow: 0px 0px 40px -10px rgba(133, 133, 133, 1);
  box-shadow: 0px 0px 40px -10px rgba(133, 133, 133, 1);
}

@mixin red-purple-gradientfirt-img-slider {
  filter: contrast(350%) brightness(150%);
  background: linear-gradient(
      180deg,
      rgb(198, 197, 255),
      rgb(216, 209, 255),
      rgb(255, 209, 234)
    ),
    url(https://upload.wikimedia.org/wikipedia/commons/thumb/7/76/1k_Dissolve_Noise_Texture.png/900px-1k_Dissolve_Noise_Texture.png?20200626210709);
}

@mixin doubleRadial1 {
  background: radial-gradient(
      ellipse at top,
      rgba(235, 235, 168, 0.548),
      rgba(28, 7, 136, 0.367)
    ),
    radial-gradient(ellipse at bottom, rgba(83, 83, 83, 0.367), transparent);
}

@mixin gradientPurpleYellow {
  background-image: radial-gradient(
    rgba(230, 100, 100, 0.548),
    rgba(255, 225, 156, 0.367),
    rgba(145, 152, 229, 0.367)
  );
}
@mixin MontanitaSunset {
  background-image: linear-gradient(
    95deg,
    rgba(192, 156, 255, 0.367),
    rgba(240, 205, 8, 0.414),
    rgba(192, 156, 255, 0.367)
  );
}

@mixin gradientCenterOrangeOpacity {
  background-image: radial-gradient(
    rgba(240, 47, 8, 0.548),
    rgba(255, 225, 156, 0.367),
    rgba(255, 255, 255, 0.367)
  );
}
@mixin gradientCenterOrangeOpacity-v2 {
  background-image: radial-gradient(
    rgba(230, 188, 89, 0.756),
    rgba(178, 141, 180, 0.968),
    rgb(250, 250, 250)
  );
}
@mixin gradientCenterOrangeOpacity-v2-transp {
  background-image: radial-gradient(
    rgba(230, 188, 89, 0.749),
    rgba(178, 141, 180, 0.547),
    rgba(250, 250, 250, 0.13)
  );
}
@mixin purpleRadialGradient {
  background-image: radial-gradient(#e66465, #9198e5);
}

@mixin turquoise-light {
  background-image: linear-gradient(-20deg, #bfd4db 0%, #f0f0e7 100%);
}

@mixin yellowRadialGradient {
  background-image: radial-gradient(
    #e66464b0,
    #e5cc91ce,
    rgba(232, 180, 224, 0.719)
  );
}

@mixin gradientBlueDarkYellowAnimat {
  height: 100%;
  background-image: radial-gradient(
    rgba(4, 4, 4, 0.059),
    rgba(255, 255, 255, 0.338),
    rgba(4, 4, 4, 0.059)
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@mixin gradientBlueDarkYellow-noTranspAnimat {
  height: 100%;
  background-image: radial-gradient(#9a9dbafe, #cfe6eb, #a8a295);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@mixin funky-rainbow {
  height: 100%;
  background-image: radial-gradient(#5d50e6, rgb(182, 185, 255), #4637e2);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@mixin funky-rainbow-help {
  background-image: radial-gradient(#d4faf8, rgb(255, 235, 148), #d4faf8);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@mixin funky-rainbow-purple-strong {
  height: 100%;
  background-image: radial-gradient(
    rgb(182, 185, 255),
    rgb(35, 23, 39),
    #4637e2
  );
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@mixin blackBorder-clip {
  clip-path: polygon(
    0% 78%,
    2% 79%,
    2% 52%,
    0 50%,
    0 4%,
    11% 0,
    90% 0,
    100% 5%,
    100% 46%,
    98% 45%,
    99% 83%,
    100% 83%,
    100% 100%,
    32% 100%,
    0 100%
  );
}

//
@mixin notchedSlider-borderRadius-x4corners {
  border-top: 20px solid #000;
  border-bottom: 20px solid #000;
  border-left: 10px solid #000;
  border-right: 10px solid #000;
  //
  border-radius: 100px;

  clip-path: polygon(
    39% 100%,
    41% 99%,
    69% 99%,
    71% 100%,
    96% 100%,
    100% 96%,
    100% 68%,
    99% 65%,
    99% 33%,
    100% 29%,
    100% 4%,
    94% 0,
    64% 0,
    62% 1%,
    40% 1%,
    37% 0,
    4% 0,
    0 4%,
    0 30%,
    1% 31%,
    1% 67%,
    0 68%,
    0 96%,
    5% 100%
  );

  @media screen and (min-width: 1468px) {
    border-radius: 126px 113px 172px 172px;
    -moz-border-radius: 126px 113px 97px 172px;
    -webkit-border-radius: 126px 113px 97px 172px;

    border: 20px solid #000;
  }
}
@mixin notchedSlider-borderRadius-leftTop {
  border-radius: 0px 97px 200px 0px;
  -moz-border-radius: 0px 97px 200px 0px;
  -webkit-border-radius: 0px 97px 200px 0px;

  border-top: 10px solid #000;
  border-left: 0px solid #000;
  border-bottom: 0px solid #000;

  clip-path: polygon(
    12% 100%,
    0 100%,
    0 4%,
    6% 0,
    26% 0,
    29% 1%,
    74% 1%,
    77% 0,
    93% 0,
    100% 4%,
    100% 100%,
    73% 100%,
    72% 99%,
    33% 99%,
    34% 100%
  );
}
//

@mixin notched-clipPath-outside {
  border-radius: 80px;
  -webkit-clip-path: polygon(
    39% 100%,
    41% 99%,
    69% 99%,
    71% 100%,
    96% 100%,
    100% 96%,
    100% 68%,
    99% 65%,
    99% 33%,
    100% 29%,
    100% 4%,
    94% 0,
    64% 0,
    62% 1%,
    40% 1%,
    37% 0,
    4% 0,
    0 4%,
    0 30%,
    1% 31%,
    1% 67%,
    0 68%,
    0 96%,
    5% 100%
  );
  clip-path: polygon(
    39% 100%,
    41% 99%,
    69% 99%,
    71% 100%,
    96% 100%,
    100% 96%,
    100% 68%,
    99% 65%,
    99% 33%,
    100% 29%,
    100% 4%,
    94% 0,
    64% 0,
    62% 1%,
    40% 1%,
    37% 0,
    4% 0,
    0 4%,
    0 30%,
    1% 31%,
    1% 67%,
    0 68%,
    0 96%,
    5% 100%
  );
}

@mixin notched-clipPath-inner {
  border-radius: 100px;

  -webkit-clip-path: polygon(
    39% 100%,
    41% 99%,
    69% 99%,
    71% 100%,
    96% 100%,
    100% 96%,
    100% 68%,
    99% 65%,
    99% 33%,
    100% 29%,
    100% 4%,
    94% 0,
    64% 0,
    62% 1%,
    40% 1%,
    37% 0,
    4% 0,
    0 4%,
    0 30%,
    1% 31%,
    1% 67%,
    0 68%,
    0 96%,
    5% 100%
  );
  clip-path: polygon(
    39% 100%,
    41% 99%,
    69% 99%,
    71% 100%,
    96% 100%,
    100% 96%,
    100% 68%,
    99% 65%,
    99% 33%,
    100% 29%,
    100% 4%,
    94% 0,
    64% 0,
    62% 1%,
    40% 1%,
    37% 0,
    4% 0,
    0 4%,
    0 30%,
    1% 31%,
    1% 67%,
    0 68%,
    0 96%,
    5% 100%
  );
}

@mixin iconSvgwithBorderAnimated {
  .shoe_description_top_animated__icon {
    border-radius: 50px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    @include Scaling2;
    @include flex-center;
    overflow: hidden;
    -webkit-box-shadow: 4px 7.5px 14.5px -3px #e9e9e9;
    -moz-box-shadow: 4px 7.5px 14.5px -3px #e9e9e9;
    box-shadow: 4px 7.5px 14.5px -3px #e9e9e9;
    .top_animated_icon-file {
      box-shadow: inset 0.3em 0.3em 0.9em #fafeff;
      background-color: #ffefa1;

      border-radius: 50px;
      padding: 10px;
      pointer-events: none;
      @include flex-center;
      font-size: calc(15px + 1.1vmin);
      color: #000000;
      @include funky-rainbow-help;
    }
  }

  @media screen and (min-width: 768px) {
    .shoe_description_top_animated__icon {
      .top_animated_icon-file {
        padding: 8px;
        font-size: calc(15px + 1.1vmin);
      }
    }
  }
  @media screen and (min-width: 1168px) {
    .shoe_description_top_animated__icon {
      .top_animated_icon-file {
        padding: 7px;
        font-size: calc(15px + 1.1vmin);
      }
    }
  }
}

@mixin iconSvgwithBorderBlack {
  .shoe_description_top_icon {
    border-radius: 50px;
    @include Scaling2;
    width: 50px;
    height: 50px;
    cursor: pointer;
    @include flex-center;
    overflow: hidden;

    -webkit-box-shadow: 4px 7.5px 14.5px -3px #e9e9e9;
    -moz-box-shadow: 4px 7.5px 14.5px -3px #e9e9e9;
    box-shadow: 4px 7.5px 14.5px -3px #e9e9e9;
    transition: all 0.8s ease-in-out;

    .icon-file {
      border-radius: 50px;
      padding: 10px;
      pointer-events: all;
      @include flex-center;
      font-size: calc(15px + 1.1vmin);
      color: rgb(29, 29, 29);
      box-shadow: inset 0.3em 0.3em 0.9em #ecf0f2;
      background-color: #f5f5ee;
    }
  }

  @media screen and (min-width: 768px) {
    .shoe_description_top_icon {
      .icon-file {
        padding: 8px;
        font-size: calc(15px + 1.1vmin);
        &:hover {
          box-shadow: inset 0.3em 0.3em 0.9em #dee3e6;
          color: rgb(33, 33, 33);
        }
      }
    }
  }
  @media screen and (min-width: 1168px) {
    .shoe_description_top_icon {
      .icon-file {
        padding: 7px;
        font-size: calc(15px + 1.1vmin);
        &:hover {
          box-shadow: inset 0.3em 0.3em 0.9em #dee3e6;
          color: rgb(33, 33, 33);
        }
      }
    }
  }
}

@mixin bar-folio {
  .Header-wrapper-flio::-webkit-scrollbar {
    width: 5px;
  }

  .Header-wrapper-flio::-webkit-scrollbar-track {
    background: #fefefe;
  }

  .Header-wrapper-flio::-webkit-scrollbar-thumb {
    background: #000;
  }

  .Header-wrapper-flio::-webkit-scrollbar-thumb:hover {
    background: #e7e6e3;
  }
}

@mixin bar_inner_black {
  //

  .shoe_description_inner-middle::-webkit-scrollbar {
    width: 5px;
    // display: none;
  }
  /* Track */
  .shoe_description_inner-middle::-webkit-scrollbar-track {
    background: #fefefe;
  }

  /* Handle */
  .shoe_description_inner-middle::-webkit-scrollbar-thumb {
    background: #000;
  }

  /* Handle on hover */
  .shoe_description_inner-middle-simple ::-webkit-scrollbar-thumb:hover {
    background: #e7e6e3;
  }
  //
}

@mixin bar_inner_black-simple_shoe {
  //

  .shoe_description_inner-middle-simple::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  .shoe_description_inner-middle-simple::-webkit-scrollbar-track {
    background: #fefefe;
  }

  /* Handle */
  .shoe_description_inner-middle-simple::-webkit-scrollbar-thumb {
    background: #000;
  }

  /* Handle on hover */
  .shoe_description_inner-middle-simple ::-webkit-scrollbar-thumb:hover {
    background: #e7e6e3;
  }
  //
}

@mixin grainImgVariant_01 {
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 100;
    pointer-events: none;

    background: linear-gradient(
        0deg,
        rgba(58, 56, 52, 0.032),
        rgba(133, 168, 255, 0.018)
      ),
      url(https://grainy-gradients.vercel.app/noise.svg);
    filter: contrast(700%) brightness(100%);

    //
  }
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 150;
    pointer-events: none;

    @include gradient__cahier-dots__soft_strong-blue;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    aspect-ratio: 3/2;
  }
}

@mixin xs_container__3rows_footer_contact {
  .container-contact-links {
    width: 99%;
    height: 100%;
    margin: 70px 0 0 0;
    @include flex-row-space-between-start;
    //
    .link-1 {
      flex: 1;

      @include flexStart-Col;
    }
    .link-2 {
      flex: 1;
      @include flexStart-Col;
    }
    .link-3 {
      flex: 1;
      height: 100%;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      h3 {
        font-weight: 300;

        font-size: calc(8px + 1.1vmin);
      }
    }

    .link-1,
    .link-2,
    .link-3 {
      //
      display: flex;
      color: $font__black__services;
      height: 100%;
      transition: 4s ease-in-out;

      h2 {
        margin: 0 0 20px 0;
        font-size: calc(8px + 1.1vmin);
        font-weight: 500;
        letter-spacing: 2px;
      }
      ul {
        li {
          list-style: none;
          a {
            cursor: pointer;
            font-size: calc(7px + 1.2vmin);
            line-height: calc(8px + 1.2vmin);
            letter-spacing: 1px;
            text-transform: uppercase;
            font-weight: 300;
            color: $font__black__services;
            //
            &:hover {
              background: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }
  }
  //
  //
  @media screen and (min-width: 1468px) {
    .container-contact-links {
      width: 95%;
      margin: 0 auto;
      @include flex-row-space-between-start;
      .link-3 {
        text-align: right;
        flex: 2;
        height: 100%;
      }

      .link-1,
      .link-2,
      .link-3 {
        h2 {
          font-size: calc(10px + 1.1vmin);
        }
        ul {
          li {
            a {
              font-size: calc(4px + 1.2vmin);
              line-height: calc(8px + 1.2vmin);
            }
          }
        }
      }
    }
  }
  //
  //
  @media screen and (min-width: 1668px) {
    .container-contact-links {
      height: 100%;

      .link-1 {
        flex: 1;
        text-align: left;
      }
      .link-2 {
        flex: 1;
        text-align: center;
      }
      .link-3 {
        text-align: right;
        flex: 2;
        height: 100%;
      }

      .link-1,
      .link-2,
      .link-3 {
        h2 {
          margin: 0 0 20px 0;
          font-size: calc(10px + 1.1vmin);
        }
        ul {
          li {
            a {
              cursor: pointer;
              font-size: calc(4px + 1.2vmin);
              line-height: calc(8px + 1.2vmin);
              color: $font__black__services;
            }
          }
        }
      }
    }
  }
}

//  ***  SHADOW

@mixin shadowShoeOpenDialogs {
  -webkit-box-shadow: 9px 3px 18.5px 5px #e2e2e2;
  -moz-box-shadow: 9px 3px 18.5px 5px #e2e2e2;
  box-shadow: 9px 3px 18.5px 5px #e2e2e2;
}
@mixin shadowShoeOpenDialogs_softer1 {
  -webkit-box-shadow: 9px 3px 20px 5px #eaeaea;
  -moz-box-shadow: 9px 3px 20px 5px #eaeaea;
  box-shadow: 9px 3px 20px 5px #eaeaea;
}
//
@mixin borderShoeOpenDialogs {
  border-radius: 58px 58px 58px 58px;
  -moz-border-radius: 58px 58px 58px 58px;
  -webkit-border-radius: 58px 58px 58px 58px;
  //
}

@mixin loaderScaleCircleShoe {
  -webkit-backdrop-filter: blur(27px);
  backdrop-filter: blur(27px);
  //
  -webkit-animation: scaleOutAnim 1s infinite ease-in-out;
  animation: scaleOutAnim 1s infinite ease-in-out;
}

@keyframes scaleOutAnim {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }
}

@mixin Btn-shadow-white-preview-website-mobile {
  // ** NO HOVER
  @media only screen and (max-width: 1267px) and (min-width: 160px) {
    //
    .viewPreview-btn_flio {
      cursor: pointer;
      display: inline-block;
      box-sizing: border-box;
      //
      text-align: center;
      text-decoration: none;
      padding: 0.2em 1em;
      font-size: calc(8px + 1.1vmin);
      font-weight: 600;
      border-radius: 50px;

      @include shadowShoeOpenDialogs;
      @include flex-center;

      background-color: #fff;
      border: 5px solid #ffffff;

      transition: all 0.5s ease-in-out;

      &::after {
        text-align: center;
        margin-left: 0;
        padding: 10px;
        border-radius: 0px;
        background-color: transparent;

        color: #303030;
        font-size: calc(12px + 1.1vmin);
        font-family: "Material Icons";
        content: "arrow_outward";
        transition: all 0.8s ease-in-out;
      }
      //
    }
  }
  @media screen and (min-width: 1067px) {
    .viewPreview-btn_flio {
      //
      padding: 0.2em 1em;
      text-align: right;
      font-size: calc(8px + 1.1vmin);
      font-weight: 600;
      border: 5px solid #fafafa;
      border-radius: 50px;

      background-color: transparent;

      transition: all 0.5s ease-in-out;

      &::after {
        text-align: center;
        margin-left: 0;
        padding: 10px;
        border-radius: 0px;
        background-color: transparent;
        color: #303030;
        font-size: calc(12px + 1.1vmin);
        font-family: "Material Icons";
        content: "arrow_outward";
        transition: all 0.8s ease-in-out;
      }
      //
      &:hover {
        border: 5px solid #fefefe;
        padding: 0.8em 1.2em;
        @include shadowShoeOpenDialogs;
      }
      &:hover::after {
        color: #ffffff;
        margin: 0 0 0 15px;
        padding: 10px;
        border-radius: 100px;
        background-color: rgb(43, 34, 34);
      }
    }
  }
  @media screen and (min-width: 1267px) {
    .viewPreview-btn_flio {
      //

      font-size: calc(10px + 1.1vmin);
      font-weight: 600;
      border: 5px solid #fafafa;
      border-radius: 50px;
      padding: 0.8em 0.1em 0.8em 0.3em;
      text-align: right;
      background-color: transparent;

      transition: all 0.5s ease-in-out;

      &::after {
        text-align: center;
        margin-left: 0;
        padding: 10px;
        border-radius: 0px;
        background-color: transparent;
        color: #303030;
        font-size: calc(12px + 1.1vmin);
        font-family: "Material Icons";
        content: "arrow_outward";
        transition: all 0.8s ease-in-out;
      }
      //
      &:hover {
        border: 5px solid #fefefe;
        padding: 0.8em 1.2em;
        @include shadowShoeOpenDialogs;
      }
      &:hover::after {
        color: #ffffff;
        margin: 0 0 0 15px;
        padding: 10px;
        border-radius: 100px;
        background-color: rgb(43, 34, 34);
      }
    }
  }
}

@mixin btn-flio-container-previewLink {
  pointer-events: none;
  z-index: 601;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  //
  text-align: center;
  text-decoration: none;
  font-size: calc(8px + 1.1vmin);
  font-weight: 600;
  //
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 50px;
  padding: 1.2em 2em;
  background-color: #fafafa;
  border: 2px solid #121212;

  @include shadowShoeOpenDialogs;
  @media only screen and (min-width: 1668px) {
    padding: 1.2em 3em;
  }
}

@mixin img-box-display-block-auto {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}
@mixin img-box-display-block-100 {
  border-radius: 50px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
@mixin car-imgFolio {
  .card-y-img-outer {
    overflow: hidden;
    .img-box-card-y {
      width: 100%;
      height: 100%;

      //
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        display: block;
        aspect-ratio: 1/1;
        border-radius: 50px;
        border: 5px solid #000;
      }
    }
  }
}

@mixin folio-cards-bottom-gradient {
  filter: grayscale(30%);
  //
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 100;
    pointer-events: none;
    background-color: #a9bdcc34;
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    pointer-events: none;

    background: linear-gradient(
      hsla(43, 100%, 70%, 0) 0%,
      hsla(43, 100%, 70%, 0.002) 9%,
      hsla(43, 100%, 70%, 0.008) 34%,

      hsla(43, 100%, 70%, 0.021) 47%,
      hsla(43, 100%, 70%, 0) 56.5%,
      hsla(43, 100%, 70%, 0) 65%,
      hsla(43, 100%, 70%, 0.123) 73%,
      hsla(43, 100%, 70%, 0.275) 80.2%,
      hsla(43, 100%, 70%, 0.394) 86.1%,
      hsla(43, 100%, 70%, 0.478) 91%,
      hsla(43, 100%, 70%, 0.541) 95.2%,
      hsla(43, 100%, 70%, 0.644) 98.2%,
      hsla(43, 100%, 70%, 0.734) 100%
    );
  }
}

@mixin folio-cards-darker-bottom-gradient {
  filter: grayscale(20%);

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 100;
    pointer-events: none;
    //
    //
    background: linear-gradient(0deg, rgba(171, 171, 245, 0), rgba(0, 0, 0, 0)),
      url(https://grainy-gradients.vercel.app/noise.svg);
    filter: contrast(1500%) brightness(180%);
    background-color: rgba(209, 242, 246, 0.074);

    //
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    //
    background: linear-gradient(
      hsla(43, 100%, 70%, 0) 0%,
      hsla(43, 100%, 70%, 0.002) 9%,
      hsla(43, 100%, 70%, 0.008) 34%,

      hsla(43, 100%, 70%, 0.021) 47%,
      hsla(43, 100%, 70%, 0) 56.5%,
      hsla(43, 100%, 70%, 0) 65%,
      hsla(43, 100%, 70%, 0.123) 73%,
      hsla(43, 100%, 70%, 0.275) 80.2%,
      hsla(43, 100%, 70%, 0.394) 86.1%,
      hsla(43, 100%, 70%, 0.478) 91%,
      hsla(43, 100%, 70%, 0.541) 95.2%,
      hsla(43, 100%, 70%, 0.644) 98.2%,
      hsla(43, 100%, 70%, 0.734) 100%
    );
  }
}

@mixin overlay-inner-cards-phone {
  //
  overflow: hidden;
  background: #fafafa;
  border-radius: 50px;
  border: 5px solid #fafafa;
  box-shadow: -12px 17.5px 22px 7px #dddddd;

  .img-box-phone {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 100;
      pointer-events: none;

      box-shadow: inset 0px 2px 51px 0px rgba(255, 255, 255, 0.79);
    }

    img {
      display: block;
      border-radius: 50px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 3/2;
    }
  }
}

@mixin folio-cards-top-gradient {
  // ** LIGHT

  filter: grayscale(30%);
  //
  &::before {
    content: "";
    width: 100%;
    height: 100%;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 100;
    pointer-events: none;
    //
    border-radius: 100px;
    border: 20px solid transparent;
    box-shadow: 0 0 0 1px transparent, inset 0 0 0 1px #fafafa9c;
    //
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 99;
    pointer-events: none;

    background: linear-gradient(
        0deg,
        rgba(138, 138, 229, 0.116),
        rgba(0, 0, 0, 0)
      ),
      url(https://grainy-gradients.vercel.app/noise.svg);
  }
}
