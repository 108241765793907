@import "../../scss/mixins.scss";
// @import "../../scss/main.scss";

.wrapper-headline-prop {
  // background-color: sandybrown;
  padding: 20px 0 40px 0;
  margin: 100px 0 0 0;
  overflow: hidden;
  width: 100%;
  height: auto;
  @include flex-center_col;
  //
  //
  // h1 (WHITE font due to div on HomeScreen changing from white to black when scroll reaches section-2)
  h1 {
    // color: #fdfbf3;
    margin: 0;
    padding: 0;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
    font-weight: 400;

    @media only screen and (max-width: 680px) and (min-width: 170px) {
      // background-color: sienna;
      font-size: calc(9px + 1.1vmin);
      line-height: calc(12px + 1.1vmin);
    }
    // --------
    // TABLET1
    @media only screen and (max-width: 992px) and (min-width: 681px) {
      // background-color: orange;
      font-size: calc(9px + 1.1vmin);
    }
    // --------
    // TABLET2
    @media only screen and (min-width: 993px) and (max-width: 1366px) {
      // background-color: green;
      font-size: calc(9px + 1.1vmin);
    }
    // --------

    &.font-white-h1 {
      color: rgba(254, 252, 246, 0.792);
    }
    &.font-black-h1 {
      color: rgba(50, 50, 50, 0.722);
    }
  }

  // h1
  // .black-font {
  //   // latest
  //   font-size: calc(7px + 1.1vmin);
  //   line-height: calc(20px + 1.1vmin);
  //   font-weight: 400;
  //   color: #6a6a6a;
  // }

  h2 {
    //👾 DONT add the styles of the H2 from the other headline PROPS, as the archive from here below should be smaller
    //
    // font-family: "Wagon-Bold";

    font-family: "NeueMetana-Bold";
    font-family: "Noto Sans", sans-serif;
    font-family: "Kenoky-Light";
    // font-family: "NeutralFace-Bold";
    // font-family: "Jost", sans-serif;
    // archive
    text-transform: capitalize;
    font-weight: 600;
    font-size: calc(120px + 1.1vmin);
    line-height: calc(150px + 1.1vmin);
    letter-spacing: 2px;
    //
    //
    //
    @media only screen and (max-width: 680px) and (min-width: 170px) {
      // background-color: sienna;
      font-size: calc(50px + 1.1vmin);
      line-height: calc(80px + 1.1vmin);
    }
    // --------
    // TABLET1
    @media only screen and (max-width: 992px) and (min-width: 681px) {
      // background-color: orange;
      font-size: calc(90px + 1.1vmin);
      line-height: calc(120px + 1.1vmin);
      letter-spacing: 2px;
    }
    // --------
    // TABLET2
    @media only screen and (min-width: 993px) and (max-width: 1366px) {
      // background-color: green;
      font-size: calc(120px + 1.1vmin);
      line-height: calc(160px + 1.1vmin);
      letter-spacing: 2px;
    }
    //
    &.font-white-h2 {
      color: rgb(254, 252, 246);
    }
    &.font-black-h2 {
      color: #2d2a26;
    }

    //
    //
    //SPAN------
    .spanSubTitleHeadlineCompProp {
      font-family: "BriarNeue";
      font-style: oblique;
    }
  }

  h3 {
    // selected works
    font-size: calc(8px + 1.1vmin);
    font-weight: 200;

    text-transform: uppercase;

    &.font-white-h3 {
      letter-spacing: 1px;
      color: rgba(254, 252, 246, 0.69);
      span {
        color: rgba(254, 252, 246, 0.792);
        letter-spacing: -1px;
      }
    }
    &.font-black-h3 {
      color: #323232;
      span {
        // 19 | 22
        color: #323232;
      }
    }
    //
    //
    span {
      // 19 | 22
      margin: 0 10px;
      text-transform: lowercase;
      font-weight: 600;
    }
  }
}
