//
//-------- btn 🌻
//
//
.container-btn-shop {
  padding: 30px 20px;

  //
  //
  //
  // ** ---- media query -----
  //
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    padding: 30px 10px;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    padding: 30px 10px;
  }

  // ------
  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    padding: 30px 10px;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    padding: 30px 10px;
  }

  //-------------**
  //-------------**
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    padding: 20px;
  }
  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    padding: 20px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    padding: 20px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    padding: 20px;
  }

  //
  //
  //
  //
  .btn_ul-shop {
    // background-color: orange;
    padding: 5px 0;
    // ** GRID
    display: grid;
    grid-template-columns: 1.8fr; // Here, you specify the columns with different widths
    gap: 30px; // Adjust the gap between the boxes
    // place-content: center;
    //
    //

    .btn-li-shop {
      // STICKY
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      a {
        overflow: hidden;

        display: flex;
        justify-content: center;
        align-items: center;
        //
        // NavLink
        border-radius: 30px;
        text-transform: capitalize;
        font-weight: 500;
        //

        letter-spacing: 1px;
        font-size: calc(10px + 1vmin);
        line-height: calc(5px + 1vmin);
        // ** btn --
        // width: 180px;
        border: 0.5px solid #d2d2d21d;
        padding: 22px 38px;
        // ** btn --
        //
        color: #fdfaf55e;
        //
        &:hover {
          background-color: #0d0d0d;
          color: #f2e6d2; //grey yellowish
          // font-weight: 500;
          border: 2px solid #0d0d0d;
        }

        //
        //
        //
        // ** ---- media query -----
        //
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          padding: 15px 28px;
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          padding: 15px 32px;
        }

        // ------
        // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          padding: 15px 32px;
        }
        //
        // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          padding: 15px 32px;
        }

        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          padding: 18px 32px;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          padding: 18px 32px;
        }
        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          padding: 22px 38px;
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          padding: 26px 46px;
        }
      }
    }
  }
}
//----- btn
