/*


                  ((H1))


*/

@mixin Headline_H1_about-SECTION {
  //  lARGE text "in Progress"
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    font-size: calc(45px + 1.1vmin);
    line-height: calc(49px + 1.1vmin);

    text-align: center;
    letter-spacing: 1px;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    font-size: calc(65px + 1.1vmin);
    line-height: calc(58px + 1.1vmin);
    letter-spacing: 2px;

    text-align: center;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    font-size: calc(65px + 1.1vmin);
    line-height: calc(60px + 1.1vmin);
    letter-spacing: -2px;
    text-align: center;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    font-size: calc(75px + 1.1vmin);
    line-height: calc(70px + 1.1vmin);
    letter-spacing: -2px;
    text-align: center;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    font-size: calc(95px + 1.1vmin);
    line-height: calc(100px + 1.1vmin);
    letter-spacing: 1px;
    text-align: center;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    font-size: calc(55px + 1.1vmin);
    line-height: calc(54px + 1.1vmin);
    letter-spacing: 1px;
    text-align: left;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    max-width: 1300px;
    font-size: calc(80px + 1.1vmin);
    line-height: calc(75px + 1.1vmin);
    letter-spacing: -1px;
    text-align: left;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    font-size: calc(80px + 1.1vmin);
    line-height: calc(75px + 1.1vmin);
    letter-spacing: -1px;
    text-align: left;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    max-width: 650px;
    max-width: 1300px;
    font-size: calc(95px + 1.1vmin);
    line-height: calc(95px + 1.1vmin);
    letter-spacing: -4px;
    text-align: left;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    max-width: 650px;
    max-width: 1300px;
    font-size: calc(100px + 1.1vmin);
    line-height: calc(105px + 1.1vmin);
    letter-spacing: -1px;
    text-align: left;
  }
  //-------------**
  //-------------**
  //-------------**
  //-------------**
}

/*


                  ((P))


*/
@mixin Headline_P_about-SECTION {
  // inspired by my artistic journey ...
  // wrap the 2 classes with the P, the P tag should have the 2 classes below, otherwise other components will take the styles
  // -------- **
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    //
    padding: 30px 10px 40px 10px;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    //
    font-size: calc(12px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    letter-spacing: -0.1px;
  }

  // MOBILE - LANDSCAPE (658 x 606 or 663 x 662 ) on google responsive
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    padding: 30px 0 40px 0;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    //
    font-size: calc(13px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
    letter-spacing: -0.2px;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    //
    padding: 30px 0 40px 0;
    max-width: 550px;
    margin: 0 auto;
    text-align: center;
    //
    font-size: calc(11px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);

    letter-spacing: -0.1px;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    //
    padding: 30px 0 40px 0;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    //

    font-size: calc(12px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    letter-spacing: -0.2px;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    //------
    max-width: 680px;
    text-align: center;
    padding: 20px 0;
    margin: 0 auto;
    //-------
    font-size: calc(16px + 1.1vmin);
    line-height: calc(31px + 1.1vmin);
    letter-spacing: -0.1px;
  }

  // TABLET2 - PORTRAIT 994 x 1367 (on google) or (1024 x 600 [ Desktop/Laptop ])  http://responsivetesttool.com/?url=http://localhost:6255/
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    text-align: left;
    padding: 5px 0;
    margin: 20px 0;
    font-size: calc(12px + 1.1vmin);
    line-height: calc(22px + 1.1vmin);
    letter-spacing: -0.2px;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    //
    text-align: left;
    max-width: 600px;
    padding: 20px 0;
    margin: 40px 0;
    //
    font-size: calc(13px + 1.1vmin);
    line-height: calc(22px + 1.1vmin);
    letter-spacing: -0.2px;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    //
    text-align: left;
    max-width: 550px;
    padding: 20px 0;
    margin: 40px 0;
    //
    font-size: calc(14px + 1.1vmin);
    line-height: calc(24px + 1.1vmin);
    letter-spacing: -0.2px;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    //
    text-align: left;
    max-width: 600px;
    padding: 20px 0;
    margin: 40px 0;
    //
    font-size: calc(13px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    letter-spacing: -0.1px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    //
    text-align: left;
    max-width: 700px;
    padding: 20px 0;
    margin: 40px 0;
    //
    font-size: calc(16px + 1.1vmin);
    line-height: calc(26px + 1.1vmin);
    letter-spacing: -0.1px;
  }
  //-------------**
  //-------------**
  //-------------**
  //-------------**
}

/*


                  ((BUTTON))


*/
@mixin Headline_BTN-wrapper_about-SECTION {
  // -------- **
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(132, 155, 152);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  // MOBILE - LANDSCAPE (658 x 606 or 663 x 662 ) on google responsive
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }
}

//-------------**
//-------🟩 ------**
//-------------**
@mixin Headline_BUTTON_about-SECTION {
  // -------- **
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    font-size: calc(13px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    margin: 10px 0 40px 0;
    padding: 0.8em 2.2em 0.8em 2.2em;
    width: 180px;
  }
  // MOBILE - LANDSCAPE (658 x 606 or 663 x 662 ) on google responsive
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    font-size: calc(13px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    margin: 10px 0 40px 0;
    //---------
    // btn size
    padding: 0.8em 2.2em 0.8em 2.2em;
    width: 180px;
    //-------
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    font-size: calc(8px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    letter-spacing: 1px;
    margin: 30px 0 70px 0;
    //---------
    // btn size
    padding: 0.6em 2.6em 0.6em 2.6em;
    width: 200px;
    //-------
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    font-size: calc(9px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    letter-spacing: -0.1px;
    //---------
    // btn size
    padding: 0.7em 2.6em 0.7em 2.6em;
    width: 220px;
    //-------
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // color: teal;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    letter-spacing: -0.1px;
    margin: 40px 0 70px 0;
    //---------
    // btn size
    padding: 0.9em 2.6em 0.9em 2.6em;
    width: 300px;
    //-------
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    font-size: calc(9px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    letter-spacing: 0.1px;
    margin: 40px 0 40px 0;
    //---------
    // btn size
    padding: 0.9em 2.6em 0.9em 2.6em;
    width: 220px;
    //-------
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    font-size: calc(13px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    margin: 0 0 120px 0;

    //---------
    // btn size
    padding: 0.9em 2.6em 0.9em 2.6em;
    width: 220px;
    //-------
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    font-size: calc(13px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    margin: 0 0 120px 0;

    //---------
    // btn size
    padding: 0.9em 2.6em 0.9em 2.6em;
    width: 220px;
    //-------
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    font-size: calc(13px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    //---------
    // btn size
    padding: 0.9em 2.6em 0.9em 2.6em;
    width: 280px;
    //-------
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    font-size: calc(16px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    //---------
    // btn size
    padding: 0.9em 2.6em 0.9em 2.6em;
    width: 280px;
    //-------
  }
  //-------------**
  //-------------**
  //-------------**
  //-------------**
}
