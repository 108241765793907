/*

          🖐️   IMG STICKY (right side)


*/

@mixin mediaQueries___wrapper__drawingArchiveScreen {
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(220, 202, 193);
    padding: 0 10px;
    &:nth-child(even) {
      flex-direction: column;
    }
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: rgb(160, 95, 158);
    padding: 0 10px;
    &:nth-child(even) {
      flex-direction: column;
    }
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    padding: 0 100px;
    &:nth-child(even) {
      flex-direction: column;
    }
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    padding: 0 100px;
    &:nth-child(even) {
      flex-direction: column;
    }
    // background-color: rgb(143, 225, 225);
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: burlywood;
    padding: 0 150px;
    &:nth-child(even) {
      flex-direction: column;
    }
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: sienna;
    padding: 0 50px;
    &:nth-child(even) {
      flex-direction: column;
    }
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: olive;
    padding: 0;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
    padding: 0;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    padding: 40px 0;
    width: 100%;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;

    padding: 40px 0;
    width: 100%;
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
}

/*




*/
@mixin mediaQueries___fullImg____drawingArchiveScreen {
  //
  //
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(101, 198, 186);
    //
    flex-direction: column;
    // ----------
    // height: auto;
    // min-height: 750vh;
    min-height: var(--min-height); // Use the --min-height variable here
    //
    width: 100%;
    // min-height: 10vh;
    //
    padding: 0px 0 0px 0;
    margin: 0px 0;
    // line is too long for small devices
  }
  // ------
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: cadetblue;

    flex-direction: column;
    // padding: 70px 0 30px 0;
    // margin: 10px 0;
    width: 100%;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    width: 100%;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: greenyellow;

    width: 100%;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: burlywood;
    width: 100%;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: sienna;

    width: 80%;
    margin: 0 auto;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: olive;
    // background-color: plum;
    width: 55%;
    min-height: var(--min-height);
    padding: 30px 0 30px 0;
    margin: 10px 0;
    // height: auto;
    // min-height: 750vh;

    //
    display: flex;
    justify-content: center;
    align-items: flex-start;

    //
    //
    position: sticky;
    position: -webkit-sticky;
    top: 0%;
    //@at-root
    overflow: hidden;
    overflow-y: scroll;
    //
    &::-webkit-scrollbar {
      display: none;
    }
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;

    width: 55%;
    min-height: var(--min-height);
    padding: 30px 0 30px 0;
    margin: 10px 0;

    //
    display: flex;
    justify-content: center;
    align-items: flex-start;

    //
    //
    position: sticky;
    position: -webkit-sticky;
    top: 0%;
    //@at-root
    overflow: hidden;
    overflow-y: scroll;
    //
    &::-webkit-scrollbar {
      display: none;
    }
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    //
    width: 60%;
    min-height: var(--min-height);
    // height: 100vh;
    // min-height: 130vh;
    padding: 30px 0 30px 0;
    margin: 10px 0;
    // background-color: red;
    //
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: sticky;
    position: -webkit-sticky;
    top: 0%;
    //@at-root
    overflow: hidden;
    overflow-y: scroll;
    //
    &::-webkit-scrollbar {
      display: none;
    }
    //
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;
    width: 60%;
    min-height: var(--min-height);
    padding: 30px 0 30px 0;
    margin: 10px 0;
    // height: auto;
    // min-height: 750vh;

    //
    display: flex;
    justify-content: center;
    align-items: flex-start;

    //
    //
    position: sticky;
    position: -webkit-sticky;
    top: 0%;
    //@at-root
    overflow: hidden;
    overflow-y: scroll;
    //
    &::-webkit-scrollbar {
      display: none;
    }
    //
  }
  //-------------**
  //-------------**
}

/*




          🖐️   TEXT (left side)





*/
@mixin mediaQueries_wrapper-TEXT____drawingArchiveScreen {
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    width: 100%;
    // background-color: rgb(101, 198, 186);
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    width: 100%;
    // background-color: cadetblue;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    width: 100%;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: greenyellow;
    width: 100%;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: burlywood;
    width: 100%;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: sienna;

    width: 100%;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: rgb(228, 228, 158);
    width: 45%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 10px;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: rgb(87, 107, 47);
    width: 45%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 10px;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    width: 40%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;

    width: 40%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }
  //-------------**
  //-------------**
}

@mixin mediaQueries__container_text-drawingScreen__archive {
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(101, 198, 186);
    padding: 20px 20px 10px 20px;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: cadetblue;
    padding: 20px 30px 10px 30px;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    padding: 20px 40px 10px 40px;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: greenyellow;
    padding: 20px 80px 10px 80px;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: burlywood;
    padding: 20px 80px 10px 80px;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: sienna;
    padding: 20px 100px 10px 100px;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: olive;
    padding: 20px 30px 10px 30px;
  }

  // 🥬 DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
    padding: 20px 30px 10px 30px;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    padding: 20px 50px 10px 50px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;
    padding: 20px 40px 10px 40px;
  }
  //-------------**
  //-------------**
}
/*
















           🍰       ((H1))


*/
// **  Drawing title
@mixin Headline_H1__DrawingTitle___drawingArchiveScreen {
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);

    font-weight: 600;
    letter-spacing: 2px;
    font-size: calc(40px + 1.1vmin);
    line-height: calc(110px + 1.1vmin);

    text-align: left;
    align-self: flex-start;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: cadetblue;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: calc(40px + 1.1vmin);
    line-height: calc(110px + 1.1vmin);
    text-align: left;
    align-self: flex-start;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    font-weight: 600;
    letter-spacing: 3px;

    font-size: calc(40px + 1.1vmin);
    line-height: calc(85px + 1.1vmin);
    text-align: left;
    align-self: flex-start;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: rgb(179, 211, 131);
    font-weight: 600;
    letter-spacing: 3px;

    font-size: calc(40px + 1.1vmin);
    line-height: calc(85px + 1.1vmin);
    text-align: left;
    align-self: flex-start;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: rgb(205, 222, 135);
    font-weight: 600;
    letter-spacing: 2px;

    font-size: calc(60px + 1.1vmin);
    line-height: calc(85px + 1.1vmin);
    text-align: left;
    align-self: flex-start;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: sienna;
    font-weight: 600;
    letter-spacing: 2px;

    font-size: calc(60px + 1.1vmin);
    line-height: calc(85px + 1.1vmin);
    text-align: left;
    align-self: flex-start;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: olive;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: calc(45px + 1.1vmin);
    line-height: calc(55px + 1.1vmin);
    text-align: left;
    align-self: flex-start;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;

    font-weight: 600;
    letter-spacing: 1px;
    font-size: calc(45px + 1.1vmin);
    line-height: calc(55px + 1.1vmin);
    text-align: left;
    align-self: flex-start;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    text-align: left;
    align-self: flex-start;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: calc(100px + 1.1vmin);
    line-height: calc(75px + 1.1vmin);
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;
    text-align: left;
    align-self: flex-start;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: calc(60px + 1.1vmin);
    line-height: calc(75px + 1.1vmin);
  }
  //-------------**
  //-------------**
  //-------------**
  //-------------**
}

/*


           🍰       ((H2))


*/
// **  Drawing title
@mixin Headline_H2__DrawingTitle___drawingArchiveScreen {
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    text-align: left;
    align-self: flex-start;
    letter-spacing: 1px;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(28px + 1.1vmin);
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: cadetblue;
    text-align: left;
    align-self: flex-start;
    letter-spacing: 1px;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(28px + 1.1vmin);
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    text-align: left;
    align-self: flex-start;
    letter-spacing: -1px;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(28px + 1.1vmin);
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: rgb(143, 225, 225);
    text-align: left;
    align-self: flex-start;
    letter-spacing: -1px;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(28px + 1.1vmin);
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: burlywood;
    text-align: left;
    align-self: flex-start;
    letter-spacing: -1px;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(12px + 1.1vmin);
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: sienna;
    text-align: left;
    align-self: flex-start;
    letter-spacing: -1px;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(12px + 1.1vmin);
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: olive;
    text-align: left;
    align-self: flex-start;
    letter-spacing: -1px;
    font-size: calc(6px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
    text-align: left;
    align-self: flex-start;
    //
    font-weight: 600;
    letter-spacing: -0.5px;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    align-self: flex-start;
    font-weight: 600;
    letter-spacing: -1px;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;
    align-self: flex-start;
    font-weight: 600;
    letter-spacing: -1px;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
  }
  //-------------**
  //-------------**
  //-------------**
  //-------------**
}

/*


           🍰       ((p))


*/
// **  Drawing title
@mixin Headline_P__DrawingTitle___drawingArchiveScreen {
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    letter-spacing: -0.2px;
    margin: 12px 0;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(17px + 1.1vmin);
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: rgb(214, 214, 173);
    letter-spacing: -0.2px;
    margin: 12px 0;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(17px + 1.1vmin);
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    letter-spacing: -0.5px;
    margin: 10px 0;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: rgb(143, 225, 225);
    letter-spacing: -0.8px;
    margin: 10px 0;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: burlywood;
    letter-spacing: -0.3px;
    margin: 10px 0;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: sienna;

    letter-spacing: -0.3px;
    margin: 10px 0;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: olive;
    padding: 0;
    letter-spacing: 1px;
    margin: 10px 0;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
  }

  // 🥬 DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
    padding: 0;
    letter-spacing: 1px;
    margin: 10px 0;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    letter-spacing: -0.2px;
    margin: 12px 0;
    font-size: calc(11px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;
    letter-spacing: -0.2px;
    margin: 12px 0;
    font-size: calc(11px + 1.1vmin);
    line-height: calc(17px + 1.1vmin);
  }
  //-------------**
  //-------------**
  //-------------**
  //-------------**
}

/*


           🍰       ((li TAGS))


*/
// **  Drawing title
@mixin LI__listGroupTags__DrawingTitle___drawingArchiveScreen {
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    font-weight: 400;
    letter-spacing: 1px;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(2px + 1.1vmin);
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: cadetblue;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(2px + 1.1vmin);
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: rgb(143, 225, 225);
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: burlywood;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: sienna;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: olive;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;

    font-weight: 400;
    letter-spacing: 1px;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(2px + 1.1vmin);
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: black;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(2px + 1.1vmin);
  }
  //-------------**
  //-------------**
  //-------------**
  //-------------**
}
