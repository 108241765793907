//this one doesnt have media queries for small devices, because the hover effect will only work on desktop
// ----------------
// **  DESKTOP 👾
// ----------------
// * Desktop styles for screens 1367px and above
// * big/large screens
//
@media only screen and (min-width: 1325px) {
  .wrapper-modal {
    position: absolute;
    top: -40%;
    left: 0%;
    right: 0%;
    bottom: 100%;
    background-color: pink;
    .modalContainer {
      height: 300px;
      width: 340px;
      //   min-height: 20vh;
      background-color: #0d0d0d;

      //   border: 1px solid rgb(248, 248, 248, 0.2);
      border-radius: 60px;

      //
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;

      overflow: hidden;
      //❎ if you dont add the pointer event none, ou will have the img hover anim clashing with the hover of the text from the project folder
      pointer-events: none;
      //
    }
    .modalSlider {
      position: absolute;

      width: 100%;
      height: 100%;
      // transform: translateY(-200%);
      transition: top 0.5s cubic-bezier(0.76, 0, 0.24, 1);
      //
      //
    }
    .modal-hover {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      img {
        border-radius: 30px;
        height: auto;
        // width: 100%;

        object-fit: cover;
        aspect-ratio: 0.8/1;
      }
    }
    //----------------------------------
    // ** CURSOR
    //  ** the cursor related goes outside of the ."modalContainer"
    //
    .cursor,
    .cursorLabel {
      width: 80px;
      height: 80px;

      //   --- trans- blur
      background: rgba(255, 255, 255, 0.05);
      -webkit-backdrop-filter: blur(7px);
      backdrop-filter: blur(7px);
      border: 2px solid rgba(255, 253, 246, 0.096);
      //    border: 1px solid rgba(255, 255, 255, 0.025);
      //   --- trans- blur

      //   background-color: #455ce9;
      position: absolute;
      pointer-events: none;
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;
      color: rgb(255, 253, 246);
      font-weight: 400;
    }
    .cursorLabel {
      background-color: transparent;
    }
  }
}
