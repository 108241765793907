.wrapper-project-modal {
  // background-color: orange;
  margin: 0 auto;
  width: 90%;
  height: 100%;
  min-height: 70vh;
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  //

  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: yellow;
    min-height: 40vh;
    padding: 50px 0;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: blueviolet;
    min-height: 50vh;
    padding: 60px 0;
  }

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orange;
    min-height: 40vh;
    padding: 60px 0;
  }
  // TABLET1 - LANDSCAPE
  // on the https://responsivetesttool.com/, look for   414  x 896 on the mobile, then rotate and you will get the 896 x 414 which will give you this device
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: orangered;
    min-height: 50vh;
    padding: 70px 0;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: sienna;
    min-height: 30vh;
    padding: 20px 0;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: sandybrown;
    min-height: 40vh;
    padding: 60px 0;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: seagreen;
    min-height: 50vh;
    padding: 80px 0;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olive;
    min-height: 50vh;
    padding: 120px 0;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background: pink;
    min-height: 50vh;
    padding: 120px 0;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;
    min-height: 50vh;
    padding: 120px 0;
  }
  //-------------**

  //
  //
  .container-project-modal {
    width: 100%;
    height: 100%;
    // background-color: pink;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //
    border-top: 1px solid rgba(255, 252, 228, 0.923);
    transition: all 0.5s linear;
    &:hover {
      // background-color: pink;
      opacity: 0.4;
    }
    // controls the size of each

    padding: 150px 120px 150px 120px;
    //--------------
    //
    // -------- **
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      // background-color: rgb(220, 202, 193);
      padding: 35px 40px 30px 30px;
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      // background-color: blueviolet;
      padding: 40px 35px 40px 35px;
    }

    // -------- **

    // TABLET1 - PORTRAIT
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      // background-color: orange;
      padding: 40px 38px 40px 38px;
    }
    // TABLET1 - LANDSCAPE
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      // background-color: orangered;
      padding: 42px 55px 42px 55px;
    }
    //-------------**
    //-------------**
    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      // background-color: sienna;
      padding: 40px 50px 40px 50px;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      // background-color: sandybrown;
      padding: 40px 30px 40px 30px;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      // background-color: olive;
      padding: 40px 80px 40px 80px;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      // background-color: olivedrab;
      padding: 40px 80px 40px 80px;
    }
    //-------------**
    //-------------**

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      // background-color: pink;
      padding: 40px 120px 40px 120px;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      // background-color: plum;
      padding: 40px 120px 40px 120px;
    }

    //
    // -------------

    //
    // -------- TEXT
    //
    h2 {
      color: rgba(255, 252, 228, 0.923);
      font-size: calc(85px + 1.1vmin);
      font-weight: 300;
      &:hover {
        transform: translateX(-10px);
        transition: all 1.5s linear;
      }
      // -------- **
      // -------- **
      // -------- **
      // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        // background-color: rgb(220, 202, 193);
        font-size: calc(20px + 1.1vmin);
      }
      // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        // background-color: rgb(160, 95, 158);
        font-size: calc(20px + 1.1vmin);
      }

      // -------- **
      // -------- **
      // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        // background-color: orangered;
        font-size: calc(20px + 1.1vmin);
      }
      //
      // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        // background-color: orange;
        font-size: calc(25px + 1.1vmin);
      }

      //-------------**
      //-------------**
      // TABLET2 - PORTRAIT 994 x 1367
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        // background-color: burlywood;
        font-size: calc(24px + 1.1vmin);
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        // background-color: sienna;
        font-size: calc(30px + 1.1vmin);
      }

      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        // background-color: olive;
        font-size: calc(38px + 1.1vmin);
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        // background-color: olivedrab;
        font-size: calc(38px + 1.1vmin);
      }
      //-------------**
      //-------------**

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        // background-color: pink;
        font-size: calc(45px + 1.1vmin);
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        // background-color: plum;
        font-size: calc(45px + 1.1vmin);
      }
    }

    // --------------
    //
    // --------------
    p {
      font-size: calc(20px + 1.1vmin);
      color: rgba(255, 252, 228, 0.923);
      font-weight: 300;
      &:hover {
        color: rgb(117, 117, 117);
        transform: translateX(-10px);
        transition: all 1.5s linear;
      }

      // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        // background-color: rgb(220, 202, 193);
        font-size: calc(9px + 1.1vmin);
      }
      // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        // background-color: rgb(160, 95, 158);
        font-size: calc(9px + 1.1vmin);
      }

      // -------- **
      // -------- **
      // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        // background-color: orangered;
        font-size: calc(9px + 1.1vmin);
      }
      //
      // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        // background-color: orange;
        font-size: calc(9px + 1.1vmin);
      }

      //-------------**
      //-------------**
      // TABLET2 - PORTRAIT 994 x 1367
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        // background-color: burlywood;
        font-size: calc(6px + 1.1vmin);
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        // background-color: sienna;
        font-size: calc(10px + 1.1vmin);
      }

      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        // background-color: olive;
        font-size: calc(12px + 1.1vmin);
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        // background-color: olivedrab;
        font-size: calc(12px + 1.1vmin);
      }
      //-------------**
      //-------------**

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        // background-color: pink;
        font-size: calc(12px + 1.1vmin);
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        // background-color: plum;
        font-size: calc(12px + 1.1vmin);
      }
    }
  }
}
