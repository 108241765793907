/*


                  ((H1))


*/
// ** selected works
@mixin Headline_H1__BTN-Dot {
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    word-break: normal;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(11px + 1.1vmin);
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: cadetblue;
    font-size: calc(8px + 1.1vmin);
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    font-size: calc(9px + 1.1vmin);
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: blue;
    font-size: calc(9px + 1.1vmin);
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: burlywood;

    font-size: calc(9px + 1.1vmin);
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: sienna;

    font-size: calc(9px + 1.1vmin);
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: olive;
    font-size: calc(11px + 1.1vmin);
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
    font-size: calc(11px + 1.1vmin);
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    font-size: calc(11px + 1.1vmin);
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;
    font-size: calc(11px + 1.1vmin);
  }
  //-------------**
  //-------------**
  //-------------**
  //-------------**
}
/*


                  ((H2))


*/
// ** selected works
@mixin Headline_H2__BTN-Dot {
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    gap: 1px;
    font-size: calc(9px + 1.1vmin);
    line-height: calc(9px + 1.1vmin);
    // ** so that the text will be entrepose one line under the other (DONT REMOVE this)
    word-break: normal;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: cadetblue;
    gap: 1px;
    font-size: calc(9px + 1.1vmin);
    line-height: calc(9px + 1.1vmin);
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    font-weight: 400;
    font-size: calc(9px + 1.1vmin);
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: goldenrod;
    font-weight: 400;
    font-size: calc(9px + 1.1vmin);
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: burlywood;
    font-weight: 400;
    font-size: calc(9px + 1.1vmin);
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: sienna;
    font-weight: 400;
    font-size: calc(9px + 1.1vmin);
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: olive;
    font-size: calc(10px + 1.1vmin);
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
    font-size: calc(10px + 1.1vmin);
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    font-size: calc(10px + 1.1vmin);
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;
    font-size: calc(10px + 1.1vmin);
  }
  //-------------**
  //-------------**
  //-------------**
  //-------------**
}

/*


           🍰       ((UNDERLINE animation))


*/
// ** selected works
@mixin Headline_UnderlineAnim__BTN-Dot {
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(72, 205, 189);
    /* 🟥  this will change depending on the size of the dot, so if the dot is bigger, the underline will go further down, and its because the dot is making the box bigger on the Y axis
     if the DOT is:
       width: 15px;
        height: 15px;
        ------
        this is okay:
       bottom: -3px;
      IF YOU ADD THE BELOW, the -3px the animaton wont work
    transform: translateY(-3px);
    height: 1px;
     */

    bottom: 1px;
    height: 1px;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: cadetblue;

    bottom: 1px;
    height: 1px;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    /* 🟥 this will change depending on the size of the dot, so if the dot is bigger, the underline will go further down, and its because the dot is making the box bigger on the Y axis
     if the DOT is:
       width: 15px;
        height: 15px;
        ------
        this is okay:
       bottom: -3px;
    transform: translateY(-3px);
    height: 1px;
     */
    bottom: 1px;
    height: 1px;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: yellow;
    bottom: 1px;
    height: 1px;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: burlywood;

    bottom: 1px;
    height: 1px;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: rgb(200, 89, 37);

    bottom: 1px;
    height: 1px;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: olive;
    bottom: 1px;
    height: 1px;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
    bottom: 1px;
    height: 1px;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    bottom: 1px;
    height: 1px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;
    bottom: 1px;
    height: 1px;
  }
  //-------------**
  //-------------**
  //-------------**
  //-------------**
}
