.wrapper-btn-3dEffect {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0 20px 0;
  // background: #fff;
  //
  // ** button

  .btn-3dEffect {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: auto;

    button {
      font-family: "Kenoky-Light";
      letter-spacing: 2px;
      //
      width: 200px;
      outline: none;
      cursor: pointer;
      border: 0;
      font-weight: 600;
      color: #382b22;
      padding: 16px 28px;
      background: #e2e5e6;
      border: 1px solid #030303;
      border-radius: 50px;
      transform-style: preserve-3d;
      transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
        background 150ms cubic-bezier(0, 0, 0.58, 1);

      &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
        border-radius: inherit;
        box-shadow: 0 0 0 0.5px #03030344, 0 5px 40px 20px transparent;
        transform: translate3d(0, 0.75em, -1em);
        transition: transform 150ms cubic-bezier(0, 0, 0.58, 1),
          box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
      }

      //
      //

      &:hover {
        background: #dfe7ea;
        transform: translate(0, 0.25em);
      }

      &:hover::before {
        box-shadow: 0 0 0 2px #e5eaeb, 0 5px 40px 20px #d3d6d6;
        transform: translate3d(0, 0.5em, -1em);
      }

      &:active {
        background: #f0f0f0;
        transform: translate(0em, 0.75em);
      }

      &:active::before {
        box-shadow: 0 0 0 2px #f0f0f0, 0 0 #d8f5fe;
        transform: translate3d(0, 0, -1em);
      }

      //
      //
    }
  }
}
