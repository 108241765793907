//
//
@import "../../scss/font-sizes-about-section";
//
// section
//----------------
// **  WRAPPER
//----------------
//
//
.is--hero-aboutWrapper-section-white,
.is--hero-aboutWrapper-section-black {
  min-height: 85vh;
  padding: 30px 0;

  // ** GRID  --------
  display: grid;

  grid-template-columns: 1fr;
  gap: 10px;

  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    grid-template-columns: 0.8fr 0.9fr;
    gap: 1fr;
    min-height: 25vh;
    margin: 100px 0;
  }

  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    min-height: 25vh;
    grid-template-columns: 0.8fr 0.9fr;
    gap: 1fr;
  }

  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    grid-template-columns: 0.8fr 0.9fr;
    gap: 1fr;
  }

  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    grid-template-columns: 0.8fr 0.9fr;
    gap: 1fr;
  }

  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    grid-template-columns: 0.8fr 0.9fr;
    gap: 1fr;
    min-height: 25vh;
  }

  .is--hero-aboutContainer-section-white,
  .is--hero-aboutContainer-section-black {
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      margin-bottom: 28px;
      max-width: 1350px;
      font-weight: 300;
      font-size: calc(195px + 1.1vmin);
      line-height: calc(190px + 1.1vmin);
      text-align: center;

      &.is--hero-about-section-h1-white,
      &.is--hero-about-section-h1-black {
        word-break: break-all;
        // ** Desktop > will have this 84px
        font-family: "Kenoky-Light";

        @include Headline_H1_about-SECTION;
      }

      &.is--hero-about-section-h1-white {
        color: #fefcf6;
      }

      &.is--hero-about-section-h1-black {
        color: #0d0d0d;
      }
    }

    p {
      &.is--hero-about-section-p-white,
      &.is--hero-about-section-p-black {
        margin: 100px 0 70px 0;

        width: 100%;
        max-width: 1350px;
        font-weight: 300;
        font-size: calc(25px + 1.1vmin);
        line-height: calc(50px + 1.1vmin);
        letter-spacing: 1px;

        @include Headline_P_about-SECTION;
      }

      // section-3 /when BG is black, font color will be white
      // inspired by my artistic journey ...
      &.is--hero-about-section-p-white {
        // ----------
        color: #989185;
      }
      // section-2 /when BG is white, font color will be black
      &.is--hero-about-section-p-black {
        color: #3232328e;
      }
    }

    .btn-wrapper {
      @include Headline_BTN-wrapper_about-SECTION;

      button {
        &.is--hero-about-section-btn-white,
        &.is--hero-about-section-btn-black {
          margin: 40px 0 100px 0;

          border-radius: 50px;

          width: 300px;
          padding: 2.4em 2.4em 2.4em 2.4em;
          //
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          transition: 1s all ease-in-out;
          font-weight: 500;

          cursor: pointer;

          @include Headline_BUTTON_about-SECTION;
        }

        &.is--hero-about-section-btn-white {
          color: #fefcf6;
          border: 2px solid #fefcf6;
          background-color: transparent;

          &:hover {
            border: 2px solid #2d2a26d3;
            background-color: #fefcf6;
            color: rgba(45, 42, 38, 0.829);
          }
        }

        &.is--hero-about-section-btn-black {
          color: #fefcf6;
          border: 2px solid transparent;
          background-color: #0d0d0d;
          &:hover {
            border: 2px solid #0d0d0d;
            background-color: transparent;
            color: #0d0d0d;
          }
        }
      }
    }
  }
}

.aboutSection-imgWrapper-white,
.aboutSection-imgWrapper-black {
  overflow: hidden;
  border-radius: 30px;

  .aboutSection-img-container {
    border-radius: 30px;
    overflow: hidden;
    width: 100%;
    height: 80%;

    border: 3.5px solid #2d2a26d3;

    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      height: 100%;
      border: 3px solid #2d2a26d3;
    }

    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      height: 100%;
      border: 2px solid #2d2a26d3;
    }

    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      height: 100%;
    }

    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      height: 100%;
    }

    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      height: 100%;
    }

    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      height: 100%;
    }

    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      border-radius: 100px;
      height: 60%;
      border: 2px solid #2d2a26d3;
    }

    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      border-radius: 100px;
      height: 60%;
      border: 2px solid #2d2a26d3;
    }

    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      border-top-left-radius: 100px;
      border-top-right-radius: 100px;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      height: 70%;

      border: 3.5px solid #2d2a26d3;
    }

    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      border-top-left-radius: 100px;
      border-top-right-radius: 100px;
      border-bottom-left-radius: 50px;
      border-bottom-right-radius: 50px;
      height: 70%;
      border: 3.5px solid #2d2a26d3;
    }

    img {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      aspect-ratio: 1.6/1;
    }
  }
}
