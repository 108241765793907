#wrapper__contact-Screen {
  //
  padding-top: 300px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  //
  //
  border-top: 3px solid #000;
  border-left: 5px solid #000;
  border-right: 5px solid #000;
  //
  //

  .container___contact-Screen {
    width: 100%;
    height: 100%;

    //
    // ----------------
    //    Description 🧙‍♂️
    //-----------------
    .wrapper-description__contact-screen {
      min-height: 20vh;
      padding: 100px 0 40px 0;
      display: flex;

      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 80%;
      height: 100%;

      // MOBILE - PORTRAIT
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        width: 100%;
        padding: 100px 0 10px 0;
        // background-color: khaki;
      }
      // MOBILE - LANDSCAPE
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        width: 100%;
        padding: 100px 0 10px 0;
        // background-color: cadetblue;
      }

      // TABLET1 - PORTRAIT
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        width: 100%;
        // background-color: coral;
      }
      // TABLET1 - LANDSCAPE
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        width: 100%;
        // background-color: sienna;
      }

      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        width: 100%;
        // background-color: orange;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        width: 100%;
        // background-color: orangered;
      }

      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        width: 80%;
        // background-color: olive;
        padding: 150px 0 40px 0;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        padding: 150px 0 40px 0;
        width: 80%;
        // background-color: seagreen;
      }

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        padding: 200px 0 40px 0;
        width: 75%;
        // background-color: rosybrown;
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        padding: 200px 0 40px 0;
        width: 90%;
        // background-color: plum;
      }
      // -------- **
      // -------- **
      // -------- **
      // -------- **
      //
      //
      .container-description__contact-screen {
        width: 100%;
        padding: 30px 0 30px 0;
        // background-color: orange;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 0.4fr;
        gap: 15px;

        // -------- **
        // -------- **
        // -------- **
        // -------- **
        // MOBILE - PORTRAIT
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          grid-template-columns: 1fr;
          gap: 10px;
        }
        // MOBILE - LANDSCAPE
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          grid-template-columns: 1fr;
          gap: 10px;
        }

        // TABLET1 - PORTRAIT
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          grid-template-columns: 0.7fr 0.4fr;
          gap: 10px;
        }
        // TABLET1 - LANDSCAPE
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          grid-template-columns: 0.7fr 0.4fr;
          gap: 10px;
        }

        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          grid-template-columns: 1fr 0.3fr;
          gap: 5px;
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          grid-template-columns: 1fr 0.3fr;
          gap: 5px;
        }

        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          grid-template-columns: 1fr 0.3fr;
          gap: 5px;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          grid-template-columns: 1fr 0.3fr;
          gap: 5px;
        }

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          grid-template-columns: 1fr 0.3fr;
          gap: 5px;
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          grid-template-columns: 1fr 0.3fr;
          gap: 5px;
          // background-color: pink;
        }
        // -------- **
        // -------- **
        // -------- **
        // -------- **

        .desc_contact-screen_left {
          // background-color: green;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
        .desc_contact-screen_right {
          // background-color: darkcyan;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-end;
        }
        //
        //

        // left 👾 IMG
        .desc_contact-screen_left {
          //   background-color: pink;
          width: 100%;
          .container-imgBox-contact {
            width: 87%;
            margin: 0 auto;
            overflow: hidden;
            border-top-right-radius: 500px;
            border-top-left-radius: 500px;

            border-top: 4.5px solid #000;
            border-bottom: 4.5px solid #000;

            border-left: 2px solid #000;
            border-right: 2px solid #000;
            // -----
            //
            img {
              background-color: #000;
              //   filter: blur(10px);
              // filter: grayscale(50%);
              width: 100%;
              height: 100%;
              object-fit: cover;
              aspect-ratio: 0.58/1;
              display: block;

              // -------- **
              // -------- **
              // -------- **
              // MOBILE - PORTRAIT
              @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                aspect-ratio: 0.58/1;
              }
              // MOBILE - LANDSCAPE
              @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                aspect-ratio: 0.58/1;
              }

              // TABLET1 - PORTRAIT
              @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                aspect-ratio: 0.68/1;
              }
              // TABLET1 - LANDSCAPE
              @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                aspect-ratio: 0.68/1;
              }

              // TABLET2 - PORTRAIT
              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                aspect-ratio: 0.78/1;
              }

              // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                aspect-ratio: 0.78/1;
              }

              // DESKTOP - PORTRAIT
              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                aspect-ratio: 0.88/1;
              }

              // DESKTOP - LANDSCAPE
              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                aspect-ratio: 0.88/1;
              }

              // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
              @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                aspect-ratio: 0.88/1;
              }
              // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
              @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                aspect-ratio: 0.78/1;
              }
            }
          }
        }
        // -------- **
        // -------- **
        // -------- **
        // -------- **
        //
        //
        //-----
        // RIGHT
        //------

        .desc_contact-screen_right {
          display: flex;
          justify-content: center;
          align-items: center;
          //
          //
          //
          // .container-text-email {
          // }
          // .container-text-call {
          // }

          .container-text-email,
          .container-text-call {
            padding-top: 100px;

            // -------- **
            // ** MOBILE - PORTRAIT
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              padding-top: 30px;
            }
            // MOBILE - LANDSCAPE
            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              padding-top: 30px;
            }
            // TABLET1 - PORTRAIT
            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              padding-top: 50px;
            }
            // TABLET1 - LANDSCAPE
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              padding-top: 50px;
            }
            // TABLET2 - PORTRAIT
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              padding-top: 50px;
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              padding-top: 50px;
            }

            // DESKTOP - PORTRAIT
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              padding-top: 100px;
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              padding-top: 100px;
            }

            // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              padding-top: 100px;
            }
            // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              padding-top: 100px;
            }
            //
            //
            //💚
            h1 {
              // background-color: pink;

              color: rgb(3, 3, 3);

              letter-spacing: -1px;
              font-size: calc(30px + 1vmin);
              font-family: "Fjord One", serif;
              text-align: center;
              // -------- **
              // -------- **
              // MOBILE - PORTRAIT
              @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                letter-spacing: -1px;
                font-size: calc(14px + 1vmin);
                line-height: calc(35px + 1vmin);
                align-self: center;
              }
              // MOBILE - LANDSCAPE
              @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                letter-spacing: -1px;
                font-size: calc(14px + 1vmin);
                line-height: calc(35px + 1vmin);
                align-self: center;
              }

              // TABLET1 - PORTRAIT
              @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                letter-spacing: -1px;
                font-size: calc(14px + 1vmin);
                line-height: calc(35px + 1vmin);
                align-self: center;
              }
              // TABLET1 - LANDSCAPE
              @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                //   background-color: orangered;

                letter-spacing: -1px;
                font-size: calc(14px + 1vmin);
                line-height: calc(35px + 1vmin);
                align-self: center;
              }

              // TABLET2 - PORTRAIT
              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                letter-spacing: -1px;
                font-size: calc(14px + 1vmin);
                line-height: calc(35px + 1vmin);
                align-self: center;
              }

              // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                letter-spacing: -1px;
                font-size: calc(15px + 1vmin);
                line-height: calc(35px + 1vmin);
                align-self: center;
              }

              // DESKTOP - PORTRAIT
              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                letter-spacing: -1px;
                font-size: calc(15px + 1vmin);
                line-height: calc(35px + 1vmin);
                align-self: center;
              }

              // DESKTOP - LANDSCAPE
              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                letter-spacing: -1px;
                font-size: calc(15px + 1vmin);
                line-height: calc(35px + 1vmin);
                align-self: center;
              }

              // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
              @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                letter-spacing: -1px;
                font-size: calc(15px + 1vmin);
                line-height: calc(35px + 1vmin);
                align-self: center;
              }
              // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
              @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                letter-spacing: -1px;
                font-size: calc(15px + 1vmin);
                line-height: calc(35px + 1vmin);
                align-self: center;
              }
            }

            //
            //
            //
            //
            //💚
            h3 {
              //melissa
              color: rgba(3, 3, 3, 0.633);
              letter-spacing: 1px;
              // font-weight: 700;

              // margin-bottom: 40px;
              // font-size: calc(10px + 1vmin);

              opacity: 0.6;

              align-self: center;
              font-size: calc(10.5px + 1vmin);
              line-height: calc(10px + 1vmin);
              letter-spacing: -1px;
              word-spacing: 3px;
              font-weight: 500;

              //
              // h3 media queries
              // -------- **
              // -------- **
              // ** MOBILE - PORTRAIT
              @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                align-self: center;
                letter-spacing: -0.2px;
                font-size: calc(9px + 1vmin);
                word-spacing: 3px;
                align-self: center;
              }
              // MOBILE - LANDSCAPE
              @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                font-size: calc(9px + 1vmin);

                letter-spacing: -0.2px;
                word-spacing: 3px;
                align-self: center;
              }
              // TABLET1 - PORTRAIT
              @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                font-size: calc(9px + 1vmin);
                letter-spacing: -1px;
                word-spacing: 3px;
                opacity: 1;
                align-self: center;
              }
              // TABLET1 - LANDSCAPE
              @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                font-size: calc(9px + 1vmin);
                letter-spacing: 0.1px;
                word-spacing: 3px;
                opacity: 1;
                align-self: center;
              }
              // TABLET2 - PORTRAIT
              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                font-size: calc(9px + 1vmin);
                letter-spacing: -1px;
                word-spacing: 3px;
                opacity: 1;
                align-self: center;
              }

              // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                font-size: calc(9px + 1vmin);
                letter-spacing: -1px;
                word-spacing: 3px;
                opacity: 1;
                font-weight: 500;
                align-self: center;
              }

              // DESKTOP - PORTRAIT
              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                font-size: calc(7.5px + 1vmin);
                letter-spacing: -1px;
                word-spacing: 3px;
                font-weight: 500;
                align-self: center;
              }

              // DESKTOP - LANDSCAPE
              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                font-size: calc(7.5px + 1vmin);
                letter-spacing: -1px;
                word-spacing: 3px;
                font-weight: 500;
                align-self: center;
              }

              // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
              @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                font-size: calc(8.5px + 1vmin);
                letter-spacing: -1px;
                word-spacing: 3px;
                font-weight: 500;
                align-self: center;
              }
              // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
              @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                align-self: center;
                font-size: calc(8.5px + 1vmin);

                letter-spacing: -1px;
                word-spacing: 3px;
                font-weight: 500;
              }
              //
              //
              //

              span {
                font-family: Inter, system-ui, Avenir, Helvetica, Arial,
                  sans-serif;
                // color: var(--font-color-h3);
                color: var(--font-color-h3);
                font-weight: 700;
                margin-bottom: 20px;
                letter-spacing: -1px;
                font-size: calc(8px + 1vmin);

                // ** MOBILE - PORTRAIT
                @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                  font-size: calc(8px + 1vmin);
                  font-weight: 400;
                  margin-left: 3px;
                }
                // MOBILE - LANDSCAPE
                @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                  font-size: calc(8px + 1vmin);
                  font-weight: 400;
                  margin-left: 3px;
                }
                // TABLET1 - PORTRAIT
                @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                  font-size: calc(10px + 1vmin);
                  font-weight: 300;
                  margin-left: 5px;
                }
                // TABLET1 - LANDSCAPE
                @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                  font-size: calc(10px + 1vmin);
                  font-weight: 300;
                  margin-left: 5px;
                }
                // TABLET2 - PORTRAIT
                @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                  font-size: calc(8px + 1vmin);
                  font-weight: 400;
                  margin-left: 5px;
                }

                // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                  font-size: calc(8px + 1vmin);
                  font-weight: 400;
                  margin-left: 5px;
                }

                // DESKTOP - PORTRAIT
                @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                  font-size: calc(8px + 1vmin);
                  font-weight: 400;
                  margin-left: 5px;
                }

                // DESKTOP - LANDSCAPE
                @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                  font-size: calc(8px + 1vmin);
                  font-weight: 400;
                  margin-left: 5px;
                }

                // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
                @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                  font-size: calc(8px + 1vmin);
                  font-weight: 400;
                  margin-left: 5px;
                }
                // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
                @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                  font-size: calc(9px + 1vmin);
                  font-weight: 400;
                  margin-left: 5px;
                }
                //
                //
                //
              }
            }
          }
        }
      }
    }
  }
}
