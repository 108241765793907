.wrapper-HeadlineLg {
  width: 100%;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    padding: 150px 0 50px 0;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    padding: 180px 0 50px 0;
  }
  // --
  //
  .container-HeadlineLg {
    width: 100%;
    // border-top: 2px solid rgb(14, 14, 14);
    // border-bottom: 3px solid rgb(14, 14, 14);
    //
    h1,
    h2 {
      text-align: center;
      // font-family: "Wagon-Bold";
      font-family: "Wagon-ExtraLight";
      font-family: "Casta-Thin";
      font-family: "Kenoky-Light";

      font-size: calc(300px + 1.1vmin);
      color: #0c0c0c;

      // -------- **
      // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        font-size: calc(60px + 1.1vmin);
        line-height: calc(60px + 1.1vmin);
      }
      // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        font-size: calc(60px + 1.1vmin);
        line-height: calc(60px + 1.1vmin);
      }
      // ------
      // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        font-size: calc(90px + 1.1vmin);
        line-height: calc(90px + 1.1vmin);
      }
      //
      // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        font-size: calc(90px + 1.1vmin);
        line-height: calc(90px + 1.1vmin);
      }

      //-------------**
      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        font-size: calc(90px + 1.1vmin);
        line-height: calc(90px + 1.1vmin);
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        font-size: calc(90px + 1.1vmin);
        line-height: calc(90px + 1.1vmin);
      }
      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        font-size: calc(90px + 1.1vmin);
        line-height: calc(90px + 1.1vmin);
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        font-size: calc(150px + 1.1vmin);
        line-height: calc(130px + 1.1vmin);
      }

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        font-size: calc(180px + 1.1vmin);
        line-height: calc(180px + 1.1vmin);
      }
      // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        font-size: calc(200px + 1.1vmin);
        line-height: calc(200px + 1.1vmin);
      }
    }
  }
}

//
//
// --------------
//  OPT  2 for screens
// --------------
.wrapper-HeadlineLg_textScreens {
  width: 100%;
  padding: 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    padding: 150px 0 a50px 0;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    padding: 180px 0 50px 0;
  }
  // --
  //
  .container-HeadlineLg_textScreens {
    width: 100%;
    // border-top: 2px solid rgb(14, 14, 14);
    // border-bottom: 3px solid rgb(14, 14, 14);
    //
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    //-----------
    //------- H1
    //-----------
    .HeadlineLg_textScreensH1 {
      text-align: center;
      // font-family: "Wagon-Bold";font-family: "Kenoky-Light";  font-family: "Wagon-ExtraLight";
      font-family: "Casta-Thin";

      font-size: calc(20px + 1.1vmin);
      color: #757575;
    }
  }
  //
  //
  //
  //-----------
  //------- H2
  //-----------
  //
  //
  //
  .HeadlineLg_textScreensH2 {
    text-align: center;
    // font-family: "Wagon-Bold";
    font-family: "Wagon-ExtraLight";
    font-family: "Casta-Thin";
    font-family: "Kenoky-Light";

    font-size: calc(300px + 1.1vmin);
    color: #0c0c0c;

    // -------- **
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      font-size: calc(60px + 1.1vmin);
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      font-size: calc(60px + 1.1vmin);
    }
    // ------
    // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      font-size: calc(90px + 1.1vmin);
    }
    //
    // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      font-size: calc(90px + 1.1vmin);
    }

    //-------------**
    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      font-size: calc(90px + 1.1vmin);
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      font-size: calc(90px + 1.1vmin);
    }
    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      font-size: calc(90px + 1.1vmin);
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      font-size: calc(150px + 1.1vmin);
    }

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      font-size: calc(180px + 1.1vmin);
    }
    // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      font-size: calc(200px + 1.1vmin);
    }
  }
  //
}
