@import "_fonts";
@import "remote-fonts.scss";
@import "./mixins.scss";
// @import "../pages/home/Home";
@import "../pages/home/Styles-Home.scss";

//
//
//
//---------

:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  // line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(35, 35, 35, 0.87);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  outline: none;
  //   // CURSOR DEFAULT NONE
  //   cursor: auto;

  // -khtml-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // -o-user-select: none;
  // user-select: none;
  // // -----------
  // @include user-select-none;
  // // -----------------------
  // @include user-drag-none;
  //to remove the blue highlight of the buttons
  -webkit-tap-highlight-color: transparent;
  //
}
/* ---------BAR STYLES------- */

// THE ERROR IN THIS FILE COMES FROM HERE********
body::-webkit-scrollbar {
  width: 10px;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(248, 248, 248);
}
//   background-color: rgb(248, 248, 248);
body::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0);
  outline: none;
  border-radius: 50px;
}

/* ---------BAR STYLES------- */
body,
html {
  height: 100%;
}
body {
  min-height: auto;
  overflow-x: hidden;

  margin: 0;
  padding: 0;
}
//-----------------------
//--------- PAGE --------
.page {
  width: 100vw;
  height: 100%;
  display: flex;
  flex-wrap: wrap;

  // background-color: #fefefe;
  // background-color: #fdfbf3;
  // background-color: rgb(51, 63, 30);
  // //
  background-color: #f9eddc;
  //  -----------------
  //-------
  //-----------------
}
