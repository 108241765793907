//
@import "../../scss//font-sizes-drawingScreen";
//
//
//
.wrapper__drawingScreen_sticky_and_description {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  border-top: 1px solid #000;
  border-left: 0px solid #000;
  border-right: 0px solid #000;

  //  ---------

  .container___drawingScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;

    width: 95%;
    height: 100%;

    border-bottom: 3px solid #000;

    @include mediaQueries___container__drawingScreen;

    .wrapper_text-drawingScreen,
    .full-img {
      flex: 1;
      border-top: 0px solid #000;

      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        border-top: 0px solid #000;
      }

      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        border-top: 0px solid #000;
      }

      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        border-top: 0px solid #000;
      }

      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        border-top: 0px solid #000;
      }

      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        border-top: 0px solid #000;
      }

      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        border-top: 0px solid #000;
      }

      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        border-top: 3px solid #000;
      }

      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        border-top: 3px solid #000;
      }

      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        border-top: 3px solid #000;
      }

      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        border-top: 3px solid #000;
      }
    }

    .full-img {
      margin: 0 auto;
      height: auto;
      padding: 0px 10px 10px 10px;
      width: 100%;
      overflow: hidden;
      border-right: 3px solid #000;
      @include mediaQueries___fullImg__drawingScreen;

      .img-box-draw-screen {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 auto;

        .imgDrawing_Screen,
        .img1Drawing_Screen,
        .img2Drawing_Screen,
        .img3Drawing_Screen {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          margin: 0 auto;

          @include imgs___DrawingScreen;

          overflow: hidden;
          //
          img {
            border: 3px solid #000;

            border-top-left-radius: 520px;
            border-top-right-radius: 520px;
            border-bottom-left-radius: 520px;
            border-bottom-right-radius: 520px;

            margin: 2px 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            cursor: all-scroll;
          }
        }
      }
    }

    .wrapper_text-drawingScreen {
      align-self: flex-start;
      margin: 0 auto;
      width: 100%;

      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        width: 85%;
      }

      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        width: 80%;
      }

      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        width: 80%;
      }

      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        width: 80%;
      }

      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        width: 80%;
      }

      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        width: 80%;
      }

      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        width: 100%;
      }

      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        width: 100%;
      }

      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        width: 100%;
      }

      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        width: 100%;
      }
      // tags
      .text-drawingScreen-container {
        padding: 30px 20px 100px 20px;
        width: 100%;

        .card-container-screenDraw__tags {
          width: 100%;

          .card-tags-screen-draw {
            padding: 0 0 10px 0;
            width: 60%;

            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              width: 100%;
              padding: 20px 0;
            }

            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              width: 100%;
            }

            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              width: 100%;
            }

            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              width: 100%;
            }

            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              width: 100%;
            }

            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              width: 100%;
              padding: 20px 0;
            }
            //
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              width: 100%;
            }

            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              width: 100%;
            }

            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              width: 100%;
            }
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              width: 100%;
            }

            .column-wrap-screen-draw {
              width: 100%;

              display: grid;
              grid-template-columns: 0.3fr 0.3fr;

              grid-gap: 20px;

              @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                grid-template-columns: 0.3fr 0.3fr;

                grid-gap: 5px;
              }

              @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                grid-template-columns: 0.3fr 0.3fr;

                grid-gap: 5px;
              }

              @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                grid-template-columns: 0.3fr 0.3fr;
                grid-gap: 5px;
              }

              @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                grid-template-columns: 0.3fr 0.3fr;

                grid-gap: 5px;
              }

              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                grid-gap: 10px;
                grid-template-columns: 0.3fr 0.3fr;
              }

              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                grid-template-columns: 0.3fr 0.3fr;

                grid-gap: 10px;
              }
              // *
              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                grid-template-columns: 0.3fr 0.3fr;
                grid-gap: 15px;
              }

              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                grid-template-columns: 0.3fr 0.3fr;
                grid-gap: 15px;
              }

              @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                grid-template-columns: 0.3fr 0.3fr;
                grid-gap: 20px;
              }

              @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                grid-template-columns: 0.3fr 0.3fr;
                grid-gap: 20px;
              }

              .listGroupTags {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .tag-button {
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  text-transform: capitalize;
                  font-family: "NotoSans-Regular";
                  font-weight: 400;
                  letter-spacing: 1px;
                  font-size: calc(8px + 1.1vmin);
                  line-height: calc(2px + 1.1vmin);

                  border-radius: 0px;
                  border-top: 0;
                  border-left: 0;
                  border-right: 0;

                  color: #fdfbf32e;
                  color: #212121;
                  color: #ece6d190;
                  color: #111;

                  border: 2px solid #111;
                  padding: 12px 28px;
                  width: 180px;
                  border-radius: 60px;

                  transition: all 0.5s;
                  &:hover {
                    border-radius: 20px;
                    border-top: 2px solid #111;
                    border-left: 2px solid #111;
                    border-right: 2px solid #111;
                    color: #f2e6d2c5;
                    color: #a48665d6;
                    color: #111;
                  }

                  @include LI__listGroupTags__DrawingTitle_DrawingScreen;
                }
              }
            }
          }
        }

        @include mediaQueries__container_TEXT-drawingScreen;

        .card-container-screenDraw {
          margin-bottom: 8px;
          .card-deli-description-screen-draw {
            width: 100%;
            .list-title-btn-description-screen-draw {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 10px 0;

              .list-title-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 0 auto;
                width: 100%;
                // background-color: red;
                h1 {
                  margin: 10px 0;
                  width: 100%;
                  font-family: "Matao-Regular";
                  color: #212121;
                  font-weight: 500;
                  letter-spacing: 1px;
                  font-size: calc(15px + 1.1vmin);
                  line-height: calc(9px + 1.1vmin);

                  @include Headline_H1__DrawingTitle_DrawingScreen;
                }

                h2 {
                  position: -webkit-sticky;
                  position: sticky;
                  top: 40px;
                  padding: 10px 0 20px 0;
                  margin: 0px 0 0 0;
                  font-weight: 300;
                  color: #575757cd;

                  span {
                    font-weight: 600;
                    margin: 0 5px;
                    font-weight: 500;
                    color: #2b2b2bf9;
                  }

                  @include Headline_H2__DrawingTitle_DrawingScreen;
                }

                h3 {
                  color: #f3eee4cd;
                  font-weight: 400;
                  font-size: calc(9px + 1.1vmin);
                  @include Headline_H3__DrawingTitle_DrawingScreen;
                }
              }

              .btn-box {
                color: #fdfbf3;
              }
            }

            .list-group-item-delivery {
              padding: 50px 0 50px 0;
              p {
                color: #989185;
                color: #575757;
                font-family: "NotoSans-Regular";
                font-weight: 300;
                font-size: calc(10px + 1.1vmin);
                line-height: calc(18px + 1.1vmin);

                @include Headline_P__DrawingTitle_DrawingScreen;
              }
            }
          }

          .card-dimensions-screen-draw {
            margin: 0 auto;
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              padding: 20px 15px 10px 15px;
            }

            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              padding: 20px 15px 10px 15px;
            }

            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              padding: 20px 0px 10px 0px;
            }
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              padding: 20px 0px 10px 0px;
            }

            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              padding: 20px 0px 10px 0px;
            }

            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              padding: 20px 0px 10px 0px;
            }

            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              padding: 10px 20px 10px 0px;
            }
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              padding: 10px 20px 10px 0px;
            }
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              padding: 10px 20px 10px 0px;
            }
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              padding: 10px 20px 10px 0px;
            }

            .listGroup-title-dimensions {
              h3 {
                color: #fdfbf3;
                color: #212121;
                font-weight: 500;
                font-size: calc(9px + 1vmin);
                line-height: calc(19px + 1vmin);
              }
            }

            .column-wrap-screen-draw {
              padding: 10px 0;
              .listGroupDimensions {
                p {
                  margin: 0 2px;
                  color: #989185;
                  color: #575757;
                  font-weight: 400;
                  font-size: calc(9px + 1.1vmin);
                  line-height: calc(18px + 1.1vmin);
                }
              }
            }
          }
        }
      }
    }
  }
}
