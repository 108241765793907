.container-svg-start-arch {
  height: 30vh;
  padding: 70px 0 0 0;
  border-radius: 45px;
  //
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 45px;
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    margin-top: 20px;
    padding: 20px 0 0 0;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    margin-top: 20px;
    padding: 20px 0 0 0;
  }
  // ------
  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    margin-top: 20px;
    padding: 20px 0 0 0;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    margin-top: 20px;
    padding: 10px 0 0 0;
  }
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    margin-top: 20px;
    padding: 20px 0 0 0;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    margin-top: 20px;
    padding: 20px 0 0 0;
  }
  //
  //
  .svg-box-arch {
    width: 90%;
    height: 90%;

    // -------- **
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      width: 55%;
      height: 55%;
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      width: 55%;
      height: 55%;
    }
    // ------
    // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      width: 55%;
      height: 55%;
    }
    //
    // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      width: 65%;
      height: 65%;
    }

    //-------------**
    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      width: 65%;
      height: 65%;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      width: 65%;
      height: 65%;
    }
    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      width: 65%;
      height: 65%;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      width: 65%;
      height: 65%;
    }

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      width: 65%;
      height: 65%;
    }
    // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      width: 65%;
      height: 65%;
    }
    //
  }
}
