.wrapperImgLargeComp {
  // background-color: pink;
  width: 100%;
  height: auto;
  min-height: 200vh;
  //
  //
  display: flex;
  justify-content: center;
  flex-direction: column;

  //
  // --------
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: sienna;

    height: auto;
    padding: 20px 0;
    min-height: 10vh;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: lightblue;
    height: auto;
    padding: 20px 0;
    min-height: 10vh;
  }

  // --------
  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    height: auto;
    padding: 20px 0;
    min-height: 30vh;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: blue;
    height: auto;
    padding: 20px 0;
    min-height: 30vh;
  }

  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: teal;
    height: auto;
    padding: 20px 0;
    min-height: 40vh;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    //  background-color: powderblue;
    // background-color: tan;
    height: auto;
    padding: 20px 0;
    min-height: 40vh;
  }
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: powderblue;
    padding: 20px 0;
    min-height: 30vh;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olive;

    padding: 20px 0;
    min-height: 30vh;
  }
  //-------------**
  //-------------**
  //-------------
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: fuchsia;
    padding: 20px 0;
    min-height: 30vh;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: yellow;

    padding: 20px 0;
    min-height: 30vh;
  }
  //-------------**
  //-------------**
  /*





*/
  //--------
  // **  white section
  //--------
  .containerImgLargeComp {
    margin: 0 auto;
    width: 80%;
    .img-box-largeComp {
      // will-change: "width, transform";
      // transition: 1s all ease-in-out;
      width: 100%;
      // height: 100%;
      overflow: hidden;
      border: 3px solid #282828;

      border-top-right-radius: 900px;
      border-top-left-radius: 900px;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      //
      //
      img {
        //

        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        aspect-ratio: 0.79/1;
        // --------
      }
    }
  }
}
