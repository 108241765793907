#tag-page {
  //   background-color: rgb(186, 47, 70);
  padding-top: 200px;
  /* 3 images at the bottom of the home page */

  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //
  //
  //

  // -------------
  //  **  TAG btn  🍥
  // -------------
  //
  .tag-title-wrapper {
    padding: 30px 0;
    min-height: 30vh;

    margin: 0 auto;
    width: 60%;

    //
    // -------- **
    // -------- **
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      // background-color: pink;
      width: 100%;
      padding: 50px 0 100px 0;
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      // background-color: pink;
      width: 100%;
      padding: 50px 0 100px 0;
    }
    // -------- **
    // -------- **
    // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      width: 100%;
      padding: 50px 0 100px 0;
    }
    //
    // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      width: 100%;
      padding: 50px 0 100px 0;
    }
    //-------------**
    //-------------**
    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      width: 80%;
      padding: 50px 0 100px 0;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      width: 80%;
      padding: 50px 0 100px 0;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT  ( row , desktop > )
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    }
    //-------------**
    //-------------**
    // -------- **
    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    }
    // -------- **
    // -------- **
    //
    //
    // background-color: pink;
    .tag-title-container {
      // background-color: olive;
      width: 55%;
      padding: 20px 0;
      gap: 9px;
      margin: 0 auto;
      //

      display: flex;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
      flex-flow: row wrap;
      flex-basis: auto;
      // https://developer.mozilla.org/en-US/docs/Web/CSS/flex-basis
      //
      // -------- **
      // -------- **
      // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      }
      // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        // background-color: pink;
      }
      // -------- **
      // -------- **
      // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        width: 70%;
      }
      //
      // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        width: 70%;
      }
      //-------------**
      //-------------**
      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        width: 70%;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        width: 70%;
      }

      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT  ( row , desktop > )
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      }
      //-------------**
      //-------------**
      // -------- **
      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        width: 40%;
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        width: 40%;
      }
      // -------- **
      //
      //
      //
      // ** container BTN
      .tag-title-container-btns {
        transition: 1s all ease-in-out;
        font-weight: 500;

        //   cursor: pointer;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          align-self: center;
          border-radius: 50px;

          // ----- dont delete the width
          padding: 16px 25px; /* Fixed width for the button */
          // ----- dont delete the width
          //
          font-weight: 600;
          border: 2px solid #161616bd;
          background-color: transparent;
          font-size: calc(9px + 1.1vmin);
          //
          color: #161616bd;

          // Hover
          &:hover {
            color: #fefcf6;
            background-color: #161616;
          }
        }
      }
    }
  }
  //
  //

  // -------------
  //  ** IMG'S 🍥
  // -------------
  //

  .container-images-random {
    // background-color: green;
    // --------
    overflow: hidden;
    margin: 0 auto; // to center the whole container
    width: 100%;
    height: 100%;

    padding: 5px 0;
    //

    justify-content: end;
    align-items: end;
    //
    //---- ** GRID
    display: grid;
    grid-template-columns: 0.3fr 0.5fr 0.5fr 0.4fr; //0.6fr 1fr 0.4fr Here, you specify the rows with different widths, another option: 1fr 1fr 1fr 1fr;, will create 4 columns of the same size, but its not cool for mobile
    gap: 50px;
    //gap: 100px; // Adjust the gap between the boxes

    //
    // -------- **
    // -------- **
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      grid-template-columns: 1fr;
      gap: 5px;
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      grid-template-columns: 1fr;
      gap: 5px;
    }
    // -------- **
    // -------- **
    // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      width: 90%;
      grid-template-columns: 1fr 0.8fr;
      gap: 10px;
    }
    //
    // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      width: 90%;
      grid-template-columns: 1fr 0.8fr;
      gap: 10px;
    }
    //-------------**
    //-------------**
    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      width: 90%;
      grid-template-columns: 0.3fr 0.5fr 0.4fr;
      gap: 20px;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      width: 90%;
      grid-template-columns: 0.3fr 0.5fr 0.4fr;
      gap: 20px;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT  ( row , desktop > )
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      width: 80%;
      grid-template-columns: 0.3fr 0.5fr 0.5fr 0.4fr;
      gap: 30px;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      width: 80%;
      grid-template-columns: 0.3fr 0.5fr 0.5fr 0.4fr;
      gap: 30px;
    }
    //-------------**
    //-------------**
    // -------- **
    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      grid-template-columns: 0.3fr 0.5fr 0.5fr 0.4fr; //0.
      gap: 50px;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      grid-template-columns: 0.3fr 0.5fr 0.5fr 0.4fr; //0.
      gap: 50px;
    }
    // -------- **
    // -------- **
    //
    //

    .media-wrapper {
      // background-color: rgba(80, 175, 45, 0.695);
      width: 100%;
      //
      // ** UL
      .wrapper-img-random-card {
        //   background-color: rgb(202, 120, 32);
        width: 100%;

        //  ** LI
        .container-img-random {
          overflow: hidden;
          width: 100%;
          border-radius: 15px;
          transition: 0.5s all ease-in-out;
          &:hover {
            border-radius: 45px;
          }

          img {
            // this aspect ration is connected to the jsx and the property on the array  data_allDataMeyoko.js
            //

            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;

            // -------- **
            // -------- **
            // -------- **
            // -------- **
            // -------- **
            // -------- **
            // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            }
            // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            }
            // -------- **
            // -------- **
            // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              aspect-ratio: 0.88/1;
              border-radius: 0px;
            }
            //
            // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              aspect-ratio: 0.88/1;
              border-radius: 0px;
            }
            //-------------**
            //-------------**
            // TABLET2 - PORTRAIT
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              aspect-ratio: 0.88/1;

              border-radius: 0px;
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              background-color: rgb(211, 208, 182);

              aspect-ratio: 0.88/1;

              border-radius: 0px;
            }

            //-------------**
            //-------------**
            // DESKTOP - PORTRAIT  ( row , desktop > )
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              border-radius: 0px;
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              border-radius: 0px;
            }
            //-------------**
            //-------------**
            // -------- **
            // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              // aspect-ratio: var(--aspect-ratio);
            }
            // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              // this one below, is coming from the json and then on the JSX, if you uncover it, the image will have a fixed aspect ration, and will be smaller than what the grid values are
              // aspect-ratio: var(--aspect-ratio);
            }
            // -------- **
            // -------- **
            // -------- **
            // -------- **
            // ----- no because if you change the first block, the reast will follow same for the second and 3 option
            //   &.first-image img {
            //     aspect-ratio: 16/9;
            //   }

            //   &.second-image img {
            //     aspect-ratio: 4/3;
            //   }

            //   &.third-image img {
            //     aspect-ratio: 3/2;
            //   }

            //   &.fourth-image img {
            //     aspect-ratio: 1/1;
            //   }

            // ----------
            //   &:nth-child(1) {
            //     aspect-ratio: 4/9; // Example aspect ratio for first image
            //   }

            //   &:nth-child(2) {
            //     aspect-ratio: 4/3; // Example aspect ratio for second image
            //   }

            //   &:nth-child(3) {
            //     aspect-ratio: 3/2; // Example aspect ratio for third image
            //   }

            //   &:nth-child(4) {
            //     aspect-ratio: 1/1; // Example aspect ratio for fourth image
            //   }
          }
        }
      }
    }

    //
    //
  }
}
