//
@import "../../scss/font-sizes-btnDot";
//
//
//
.wrapper-textArrowBtn {
  // background-color: sandybrown;
  padding: 40px 0 40px 0;
  // margin: 40px 0 0 0;
  // overflow: hidden;
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  // --------
  //  MOBILE
  // --------
  @media only screen and (max-width: 680px) and (min-width: 170px) {
    // gap: 1px;
    // background-color: yellow;
    width: 100%;
  }

  .container-textArrowBtn {
    // background-color: pink;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 15px;

    // --------
    //  MOBILE
    // --------
    // @media only screen and (max-width: 680px) and (min-width: 170px) {
    //   // gap: 1px;
    //   // background-color: rgb(25, 102, 114);
    //   gap: 7px;
    // }
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      gap: 2px;
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      gap: 2px;
    }

    // --------
    // -------- TEXT

    h1 {
      &.textArrowBtn_font-white-h1,
      &.textArrowBtn_font-black-h1 {
        // ** selected works
        text-align: start;

        word-break: break-all;
        word-wrap: break-word;

        //
        font-size: calc(12px + 1.1vmin);
        letter-spacing: 0px;
        font-weight: 400;

        // **  -------- * --------
        // **  -------- * --------
        //  MEDIA queries (portrait and landscape)
        // Here you will find all, check the file on the scss folder
        @include Headline_H1__BTN-Dot;
        // **  -------- * --------
        // **  -------- * --------
        //
        // --------
      }
      text-transform: uppercase;
      &.textArrowBtn_font-white-h1 {
        color: rgba(156, 156, 156, 0.556);
      }
      &.textArrowBtn_font-black-h1 {
        color: rgba(35, 35, 35, 0.408);
      }
    }

    //
    // ------------
    // **  Discover the collection (text)
    //-------------
    h2 {
      //

      //
      &.textArrowBtn_font-white-h2,
      &.textArrowBtn_font-black-h2 {
        cursor: pointer;
        // the flex start will position the link at the top or in the beginning of the box

        font-size: calc(14px + 1.1vmin);
        line-height: calc(14px + 1.1vmin);

        // color: #171717;
        text-transform: capitalize;
        font-weight: 500;
        // ------------ underline
        position: relative;
        // --------
        // **  -------- * --------
        // **  -------- * --------
        //  MEDIA queries (portrait and landscape)
        // Here you will find all, check the file on the scss folder
        @include Headline_H2__BTN-Dot;
        // **  -------- * --------
        // **  -------- * --------
        //
        a {
          //
          //
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(1);
            transform-origin: bottom left;
            height: 0.05em;
            bottom: 1em;
            left: 0;

            transition: transform 0.35s ease-out;
            padding-bottom: 4px;
            margin-bottom: -3px;

            // **  -------- * --------
            // **  -------- * --------
            //  MEDIA queries (portrait and landscape)
            // Here you will find all, check the file on the scss folder
            @include Headline_UnderlineAnim__BTN-Dot;
            // **  -------- * --------
            // **  -------- * --------
          }

          //
          // ** hover LINE
          //
          &:hover::after {
            transform: scaleX(0);
            transform-origin: bottom right;
          }
        }
      }
      // UNDERLINE (all but the color font or BG)

      //
      // UNDERLINE WHITE
      &.textArrowBtn_font-white-h2 {
        // ---- Font color ---------
        // depends on the section-2 or section-3
        // -------------------------
        // section-3 /when BG is black:
        // font color will be white
        a {
          color: rgb(254, 252, 246);
          &::after {
            background-color: rgb(254, 252, 246);
          }
        }
      }
      // UNDERLINE BLACK
      &.textArrowBtn_font-black-h2 {
        // section-2 /when BG is white:
        // font color will be black
        a {
          color: rgb(13, 13, 13);
          &::after {
            background-color: rgb(13, 13, 13);
          }
        }
      }
      // Don't add the mixing , as i am using a conditional on the homeScreen to show a color font depending if i am in a black section or a white section, so the underline should also be within the conditional
      // ** @include underline-simpleEffectAnim;
      // ------------ underline  ----------
    }

    //
    // -------------
    //  CIRCLE
    // --------------

    #circle-dot {
      margin: 0 5px;
      // background-color: #171717;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      display: flex;

      // MOBILE - PORTRAIT
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        // background-color: greenyellow;
        margin: 0 5px;
        width: 15px;
        height: 15px;
      }
      // MOBILE - LANDSCAPE (658 x 606 or 663 x 662 ) on google responsive
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        // background-color: seagreen;
        margin: 0 5px;
        width: 15px;
        height: 15px;
      }

      // -------- **
      // -------- **

      // TABLET1 - PORTRAIT
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        // background-color: sienna;
        margin: 0 3px;
        width: 23px;
        height: 23px;
        margin: 0 2px;
      }
      // TABLET1 - LANDSCAPE
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        // background-color: goldenrod;
        margin: 0 3px;
        width: 20px;
        height: 20px;
        margin: 0 2px;
      }
      //-------------**
      //-------------**
      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        // background-color: orange;
        width: 20px;
        height: 20px;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        // background-color: red;
        margin: 0 3px;
        width: 23px;
        height: 23px;
      }

      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        // background-color: darkblue;
        margin: 0 3px;
        width: 20px;
        height: 20px;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        // background-color: cadetblue;
        margin: 0 3px;
        width: 20px;
        height: 20px;
      }

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        // background-color: pink;
        margin: 0 3px;
        width: 28px;
        height: 28px;
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        // background-color: fuchsia;
        margin: 0 3px;
        width: 28px;
        height: 28px;
      }

      //-------------**
      //-------------**
      // DOT COLOR  🟩
      //-------------**
      //-------------**
      &.dot-white {
        background-color: rgba(254, 252, 246, 0.971);
      }
      &.dot-black {
        background-color: rgb(13, 13, 13);
      }
    }
  }
  //
}
