.wrapper-error-component {
  padding-top: 20px;

  border-left: 3px;
  border-right: 2px;
  border-top: 5px;
  border-bottom: 10px;
  border-color: #000;
  border-style: solid;
  //
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
  //
  //
  //

  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    padding-top: 100px;
  }

  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    padding-top: 110px;
  }

  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
  }

  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
  }

  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    padding-top: 70px;
  }

  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    padding-top: 70px;
  }

  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    padding-top: 70px;
  }

  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    padding-top: 70px;
  }

  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    padding-top: 70px;
  }

  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    padding-top: 100px;
  }
  //
  //
  //
  //
  .container-error-component {
    padding: 50px 0;
    width: 100%;
    height: 100%;

    // ** GRID  --------
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    //
    //

    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      grid-template-columns: 1fr;
      gap: 10px;
      width: 97%;
    }

    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      grid-template-columns: 1fr;
      gap: 10px;
      width: 97%;
    }

    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      grid-template-columns: 1fr;
      gap: 10px;
      width: 100%;
    }

    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      grid-template-columns: 1fr;
      gap: 10px;

      width: 92%;
    }

    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      grid-template-columns: 1fr;
      gap: 10px;
      width: 82%;
    }

    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      width: 92%;
    }

    // desk
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      width: 95%;
    }

    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      width: 90%;
    }

    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      width: 95%;
    }

    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      grid-template-columns: 0.8fr 0.9fr;
      gap: 10px;
      width: 90%;
    }

    //
    //
    //
    // -------- **
    // text
    .container-error-component-text {
      padding-top: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      width: 90%;
      //

      h1 {
        align-self: flex-start;
        font-family: "Kenoky-Light";

        font-size: calc(140px + 1.1vmin);
        line-height: calc(133px + 1.1vmin);
        color: rgb(0, 0, 0);

        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          font-size: calc(70px + 1.1vmin);
        }

        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          font-size: calc(100px + 1.1vmin);
        }

        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          font-size: calc(100px + 1.1vmin);
        }

        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          font-size: calc(90px + 1.1vmin);
        }
        //tabl 2 port  -----
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          font-size: calc(110px + 1.1vmin);
        }

        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          font-size: calc(105px + 1.1vmin);
        }

        // desk port
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          font-size: calc(100px + 1.1vmin);
          line-height: calc(90px + 1.1vmin);
        }

        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          font-size: calc(100px + 1.1vmin);
          line-height: calc(90px + 1.1vmin);
        }

        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          font-size: calc(100px + 1.1vmin);
          line-height: calc(90px + 1.1vmin);
        }

        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          font-size: calc(100px + 1.1vmin);
          line-height: calc(90px + 1.1vmin);
        }
      }

      //
      //
      //
      h2 {
        align-self: flex-start;
        font-weight: 500;
        font-size: calc(35px + 1.1vmin);
        line-height: calc(38px + 1.1vmin);
        margin: 50px 0 60px 0;
        color: rgb(16, 16, 16);

        .wavy-error {
          // wavy (option)
          //   font-style: oblique;
          //   text-decoration-line: underline;
          //   text-decoration-style: wavy;
          //   text-underline-offset: 12px;
          font-style: oblique;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-underline-offset: 12px;
        }

        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          font-size: calc(18px + 1.1vmin);
          line-height: calc(26px + 1.1vmin);
        }

        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          font-size: calc(20px + 1.1vmin);
          line-height: calc(26px + 1.1vmin);
        }
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          font-size: calc(25px + 1.1vmin);
          line-height: calc(43px + 1.1vmin);
        }

        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          font-size: calc(25px + 1.1vmin);
          line-height: calc(43px + 1.1vmin);
        }
        // tabl 2 port

        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          font-size: calc(30px + 1.1vmin);
          line-height: calc(40px + 1.1vmin);
        }

        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          font-size: calc(40px + 1.1vmin);
          line-height: calc(43px + 1.1vmin);
        }

        // desk port
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          font-size: calc(40px + 1.1vmin);
          line-height: calc(43px + 1.1vmin);
        }

        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          font-size: calc(28px + 1.1vmin);
          line-height: calc(35px + 1.1vmin);
        }

        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          font-size: calc(40px + 1.1vmin);
          line-height: calc(90px + 1.1vmin);
        }

        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          font-size: calc(32px + 1.1vmin);
          line-height: calc(37px + 1.1vmin);
        }
      }

      //
      //
      //
      //

      p {
        align-self: flex-start;
        font-size: calc(15px + 1.1vmin);
        line-height: calc(23px + 1.1vmin);
        color: rgb(36, 36, 36);

        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          font-size: calc(15px + 1.1vmin);
          line-height: calc(23px + 1.1vmin);
        }

        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          font-size: calc(15px + 1.1vmin);
          line-height: calc(23px + 1.1vmin);
        }
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          font-size: calc(20px + 1.1vmin);
          line-height: calc(28px + 1.1vmin);
        }

        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          font-size: calc(20px + 1.1vmin);
          line-height: calc(28px + 1.1vmin);
        }
        // tabl 2 port
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          font-size: calc(20px + 1.1vmin);
          line-height: calc(28px + 1.1vmin);
        }

        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          font-size: calc(11px + 1.1vmin);
          line-height: calc(23px + 1.1vmin);
        }

        // desk port
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          font-size: calc(100px + 1.1vmin);
          line-height: calc(90px + 1.1vmin);
        }

        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          font-size: calc(14px + 1.1vmin);
          line-height: calc(25px + 1.1vmin);
        }
        // desk large
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          font-size: calc(100px + 1.1vmin);
          line-height: calc(90px + 1.1vmin);
        }

        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          font-size: calc(20px + 1.1vmin);
          line-height: calc(30px + 1.1vmin);
        }
      }

      //
      //
      //
      .wrapper-btnRound {
        align-self: flex-start;
        margin: 80px 0 0px 0;
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          margin: 20px 0 0px 0;
        }

        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          margin: 20px 0 0px 0;
        }

        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          margin: 30px 0 0px 0;
        }

        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          margin: 60px 0 0px 0;
        }
        // tablet 2 port
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          margin: 70px 0 0px 0;
        }

        //tablet 2 lands
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          margin: 60px 0 0px 0;
        }

        //desk port
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          margin: 60px 0 0px 0;
        }

        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          margin: 70px 0 0px 0;
        }

        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          margin: 60px 0 0px 0;
        }

        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          margin: 70px 0 0px 0;
        }
        //
        //
        //
        // button
        .container-btnRound {
          border-radius: 50px;
          cursor: pointer;
          width: 300px;
          padding: 24px 26px;
          border: 2px solid black;
          background-color: black;
          color: rgb(243, 233, 197);
          font-size: calc(10px + 1.1vmin);
          &:hover {
            background-color: transparent;
            border: 2px solid black;
            color: black;
          }
          //
          //

          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            width: 210px;
            padding: 18px 15px;
          }

          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            width: 210px;
            padding: 18px 15px;
          }

          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            width: 280px;
            padding: 20px 15px;
            font-size: calc(12px + 1.1vmin);
          }

          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            width: 280px;
            padding: 20px 15px;
            font-size: calc(12px + 1.1vmin);
          }
          // tablet 2 port
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            width: 260px;
            padding: 18px 15px;
          }

          //tablet 2 lands
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            width: 260px;
            padding: 18px 15px;
          }

          //desk port
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            width: 260px;
            padding: 18px 15px;
          }

          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            width: 260px;
            padding: 18px 15px;
          }

          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            width: 260px;
            padding: 18px 15px;
          }

          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            width: 260px;
            padding: 18px 15px;
          }
        }
        //
        //  ------ btn
      }
    }
    //
    // ---------
    //
    // img
    .container-error-component-img {
      overflow: hidden;
      padding: 20px;

      background-color: rgb(240, 228, 217);
      border-top-left-radius: 1800px;
      border-top-right-radius: 1800px;

      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        margin: 100px 0px;
      }

      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        margin: 100px 0px;
      }

      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        margin: 100px 0px;
      }

      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        margin: 100px 0px;
      }
      // tablet 2 port
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        margin: 100px 0px;
      }

      //tablet 2 lands
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        margin: 100px 0px;
      }

      //desk port
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        margin: 0px 0;
      }

      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        margin: 0px 0;
      }

      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        margin: 0px 0;
      }

      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        margin: 0px 0;
      }

      .container-img-error {
        background-color: black;
        border-width: 3px;
        border-color: #000;
        border-style: solid;
        border-top-left-radius: 1800px;
        border-top-right-radius: 1800px;
        padding: 10px;

        overflow: hidden;
        width: 100%;
        height: auto;
        //
        //

        //
        .imgBox-error {
          //
          width: 100%;
          overflow: hidden;
          position: relative;

          &::before {
            content: "";
            width: 100%;
            height: 100%;

            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;

            z-index: 100;
            pointer-events: none;
            // --- the inner border
            border-top-left-radius: 1800px;
            border-top-right-radius: 1800px;
            border: 20px solid transparent;
            box-shadow: 0 0 0 0.1em transparent, inset 0 0 0 0.1em #eeeeee29;
            // -----
          }

          img {
            border-width: 3px;
            border-color: #000;
            border-style: solid;
            border-top-left-radius: 1800px;
            border-top-right-radius: 1800px;

            //
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            aspect-ratio: 0.97/1;
            //
          }
        }
      }
    }
    // ---- img
  }
}
