// -------- **
// -------- **
.container-svg-Press {
  width: 100%;
  padding: 30px 30px 0 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: pink;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 45px;

  .svg-box-press {
    width: 90%;
    height: 90%;
  }
}

// -------- **
// -------- **
// -------- **
