#pattern-Screen {
  padding-top: 300px;
  width: 100%;
  height: auto;

  border-top: 3px solid #000;
  border-left: 5px solid #000;
  border-right: 15px solid #000;

  //  background-color: #fdfbf3;
  // -------- **
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    padding-top: 200px;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    padding-top: 200px;
  }

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    padding-top: 200px;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    padding-top: 200px;
  }

  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    padding-top: 250px;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    padding-top: 250px;
  }

  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    padding-top: 300px;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    padding-top: 300px;
  }

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    padding-top: 300px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    padding-top: 300px;
  }
  //
  //
  //
  // ---------------------------
  //   🔶    Argus collection
  // ----------------------------

  //   container GRID
  .wrapper___pattern-Screen {
    // background-color: #bdc1c486;
    //
    overflow: hidden;
    padding: 50px 0 0 0;
    margin: 0 auto;
    width: 100%;
    height: auto;
    // background-color: black;
    // -------- **
    // -------- **
    // MOBILE - PORTRAIT
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      width: 90%;
    }
    // MOBILE - LANDSCAPE
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      width: 90%;
    }
    //-------------**
    //-------------**
    // TABLET1 - PORTRAIT
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      width: 80%;
    }
    // TABLET1 - LANDSCAPE
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      width: 80%;
    }

    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      width: 90%;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      width: 90%;
    }

    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      width: 97%;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      width: 92%;
    }

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      width: 95%;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      width: 95%;
    }

    //
    // --------- GRID TOP 🟧
    //
    .container-pattern1 {
      overflow: hidden;
      padding: 150px 0 0 0;
      width: 100%;
      height: auto;

      //
      //
      // -------- **
      // MOBILE - PORTRAIT
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        padding-top: 30px;
      }
      // MOBILE - LANDSCAPE
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        padding-top: 30px;
      }

      // TABLET1 - PORTRAIT
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        padding-top: 50px;
      }
      // TABLET1 - LANDSCAPE
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        padding-top: 50px;
      }

      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        padding-top: 70px;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        padding-top: 70px;
      }

      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        padding-top: 150px;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        padding-top: 150px;
      }

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        padding-top: 150px;
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        padding-top: 150px;
      }
      //
      //
      // --------
      .containerGrid-pattern1 {
        // grid
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0;

        padding: 0px 0;

        // MOBILE - PORTRAIT
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          grid-template-columns: 1fr;
        }
        // MOBILE - LANDSCAPE
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          grid-template-columns: 1fr;
        }
        //-------------**
        //-------------**
        // TABLET1 - PORTRAIT
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          grid-template-columns: 1fr;
        }
        // TABLET1 - LANDSCAPE
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          grid-template-columns: 1fr;
        }

        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          grid-template-columns: 1fr;
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          grid-template-columns: 1fr;
        }

        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          grid-template-columns: 1fr;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          grid-template-columns: 1fr;
        }

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          grid-template-columns: 1fr;
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          // background-color: red;
          grid-template-columns: 1fr;
        }
        //-------------**
        //
        //
        .container-card-pattern1 {
          // background-color: rgb(185, 163, 108);
          width: 100%;
          // -------------
          //  IMG
          // -------------
          //
          .img-container-pattern1 {
            // background-color: khaki;
            width: 100%;
            height: auto;
            padding: 20px 0;
            .container-imgBox-pattern1 {
              // padding: 100px; (goes together with the, box shadow here below)
              width: 100%;
              overflow: hidden;
              margin: 0 auto;
              .img-boxPattern-pattern1 {
                // background-color: magenta;
                border: 3px solid #282828;

                border-bottom: solid #000000d6;
                border-top-width: 12px;
                border-top-left-radius: 500px;
                border-top-right-radius: 500px;
                border-bottom-left-radius: 500px;
                border-bottom-right-radius: 500px;
                border-bottom-width: 7px;
                //

                //
                width: 50%;
                overflow: hidden;
                margin: 0 auto;
                //
                //
                // -----------------
                // MOBILE - PORTRAIT
                @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                  width: 80%;
                }
                // MOBILE - LANDSCAPE
                @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                  width: 80%;
                }
                //-------------**
                //-------------**
                // TABLET1 - PORTRAIT
                @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                  width: 70%;
                }
                // TABLET1 - LANDSCAPE
                @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                  width: 70%;
                }

                // TABLET2 - PORTRAIT
                @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                  width: 60%;
                }

                // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                  width: 60%;
                }

                //-------------**
                //-------------**
                // DESKTOP - PORTRAIT
                @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                  width: 40%;
                }

                // DESKTOP - LANDSCAPE
                @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                  width: 40%;
                }

                // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
                @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                  width: 50%;
                }
                // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
                @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                  // background-color: red;
                  width: 50%;
                }

                //
                //
                //
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  cursor: pointer;
                  aspect-ratio: 0.58/1;
                  display: block;
                  //
                }
              }
            }
          }
          //
          //
          //------ end img ARCH

          .wrapper__Description_patternScreen {
            padding: 50px 0 20px 0; //-------------**
            // background-color: green;
            min-height: auto;
            height: auto;
            .wrapper-text__patternScreen {
              //
              // background-color: red;
              // ** TEXT and button
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              //
              .container-text__patternScreen {
                display: flex;
                flex-direction: column;
                margin: 0 auto;
                width: 100%;

                //
                // ------ H1
                //
                h1 {
                  text-align: center;
                  align-self: center;
                  // ** Drawing title
                  //
                  margin: 10px 0;

                  width: 100%;
                  font-family: "Matao-Regular";
                  // font-family: "Kenoky-Light";
                  // font-family: "NeutralFace-Bold";
                  color: #0e0e0efc;
                  font-weight: 500;
                  letter-spacing: 1px;
                  font-size: calc(45px + 1.1vmin);
                  line-height: calc(45px + 1.1vmin);
                  // -------- **
                  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
                  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                    // background-color: rgb(193, 220, 217);
                    font-weight: 600;
                    letter-spacing: -1px;
                    font-size: calc(40px + 1.1vmin);
                    line-height: calc(34px + 1.1vmin);

                    text-align: center;
                    align-self: center;
                  }
                  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
                  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                    // background-color: cadetblue;
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: -0.2px;
                    font-size: calc(55px + 1.1vmin);
                    line-height: calc(60px + 1.1vmin);
                  }

                  // -------- **
                  // -------- **

                  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
                  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                    // background-color: orangered;
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: -0.2px;
                    font-size: calc(60px + 1.1vmin);
                    line-height: calc(60px + 1.1vmin);
                  }
                  //
                  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
                  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                    // background-color: rgb(143, 225, 225);
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    font-size: calc(60px + 1.1vmin);
                    line-height: calc(65px + 1.1vmin);
                  }
                  //-------------**
                  //-------------**
                  // TABLET2 - PORTRAIT 994 x 1367
                  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                    // background-color: burlywood;
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: -0.2px;
                    font-size: calc(60px + 1.1vmin);
                    line-height: calc(60px + 1.1vmin);
                  }

                  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                    // background-color: sienna;
                    font-weight: 600;
                    letter-spacing: -0.2px;
                    font-size: calc(60px + 1.1vmin);
                    line-height: calc(60px + 1.1vmin);
                  }

                  //-------------**
                  //-------------**
                  // DESKTOP - PORTRAIT
                  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                    // background-color: olive;
                    font-weight: 600;
                    text-align: center;
                    align-self: center;
                    letter-spacing: -0.2px;
                    font-size: calc(70px + 1.1vmin);
                    line-height: calc(95px + 1.1vmin);
                  }

                  // DESKTOP - LANDSCAPE
                  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                    // background-color: rgb(136, 146, 116);
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: -0.2px;
                    font-size: calc(60px + 1.1vmin);
                    line-height: calc(70px + 1.1vmin);
                  }
                  //-------------**
                  //-------------**

                  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
                  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                    // background-color: pink;
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: -0.2px;
                    font-size: calc(70px + 1.1vmin);
                    font-size: calc(90px + 1.1vmin);
                    line-height: calc(100px + 1.1vmin);
                  }
                  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
                  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                    // background-color: plum;
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: 1px;
                    font-size: calc(90px + 1.1vmin);
                    line-height: calc(100px + 1.1vmin);
                  }
                }

                //-------------**
                //-------------**
                //-------------**
                //-------------**
                //  --------- H2 🟨
                //

                //
                h2 {
                  margin: 0px 0 0 0;
                  // width: 140px;
                  // height: 40px;
                  font-weight: 300;
                  font-size: calc(14px + 1.1vmin);
                  line-height: calc(34px + 1.1vmin);
                  color: #0e0e0e90;
                  //
                  span {
                    //The Furies
                    font-weight: 600;
                    margin: 0 5px;
                    font-weight: 500;

                    color: #08080890;
                  }

                  //---------- **🟧
                  //
                  // MOBILE - PORTRAIT
                  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                    // background-color: blue;
                    font-size: calc(10px + 1.1vmin);
                    line-height: calc(34px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }
                  // MOBILE - LANDSCAPE
                  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                    font-size: calc(10px + 1.1vmin);
                    line-height: calc(34px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }
                  //-------------**
                  //-------------**
                  // TABLET1 - PORTRAIT
                  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                    font-size: calc(11px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }
                  // TABLET1 - LANDSCAPE
                  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                    font-size: calc(11px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }
                  // TABLET2 - PORTRAIT
                  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                    font-size: calc(11px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }

                  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                    font-size: calc(11px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }

                  //-------------**
                  //-------------**
                  // DESKTOP - PORTRAIT
                  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                    font-size: calc(12px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }

                  // DESKTOP - LANDSCAPE
                  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                    font-size: calc(12px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }

                  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
                  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                    font-size: calc(11px + 1.1vmin);
                    line-height: calc(34px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }
                  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
                  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                    font-size: calc(11px + 1.1vmin);
                    line-height: calc(34px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }
                }

                //-------------**
                //-------------**
                //-------------**
                //-------------**
                // ------ H3 🟧
                //
                h3 {
                  // ** price or year

                  color: #0e0e0e90;
                  font-weight: 400;
                  font-size: calc(9px + 1.1vmin);

                  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
                  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                    text-align: center;
                    align-self: center;
                    letter-spacing: -0.2px;
                    font-size: calc(8px + 1.1vmin);
                  }
                  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
                  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                    text-align: center;
                    align-self: center;
                    letter-spacing: -0.2px;
                    font-size: calc(8px + 1.1vmin);
                  }

                  // -------- **
                  // -------- **

                  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
                  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                    // background-color: orangered;
                    text-align: center;
                    align-self: center;
                    letter-spacing: -0.2px;
                    font-size: calc(7px + 1.1vmin);
                  }
                  //
                  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
                  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                    // background-color: rgb(143, 225, 225);
                    text-align: center;
                    align-self: center;
                    letter-spacing: -0.2px;
                    font-size: calc(8px + 1.1vmin);
                  }
                  //-------------**
                  //-------------**
                  // TABLET2 - PORTRAIT 994 x 1367
                  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                    // background-color: burlywood;
                    text-align: center;
                    align-self: center;
                    letter-spacing: -0.2px;
                    font-size: calc(8px + 1.1vmin);
                  }

                  // ❤️‍🔥 TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                    // background-color: sienna;
                    text-align: center;
                    align-self: center;
                    letter-spacing: -0.2px;
                    font-size: calc(7px + 1.1vmin);
                  }

                  //-------------**
                  //-------------**
                  // DESKTOP - PORTRAIT
                  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                    // background-color: olive;
                    padding-top: 5px;
                    align-self: center;
                    letter-spacing: 1px;
                    font-size: calc(7px + 1.1vmin);
                  }

                  // DESKTOP - LANDSCAPE
                  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                    // background-color: olivedrab;
                    align-self: center;
                    padding-top: 5px;
                    letter-spacing: 1px;
                    font-size: calc(7px + 1.1vmin);
                  }
                  //-------------**
                  //-------------**

                  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
                  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                    // background-color: pink;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: 1px;
                    font-size: calc(8px + 1.1vmin);
                  }
                  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
                  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                    // background-color: plum;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: 1px;
                    font-size: calc(8px + 1.1vmin);
                  }
                }
                //
                //-------------**
                //-------------**
                //-------------**
                //-------------**
                // ------ 👾 ( P ) 👾
                //-------------**
                //-------------**
                //-------------**
                p {
                  margin: 0 auto;
                  width: 70%;
                  max-width: 70%;
                  text-align: center;
                  font-style: italic;
                  //

                  // color: #3b3b3b90;
                  color: #191919d6;

                  font-weight: 400;
                  font-size: calc(35px + 1vmin);
                  line-height: calc(65px + 1vmin);
                  //
                  font-style: normal;
                  //
                  // * SPAN
                  .underlineP-pattern {
                    font-weight: 500;
                    font-style: oblique;
                    font-family: "Fjord One", serif;
                    color: #191919d6;

                    font-size: calc(40px + 1vmin);
                    margin: 0 15px;
                    //
                    //

                    // **  -------- BORDER
                    // border under text (no animation
                    border-bottom: solid #191919d6;
                    border-bottom-width: 0.05em;
                    // wavy (option)
                    // text-decoration-line: underline;
                    // text-decoration-style: wavy;
                    // ----------
                  }

                  //
                  // ** -- MEDIA queries -----
                  // MOBILE - PORTRAIT
                  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                    // background-color: yellow;
                    width: 95%;
                    max-width: 95%;
                    padding: 70px 0 30px 0;
                    font-size: calc(16px + 1vmin);
                    line-height: calc(30px + 1vmin);

                    // span
                    .underlineP-pattern {
                      margin: 0 5px;
                      font-size: calc(18px + 1vmin);
                      // -------- BORDER
                      border-bottom-width: 0.08em;
                    }
                    //
                    //
                  }
                  // MOBILE - LANDSCAPE
                  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                    // background-color: blueviolet;
                    width: 90%;
                    max-width: 90%;
                    padding: 70px 0 30px 0;
                    font-size: calc(16px + 1vmin);
                    line-height: calc(30px + 1vmin);

                    // span
                    .underlineP-pattern {
                      margin: 0 5px;
                      font-size: calc(18px + 1vmin);
                      // -------- BORDER
                      border-bottom-width: 0.08em;
                    }
                    //
                  }

                  // TABLET1 - PORTRAIT
                  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                    // background-color: orange;
                    padding: 70px 0 30px 0;
                    width: 87%;
                    max-width: 87%;
                    font-size: calc(16px + 1vmin);
                    line-height: calc(30px + 1vmin);
                    // span
                    .underlineP-pattern {
                      margin: 0 5px;
                      font-size: calc(18px + 1vmin);
                      // -------- BORDER
                      border-bottom-width: 0.08em;
                    }
                    //
                    //
                  }
                  // TABLET1 - LANDSCAPE
                  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                    // background-color: orangered;
                    padding: 70px 0 30px 0;
                    width: 87%;
                    max-width: 87%;
                    font-size: calc(16px + 1vmin);
                    line-height: calc(30px + 1vmin);
                    // span
                    .underlineP-pattern {
                      margin: 0 5px;
                      font-size: calc(18px + 1vmin);
                      // -------- BORDER
                      border-bottom-width: 0.08em;
                    }
                    //
                    //
                  }
                  //-------------**
                  //-------------**
                  // TABLET2 - PORTRAIT
                  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                    // background-color: sienna;
                    padding: 90px 0 30px 0;

                    width: 87%;
                    max-width: 87%;
                    font-size: calc(16px + 1vmin);
                    line-height: calc(30px + 1vmin);
                    // span
                    .underlineP-pattern {
                      margin: 0 8px;
                      font-size: calc(18px + 1vmin);
                      // -------- BORDER
                      border-bottom-width: 0.08em;
                    }
                  }

                  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                    // background-color: sandybrown;
                    padding: 90px 0 30px 0;
                    width: 87%;
                    max-width: 87%;
                    font-size: calc(24px + 1vmin);
                    line-height: calc(48px + 1vmin);
                    // span
                    .underlineP-pattern {
                      margin: 0 8px;
                      font-size: calc(26px + 1vmin);
                      // -------- BORDER
                      border-bottom-width: 0.08em;
                    }
                  }

                  //-------------**
                  //-------------**
                  // DESKTOP - PORTRAIT
                  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                    // background-color: seagreen;
                    padding: 120px 0 100px 0;
                    width: 84%;
                    max-width: 84%;
                    font-size: calc(34px + 1vmin);
                    line-height: calc(55px + 1vmin);
                    // span
                    .underlineP-pattern {
                      margin: 0 10px;
                      font-size: calc(36px + 1vmin);
                      // -------- BORDER
                      border-bottom-width: 0.08em;
                    }
                  }

                  // DESKTOP - LANDSCAPE
                  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                    // background-color: olive;
                    //
                    padding: 120px 0 100px 0;
                    width: 84%;
                    max-width: 84%;
                    font-size: calc(34px + 1vmin);
                    line-height: calc(55px + 1vmin);
                    // span
                    .underlineP-pattern {
                      margin: 0 10px;
                      font-size: calc(36px + 1vmin);
                      // -------- BORDER
                      border-bottom-width: 0.08em;
                    }
                  }
                  //-------------**
                  //-------------**

                  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
                  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                    // background: pink;
                    padding: 120px 0 70px 0;

                    width: 75%;
                    max-width: 75%;
                    font-size: calc(34px + 1vmin);
                    line-height: calc(55px + 1vmin);
                    // span
                    .underlineP-pattern {
                      margin: 0 10px;
                      font-size: calc(36px + 1vmin);
                      // -------- BORDER
                      border-bottom-width: 0.08em;
                    }
                  }
                  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
                  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                    // background-color: plum;
                    padding: 120px 0 70px 0;
                    width: 75%;
                    max-width: 75%;
                    font-size: calc(34px + 1vmin);
                    line-height: calc(55px + 1vmin);
                    // span
                    .underlineP-pattern {
                      margin: 0 10px;
                      font-size: calc(36px + 1vmin);
                      // -------- BORDER
                      border-bottom-width: 0.08em;
                    }
                  }
                }
                //
              }
            }
          }
          //
          //
          //
          //

          // --------------
          // 🔶  BOTTOM component
          // --------------
          .wrapper__galleryPatternScreen {
            //   background-color: #fdfbf3;
            //   background-color: lavenderblush;
            //

            margin: 0 auto;
            padding-top: 80px;
            width: 100%;
            height: auto;

            // -------- **
            // -------- **
            // MOBILE - PORTRAIT
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              width: 90%;
            }
            // MOBILE - LANDSCAPE
            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              width: 90%;
            }
            //-------------**
            //-------------**
            // TABLET1 - PORTRAIT
            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              width: 80%;
            }
            // TABLET1 - LANDSCAPE
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              width: 80%;
            }

            // TABLET2 - PORTRAIT
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              width: 90%;
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              width: 90%;
            }

            //-------------**
            //-------------**
            // DESKTOP - PORTRAIT
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              width: 100%;
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              width: 100%;
            }

            // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              width: 100%;
            }
            // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              width: 100%;
            }

            //
            // ---- 🔶 GRID imgs BOTTOM
            //
            //
            .container__galleryPatternScreen {
              // background-color: #0040c9;

              width: 100%;
              height: 100%;
              .container-imagesPatternScreen-ul {
                // grid
                display: grid;
                width: 100%;
                height: 100%;
                grid-template-columns: 1fr 1fr 1fr;
                grid-gap: 0.3em;

                padding: 0px 0;

                // -------- **
                // -------- **
                // MOBILE - PORTRAIT
                @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                  grid-template-columns: 1fr;
                }
                // MOBILE - LANDSCAPE
                @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                  grid-template-columns: 1fr;
                }
                //-------------**
                //-------------**
                // TABLET1 - PORTRAIT
                @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                  grid-template-columns: 0.6fr 1fr;
                }
                // TABLET1 - LANDSCAPE
                @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                  grid-template-columns: 0.6fr 1fr;
                }

                // TABLET2 - PORTRAIT
                @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                  grid-template-columns: 0.6fr 1fr;
                }

                // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                  grid-template-columns: 0.6fr 1fr;
                }

                //-------------**
                //-------------**
                // DESKTOP - PORTRAIT
                @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                  grid-template-columns: 1fr 1fr;
                }

                // DESKTOP - LANDSCAPE
                @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                  grid-template-columns: 1fr 1fr;
                }

                // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
                @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                  grid-template-columns: 1fr 1fr;
                }
                // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
                @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                  grid-template-columns: 1fr 1fr;
                }
                //-------------**

                // -------------
                //  IMG
                // -------------
                //
                .img-containerPatternScreen {
                  width: 100%;
                  height: 100%;

                  .containerPatternScreen_img-box {
                    overflow: hidden;
                    margin: 0 auto;
                    border: 2px solid #000;

                    // border-top-right-radius: 70px;
                    // border-top-left-radius: 70px;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 0;
                    border-top: 0;

                    .img-boxPatternScreen {
                      width: 100%;

                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        cursor: pointer;
                        aspect-ratio: 0.95/1;
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }

          // ---- end GRID bottom
          //
        }
      }
      //
    }
  }
}
