// -------- **
// -------- **
// -------- **

.container-text-pattern-1-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  // background-color: rgb(0, 0, 0);
  //

  //
  //
  // ---------------------------
  // **  BOX 3x 🔷
  // ---------------------------
  //
  .headlineH1_3box_text-footer {
    //
    margin: 0 auto;
    display: grid;
    width: 70%;
    height: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.2px;

    //
    //
    //
    //
    // MOBILE - PORTRAIT
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      grid-template-columns: 0.3fr 0.5fr 0.3fr;
      width: 100%;

      border-bottom: 1px solid transparent;
    }
    // MOBILE - LANDSCAPE
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      grid-template-columns: 0.3fr 0.5fr 0.3fr;
      width: 100%;

      border-bottom: 1px solid transparent;
    }
    //-------------**
    //-------------**
    // TABLET1 - PORTRAIT
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      grid-template-columns: 0.3fr 0.5fr 0.3fr;
      width: 90%;
    }
    // TABLET1 - LANDSCAPE
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      grid-template-columns: 0.3fr 0.5fr 0.3fr;
      width: 90%;
    }

    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      grid-template-columns: 0.3fr 0.5fr 0.3fr;
      width: 80%;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      grid-template-columns: 0.3fr 0.5fr 0.3fr;
      width: 80%;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      grid-template-columns: 0.5fr 2fr 0.5fr;
      width: 70%;
      grid-gap: 0.2px;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      grid-gap: 0.2px;
      grid-template-columns: 0.5fr 2fr 0.5fr;
      width: 70%;
    }

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      grid-template-columns: 1fr 1fr 1fr;
      width: 70%;
    }

    //

    //
    //
    //
    // The box a and b will contain symbols, nice for the aesthetic on the desktop and maybe on the mobile
    //
    // ** ----- BLOCK A & B -------

    //
    .headlineBlock_a,
    .headlineBlock_b {
      width: 100%;
      height: 70px;
      // background-color: #cdd0d5;
      display: flex;
      justify-content: center;
      align-items: center;
      //
      //
      // -------- **
      border-radius: 40px;
      // -------- **
      #container-svg-footer-prints {
        background-color: #cdd0d5;
        // background-color: red;
        width: 100%;
        height: 100%;
        padding: 5px 0;
        // border-radius: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        // -------
        overflow: hidden;
        // -------
        .svg-foo {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          .svg-box-footer-prints {
            // background-color: yellow;
            background-color: transparent;
            // background-color: #cdd0d5;
            margin: 0 auto;
            //   border: 2px solid #040404;
            width: 80%;
            height: 100%;
            // background-color: yellow;
            // -------- **
            // -------- **
            // -------- **
            // -------- **
            // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              width: 40%;
            }
            // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              width: 40%;
            }

            // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              width: 40%;
            }
            //
            // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            }
            width: 40%;

            // TABLET2 - PORTRAIT 994 x 1367
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              width: 40%;
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              width: 40%;
            }

            //-------------**
            //-------------**
            // DESKTOP - PORTRAIT
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              max-width: 36%;
              width: 25%;
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              max-width: 36%;
              width: 25%;
            }

            // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              max-width: 36%;
              width: 25%;
            }
            // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              max-width: 36%;
              width: 25%;
            }
            //
            //
          }
        }
      }
    }
    //
    //
    //
    //
    //🔴
    .headlineBlock_a {
      overflow: hidden;
      // border-top-right-radius: 180px;
      // border-bottom-right-radius: 180px;

      border: 2px solid #040404;
      border-top-left-radius: 0px;
      // shadow to the RIGHT
      filter: drop-shadow(5px 15px 7px rgb(35, 35, 35));
    }
    .headlineBlock_b {
      overflow: hidden;
      // border-top-left-radius: 180px;
      // border-bottom-left-radius: 180px;
      border: 2px solid #040404;
      border-top-right-radius: 0px;

      // shadow to the LEFT
      filter: drop-shadow(-5px 15px 7px rgb(35, 35, 35));
    }
    //
    //
    //
    //------------------
    //  ** BTN middle
    // -----------------
    //
    //
    .h1-box-3box {
      background-color: #cdd0d5;
      border: 2px solid #040404;
      //------
      padding: 15px 0;
      //-------
      border-radius: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 898;
      // ------ H1
      //
      h1 {
        text-align: center;
        align-self: center;

        //
        // ** Drawing title
        //

        font-family: "Matao-Regular";
        font-family: "Kenoky-Light";

        color: #0e0e0efc;
        font-weight: 500;
        letter-spacing: 1px;
        font-size: calc(35px + 1.1vmin);

        // -------- **
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          font-size: calc(13px + 1.1vmin);
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          font-size: calc(16px + 1.1vmin);
        }

        // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          font-size: calc(16px + 1.1vmin);
        }
        //
        // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          // background-color: rgb(143, 225, 225);

          font-size: calc(18px + 1.1vmin);
        }
        //-------------**
        //-------------**
        // TABLET2 - PORTRAIT 994 x 1367
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          font-size: calc(18px + 1.1vmin);
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          font-size: calc(18px + 1.1vmin);
        }

        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          font-size: calc(18px + 1.1vmin);
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          font-size: calc(18px + 1.1vmin);
        }

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          font-size: calc(18px + 1.1vmin);
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          font-size: calc(18px + 1.1vmin);
        }
      }
    }
  }

  //-------------**
  //-------------**
}
