#about-page {
  padding-top: 100px;

  //
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // margin: 0 auto;
  // overflow: hidden;
  width: 100%;
  height: 100%; // // -- dont touch this
  // // 100% for w and h

  border-top: 3px solid #000;
  border-left: 7px solid #000;
  border-right: 7px solid #000;
  border-bottom: 15px solid #000;
}
