@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
//
//-------- btn 🌻
//
//
.container-btn-press {
  padding: 30px 0px;

  //
  //
  //
  // ** ---- media query -----
  //
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    padding: 70px 0px 30px 0;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    padding: 70px 0px 30px 0;
  }

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    padding: 70px 0px 30px 0;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    padding: 70px 0px 30px 0;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    padding: 70px 0px 30px 0;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    padding: 70px 0px 30px 0;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    padding: 70px 0px 30px 0;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    padding: 70px 0px 30px 0;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    padding: 100px 0px 30px 0;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    padding: 100px 0px 30px 0;
  }
  //-------------**

  //
  //
  //
  //
  .btn_ul-press {
    min-height: 20vh;
    height: 100%;
    // background-color: pink;

    // btn styles
    .btn-li-press {
      // btn link
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        //
        // NavLink
        border-radius: 30px;
        text-transform: capitalize;
        font-weight: 300;
        //
        font-family: "NotoSans-Regular";
        letter-spacing: -0.5px;
        font-size: calc(10px + 1vmin);
        line-height: calc(5px + 1vmin);
        // ** btn --
        width: 200px;
        // border: 0.5px solid #d2d2d21d;
        //
        //
        border: 1.5px solid var(--BG-color-btn);
        padding: 22px 38px;
        // ** btn --
        //
        // color: #f1e5cfd6;
        background-color: var(--BG-color-hover);
        color: var(--font-color-btn);

        //
        //
        &:hover {
          border: 1.5px solid var(--BG-color-btn);
          background-color: var(--BG-color-btn);
          color: var(--font-color-btn-hover);
          padding: 22px 38px;
        }
        //
        //
        // ** ---- media query -----
        //
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          width: 110px;
          padding: 18px 20px;

          &:hover {
            padding: 18px 20px;
          }
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          width: 140px;
          padding: 18px 20px;

          &:hover {
            padding: 18px 20px;
          }
        }

        // ------
        // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          width: 150px;
          padding: 18px 32px;
          &:hover {
            padding: 18px 32px;
          }
        }
        //
        // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          width: 150px;
          padding: 18px 32px;
          &:hover {
            padding: 18px 32px;
          }
        }
        //-------------**
        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          width: 180px;
          padding: 18px 32px;
          &:hover {
            padding: 18px 32px;
          }
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          width: 180px;
          padding: 18px 32px;
          &:hover {
            padding: 18px 32px;
          }
        }
        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          padding: 18px 32px;
          &:hover {
            padding: 18px 32px;
          }
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          padding: 18px 32px;
          &:hover {
            padding: 18px 32px;
          }
        }
        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          padding: 30px 60px;
          width: 280px;
          border-radius: 50px;
          &:hover {
            padding: 30px 60px;
            width: 280px;
            border-radius: 50px;
          }
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          padding: 30px 60px;
          width: 280px;
          border-radius: 50px;
          &:hover {
            padding: 30px 60px;
            width: 280px;
            border-radius: 50px;
          }
        }
      }
      //----- end of 'a' tag
      //
      position: relative;
      //
      //  💮 SVG
      //

      //
      // small circle with svg icon
      .sm-icon-btn-press {
        //
        //

        border-radius: 50%;
        border: 1.9px solid var(--font-color-btn);
        background-color: var(--BG-attached-icon);
        //
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease-in-out;

        position: absolute;
        width: 70px;
        height: 70px;
        bottom: -20px;
        right: -35px;

        // ** ---- media query -----
        //
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          width: 40px;
          height: 40px;
          bottom: -15px;
          right: -18px;
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
        @media only screen and (max-height: 680px) and (min-width: 140px) and (orientation: landscape) {
          width: 40px;
          height: 40px;
          bottom: -14px;
          right: -18px;
        }
        // TABLET1 - PORTRAIT
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          width: 40px;
          height: 40px;
          bottom: -14px;
          right: -18px;
        }
        // TABLET1 - LANDSCAPE
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          width: 40px;
          height: 40px;
          bottom: -14px;
          right: -18px;
        }

        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          width: 55px;
          height: 55px;
          bottom: -18px;
          right: -25px;
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          width: 55px;
          height: 55px;
          bottom: -18px;
          right: -25px;
        }

        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          width: 44px;
          height: 44px;
          bottom: -18px;
          right: -25px;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          width: 44px;
          height: 44px;
          bottom: -18px;
          right: -25px;
        }

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          width: 70px;
          height: 70px;
          bottom: -20px;
          right: -35px;
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          width: 70px;
          height: 70px;
          bottom: -20px;
          right: -35px;
        }
        // -------- **
        // -------- **
        //
        //
        // ** SVG
        .container-svg-sm-icon-btn-press {
          display: flex;
          justify-content: center;
          align-items: center;

          margin: 0 auto;
          overflow: hidden;
          border-radius: 45px;
          .svg-box-sm-press {
            width: 65%;
            height: 65%;
          }
        }
        // will make it move a bit when hovering
        &:hover {
          transform: translateX(5%);
        }
      }

      //
      //
    }
  }
}
//----- btn
