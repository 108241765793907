#all-patterns {
  padding-top: 300px;
  width: 100%;
  height: auto;

  border-top: 3px solid #000;
  border-left: 5px solid #000;
  border-right: 15px solid #000;

  //  background-color: #fdfbf3;
  // -------- **
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    padding-top: 200px;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    padding-top: 200px;
  }

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    padding-top: 200px;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    padding-top: 200px;
  }

  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    padding-top: 250px;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    padding-top: 250px;
  }

  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    padding-top: 300px;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    padding-top: 300px;
  }

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    padding-top: 300px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    padding-top: 300px;
  }
  //
  //
  //
  //
  //
  //
  // ---------------------------
  //   🔶    Argus collection
  // ----------------------------

  //   container GRID
  .wrapper-pattern1 {
    // background-color: #fdfbf3;
    // background-color: darkblue;
    //
    padding: 100px 0 200px 0;
    margin: 0 auto;
    width: 100%;
    height: auto;

    // -------- **
    // -------- **
    // MOBILE - PORTRAIT
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      width: 97%;
    }
    // MOBILE - LANDSCAPE
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      width: 97%;
    }
    //-------------**
    //-------------**
    // TABLET1 - PORTRAIT
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      width: 95%;
    }
    // TABLET1 - LANDSCAPE
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      width: 95%;
    }

    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      width: 90%;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      width: 90%;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      width: 97%;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      width: 92%;
    }

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      width: 95%;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      width: 95%;
    }

    //
    // --------- GRID TOP 🟧
    //
    .container-pattern1 {
      // background-color: olive;
      //   justify-content: end;
      //   align-items: end;
      // --------
      width: 100%;
      height: auto;
      .containerGrid-pattern1 {
        // background-color: pink;
        // grid
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0;

        padding: 0px 0;

        //🔴 This grid is connected to the grid at the bottom, so if you add 2 columns here, it will affect the grid at the bottom
        // -------- **
        // MOBILE - PORTRAIT
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          grid-template-columns: 1fr 1fr;
        }
        // MOBILE - LANDSCAPE
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          grid-template-columns: 0.5fr 0.5fr;
        }
        //-------------**
        //-------------**
        // TABLET1 - PORTRAIT
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          grid-template-columns: 1fr 1fr;
        }
        // TABLET1 - LANDSCAPE
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          grid-template-columns: 1fr 1fr;
        }

        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          grid-template-columns: 1fr 1fr;
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          grid-template-columns: 1fr 1fr;
        }

        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          grid-template-columns: 1fr 1fr;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          grid-template-columns: 1fr 1fr;
        }

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          // background-color: purple;
          grid-template-columns: 1fr 1fr;
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          // background-color: red;
          grid-template-columns: 1fr 1fr;
        }
        //-------------**
        //
        //
        .container-card-pattern1 {
          // background-color: rgb(185, 163, 108);
          width: 100%;

          // -------------
          //  IMG
          // -------------
          //
          .img-container-pattern1 {
            // background-color: khaki;
            width: 100%;
            height: auto;
            padding: 20px 0;
            overflow: hidden;
            .container-imgBox-pattern1 {
              // background-color: rgb(187, 171, 30);
              width: 100%;
              overflow: hidden;
              margin: 0 auto;
              .img-boxPattern-pattern1 {
                // background-color: yellow;
                border: 3px solid #000;
                width: 97%;
                overflow: hidden;
                margin: 0 auto;
                border-top-left-radius: 450px;
                border-top-right-radius: 450px;
                border-bottom-left-radius: 205px;
                border-bottom-right-radius: 205px;
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  cursor: pointer;
                  aspect-ratio: 0.68/1;
                  display: block;
                  border-radius: 30px;

                  // MOBILE - PORTRAIT
                  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                    aspect-ratio: 0.5/1;
                  }
                  // MOBILE - LANDSCAPE
                  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                    aspect-ratio: 0.5/1;
                  }
                  //-------------**
                  //-------------**
                  // TABLET1 - PORTRAIT
                  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                    aspect-ratio: 0.6/1;
                  }
                  // TABLET1 - LANDSCAPE
                  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                    aspect-ratio: 0.6/1;
                  }

                  // TABLET2 - PORTRAIT
                  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                  }

                  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                  }

                  //-------------**
                  //-------------**
                  // DESKTOP - PORTRAIT
                  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                  }

                  // DESKTOP - LANDSCAPE
                  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                  }

                  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
                  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                    // background-color: purple;
                  }
                  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
                  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                    // background-color: red;
                  }
                }
              }
            }
          }
          //
          //
          //------

          .description-pattern1 {
            margin: 0 0 0 2px;
            padding: 20px 0 20px 0; //-------------**

            min-height: auto;
            height: auto;
            .wrapper-text-pattern-1 {
              //
              // background-color: red;
              // ** TEXT and button
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              //
              .container-text-pattern-1 {
                display: flex;
                flex-direction: column;
                margin: 0 auto;
                width: 100%;

                //
                // ------ H1
                //
                h1 {
                  text-align: center;
                  align-self: center;
                  // ** Drawing title
                  //

                  width: 100%;
                  font-family: "Matao-Regular";
                  // font-family: "Kenoky-Light";
                  // font-family: "NeutralFace-Bold";
                  color: #0e0e0efc;
                  font-weight: 500;
                  letter-spacing: 1px;
                  font-size: calc(45px + 1.1vmin);
                  line-height: calc(50px + 1.1vmin);
                  // -------- **
                  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
                  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                    // background-color: rgb(193, 220, 217);
                    font-weight: 600;
                    letter-spacing: -0.01px;
                    font-size: calc(18px + 1.1vmin);
                    line-height: calc(20px + 1.1vmin);

                    text-align: center;
                    align-self: center;
                  }
                  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
                  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                    // background-color: cadetblue;
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: -0.2px;
                    font-size: calc(18px + 1.1vmin);
                    line-height: calc(20px + 1.1vmin);
                  }

                  // -------- **
                  // -------- **

                  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
                  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                    // background-color: orangered;
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: -0.2px;
                    font-size: calc(25px + 1.1vmin);
                    line-height: calc(28px + 1.1vmin);
                  }
                  //
                  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
                  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                    // background-color: rgb(143, 225, 225);
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    font-size: calc(25px + 1.1vmin);
                    line-height: calc(28px + 1.1vmin);
                  }
                  //-------------**
                  //-------------**
                  // TABLET2 - PORTRAIT 994 x 1367
                  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                    // background-color: burlywood;
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: -0.2px;
                    font-size: calc(25px + 1.1vmin);
                    line-height: calc(28px + 1.1vmin);
                  }

                  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                    // background-color: sienna;
                    font-weight: 600;
                    letter-spacing: -0.2px;
                    font-size: calc(25px + 1.1vmin);
                    line-height: calc(28px + 1.1vmin);
                  }

                  //-------------**
                  //-------------**
                  // DESKTOP - PORTRAIT
                  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                    // background-color: olive;
                    font-weight: 600;
                    text-align: center;
                    align-self: center;
                    letter-spacing: -0.2px;
                    font-size: calc(70px + 1.1vmin);
                    line-height: calc(95px + 1.1vmin);
                  }

                  // DESKTOP - LANDSCAPE
                  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                    // background-color: rgb(136, 146, 116);
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: -0.2px;
                    font-size: calc(60px + 1.1vmin);
                    line-height: calc(70px + 1.1vmin);
                  }
                  //-------------**
                  //-------------**

                  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
                  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                    // background-color: pink;
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: -0.2px;
                    font-size: calc(40px + 1.1vmin);
                    line-height: calc(45px + 1.1vmin);
                  }
                  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
                  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                    // background-color: plum;
                    text-align: center;
                    align-self: center;
                    font-weight: 600;
                    letter-spacing: 1px;
                    font-size: calc(40px + 1.1vmin);
                    line-height: calc(45px + 1.1vmin);
                  }
                }

                //-------------**
                //-------------**
                //-------------**
                //-------------**
                //  --------- H2 🟨
                //

                //
                h2 {
                  margin: 0px 0 0 0;
                  // width: 140px;
                  // height: 40px;
                  font-weight: 300;
                  color: #0e0e0e90;

                  font-size: calc(9px + 1.1vmin);
                  line-height: calc(32px + 1.1vmin);
                  span {
                    //The Furies
                    margin: 0 2px;
                    font-weight: 500;
                    color: #08080890;
                  }

                  //---------- **🟧
                  //
                  // MOBILE - PORTRAIT
                  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                    // background-color: blue;

                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    font-size: calc(7px + 1.1vmin);
                    line-height: calc(14px + 1.1vmin);
                    span {
                      //The Furies
                      margin: 0 2px;
                    }
                  }
                  // MOBILE - LANDSCAPE
                  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                    font-size: calc(7px + 1.1vmin);
                    line-height: calc(14px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }
                  //-------------**
                  //-------------**
                  // TABLET1 - PORTRAIT
                  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                    font-size: calc(9px + 1.1vmin);
                    line-height: calc(13px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }
                  // TABLET1 - LANDSCAPE
                  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                    font-size: calc(9px + 1.1vmin);
                    line-height: calc(13px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }
                  // TABLET2 - PORTRAIT
                  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                    font-size: calc(9px + 1.1vmin);
                    line-height: calc(13px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }

                  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                    font-size: calc(9px + 1.1vmin);
                    line-height: calc(13px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }

                  //-------------**
                  //-------------**
                  // DESKTOP - PORTRAIT
                  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                    font-size: calc(12px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }

                  // DESKTOP - LANDSCAPE
                  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                    font-size: calc(12px + 1.1vmin);
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    span {
                      //The Furies
                      margin: 0 5px;
                    }
                  }

                  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
                  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;
                    font-size: calc(9px + 1.1vmin);
                    line-height: calc(32px + 1.1vmin);
                    span {
                      //The Furies
                      margin: 0 10px;
                    }
                  }
                  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
                  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                    text-align: center;
                    align-self: center;
                    // COLLECTION
                    display: flex;
                    flex-direction: row;

                    font-size: calc(9px + 1.1vmin);
                    line-height: calc(32px + 1.1vmin);
                    span {
                      //The Furies
                      margin: 0 10px;
                    }
                  }
                }

                //-------------**
                //-------------**
                //-------------**
              }
            }
          }
        }
      }
    }
  }
}
