//
.wrapper_galleryAllDrawingsFolio {
  padding: 0 0 100px 0;

  //--------------
  // gallery bottom
  //---------------
  .gallery-container-lg {
    width: 100%;
    padding: 200px 100px 250px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: #d7e1ee;
    //
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      padding: 70px 45px 60px 45px;
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      padding: 70px 45px 60px 45px;
    }
    // ------
    // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      padding: 100px 45px 60px 45px;
    }
    //
    // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      padding: 100px 45px 60px 45px;
    }

    //-------------**
    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      padding: 150px 45px 60px 45px;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      padding: 150px 45px 60px 45px;
    }
    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      padding: 150px 100px 70px 100px;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      padding: 150px 100px 70px 100px;
    }

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      padding: 150px 45px 70px 45px;
    }
    // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      padding: 150px 45px 70px 45px;
    }
    //
    //
    .gallery-lg-card-container {
      // ----- this to flexes end here below
      // are to position the div boxes on the bottom of the div, instead of the top
      justify-content: end;
      align-items: end;
      // --------
      width: 100%;
      height: 100%;
      // **  GRID
      grid-template-columns: 1fr 1fr 1fr;
      display: grid;
      grid-gap: 40px;

      // -------- **
      // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        grid-template-columns: 1fr;
        grid-gap: 70px;
      }
      // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        grid-template-columns: 1fr;
        grid-gap: 70px;
      }
      // ------
      // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 100px;
      }
      //
      // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 100px;
      }

      //-------------**
      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 100px;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        grid-template-columns: 1fr 1fr;
        grid-gap: 100px;
      }
      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        grid-template-columns: 0.5fr 0.5fr 0.5fr;
        grid-gap: 60px;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        grid-template-columns: 0.5fr 0.5fr 0.5fr;
        grid-gap: 60px;
      }

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        grid-template-columns: 0.5fr 0.5fr 0.5fr;
        grid-gap: 60px;
      }
      // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        grid-template-columns: 0.5fr 0.5fr 0.5fr;
        grid-gap: 60px;
      }
      //
      //
      // -------  UL - card --------
      .card-gallery-lg {
        // 🟠
        filter: drop-shadow(5px 15px 7px rgb(24, 24, 24));

        // &:hover {
        //   filter: drop-shadow(5px 15px 7px rgb(114, 115, 119));
        // }

        width: 98%;
        //    background-color: red;
        overflow: hidden;
        height: auto;
        //   padding: 1% 0 0.1% 0;
        border: 2px solid rgb(0, 0, 0);

        border-top-right-radius: 700px;
        border-top-left-radius: 700px;

        // border-bottom-left-radius: 100px;
        // border-bottom-right-radius: 100px;

        // box-shadow: 0 0 0 10px #fff3e392, inset 0 10px 0 10px #ebd5b7;
        //
        // LI
        .img-container-gallery-lg {
          // padding: 20px 10px;
          width: 100%;
          padding: 30px;

          overflow: hidden;
          border-top-right-radius: 180px;
          border-top-left-radius: 180px;

          //
          //
          .img-box-gallery-lg {
            width: 100%;
            height: 100%;
            box-shadow: 0 0 0 10px #d7e1ee, inset 0 0 0 200px rgb(255, 235, 213);
            filter: drop-shadow(5px 15px 7px rgb(35, 35, 35));
            //
            //
            overflow: hidden;
            border: 2px solid #000;
            border-top-right-radius: 480px;
            border-top-left-radius: 480px;
            padding: 20px;

            img {
              cursor: pointer;
              border-top-right-radius: 700px;
              border-top-left-radius: 700px;
              border: 3px solid #000;
              box-shadow: 0 0 0 10px #cfd3d8, inset 0 0 0 -200px transparent;

              //
              //
              width: 100%;
              height: 100%;
              object-fit: cover;
              aspect-ratio: 0.8/1;
              //  aspect-ratio: 1/1; // square
              display: block;
              // aspect-ratio: 1.57/1; // kubrick 1 option
              // --------
              // MOBILE
              // --------
              @media only screen and (max-width: 680px) and (min-width: 170px) {
                aspect-ratio: 0.6/1;
              }
              // --------
              // TABLET1
              // --------
              @media only screen and (max-width: 992px) and (min-width: 681px) {
                aspect-ratio: 0.6/1;
              }
              // --------
              // --------
              // TABLET2 / LAPTOP (1024 x 1366 [ Apple iPad Pro)
              @media only screen and (max-width: 1366px) and (min-width: 993px) {
                aspect-ratio: 0.8/1;
              }
              //-------------
              // DESKTOP
              //  1440 x 900
              // http://responsivetesttool.com/
              //
              @media only screen and (max-width: 1666px) and (min-width: 1367px) {
                aspect-ratio: 0.6/1;
              }
              //-------------
              // DESKTOP large / 1920 x 1080 (mine)
              @media only screen and (max-width: 1966px) and (min-width: 1667px) {
                aspect-ratio: 0.6/1;
              }
            }
          }
        }
      }
    }
  }
}
