header {
  width: 100%;
  padding: 20px 10px 5px 40px;
  min-height: 3vh;
  //
  display: flex;
  justify-content: space-between;
  align-items: center;
  //
  z-index: 999;
  position: fixed;
  top: 0vh;
  left: 0;
  transform: translate(0%, -1%);
  //

  //-------------**
  //-------------**
  //
  // -----------
  //😆
  .header-child {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    // background-color: yellow;

    //
    // ----------
    //
    // ** ---- media query -----
    //
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    }
    // ------
    // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    }
    //
    // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    }
    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    }
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    }
    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    }
    //
    //
    //
    // ----------
    // LOGO stuff is on the jsx (handled with framer)
    .logo-meyoko-creat {
      margin-bottom: 20px;
      .logo1,
      .logo2 {
        display: block;
        letter-spacing: -1px;
      }
      h5 {
        cursor: pointer;
        letter-spacing: 1px;
      }
    }
    //------------
    //

    .hamburger {
      // background-color: violet;
      position: absolute;
      top: 0;
      right: 15px; /* Adjusted right position for spacing from the right edge */
      cursor: pointer;

      // -------- **
      // MOBILE - PORTRAIT
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        stroke-width: 1;
        right: 10px;
      }
      // MOBILE - LANDSCAPE (658 x 606 or 663 x 662 ) on google responsive
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        right: 10px;
      }
      // TABLET1 - PORTRAIT
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        right: 10px;
      }
      // TABLET1 - LANDSCAPE
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        right: 10px;
      }
      //-------------**

      // DESKTOP - PORTRAIT  ( row , desktop > )
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        right: 12px;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        right: 12px;
      }
      //-------------**
      //-------------**

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        right: 12px;
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        right: 12px;
      }

      //
      //
      //
      // ** SVG ----
      svg {
        width: 38px; /* Adjust the width as needed for responsiveness */
        height: auto; /* Maintain aspect ratio */
        fill: none;
        stroke: #141414; // Change color as needed
        stroke-width: 2; /* Adjusted stroke width for thinner lines */
        strokelinecap: round; /* Keep rounded line ends for better appearance */
        strokelinejoin: round; /* Keep rounded line joins for better appearance */

        // -------- **
        // MOBILE - PORTRAIT
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          stroke-width: 1;
          width: 30px;
        }
        // MOBILE - LANDSCAPE (658 x 606 or 663 x 662 ) on google responsive
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          width: 30px;
        }
        // TABLET1 - PORTRAIT
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          width: 34px;
        }
        // TABLET1 - LANDSCAPE
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          width: 34px;
        }
        //-------------**

        // DESKTOP - PORTRAIT  ( row , desktop > )
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          width: 34px;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          width: 34px;
        }
        //-------------**
        //-------------**

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          width: 38px;
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          width: 38px;
        }
      }
    }
  }

  // -------

  // // related to scroll
  &.active {
    visibility: visible;
    transition: all 0.5s;
  }
  &.hidden {
    visibility: hidden;
    transition: all 0.5s;
    transform: translateY(-100%);
  }
  // -------
}

//
//
// -------------
// **  MENU OPEN
// -------------
//
//
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  // ** blur effect
  // background: rgba(255, 255, 255, 0.25);
  // -webkit-backdrop-filter: blur(23px);
  // backdrop-filter: blur(23px);
  // border: 1px solid rgba(255, 255, 255, 0.125);
  // ------
  //
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  flex-direction: column;
  z-index: 998; /* Place the overlay beneath the hamburger */

  //
  //
  // ** ---- media query -----
  //
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
  }
  // ------
  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.menu {
  width: 100%;
  height: 100%;

  // background-color: khaki;

  padding: 0 50px;
  border-radius: 10px;
  text-align: left;
  z-index: 999;

  // ------ MENU container
  //
  .menuOpen-container {
    // background-color: pink;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;

    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      justify-content: center;
      align-items: flex-start;
    }

    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }

    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      justify-content: center;
      align-items: flex-start;
    }

    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      justify-content: center;
      align-items: flex-start;
    }
    //-------------**
    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      justify-content: center;
      align-items: flex-start;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT  ( row , desktop > )
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      flex-direction: row;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      flex-direction: row;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      flex-direction: row;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .navAnimated-ul,
    .navAnim-li {
      margin: 0;
      padding: 0;
    }
    .navAnimated-ul {
      padding: 25px;

      //🟠 half page
      width: 50%;
      z-index: 952;

      //
      //
      a {
        text-decoration: none;
        display: block; // 1) ❎ don't touch this, it has to stay at display:block, otherwise it will change the structure of the navlinks
      }
      .navAnim-li {
        margin: 0;
        list-style: none;
        margin-bottom: 20px;
        cursor: pointer;
        display: inline-block;
        font-family: "Kenoky-Light";
        font-size: calc(61px + 1.1vmin);
        line-height: calc(38px + 1.1vmin);
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;

        color: hsl(38, 100%, 98%);

        //
        //
        // ** ---- media query -----
        //
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          font-size: calc(30px + 1.1vmin);
          line-height: calc(15px + 1.1vmin);
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          font-size: calc(30px + 1.1vmin);
          line-height: calc(15px + 1.1vmin);
        }
        // ------
        // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          font-size: calc(62px + 1.1vmin);
          line-height: calc(50px + 1.1vmin);
        }
        //
        // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          font-size: calc(40px + 1.1vmin);
          line-height: calc(22px + 1.1vmin);
        }
        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          font-size: calc(51px + 1.1vmin);
          line-height: calc(40px + 1.1vmin);
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          font-size: calc(48px + 1.1vmin);
          line-height: calc(28px + 1.1vmin);
        }
        // --------- **
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          font-size: calc(70px + 1.1vmin);
          line-height: calc(45px + 1.1vmin);
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          font-size: calc(70px + 1.1vmin);
          line-height: calc(55px + 1.1vmin);
        }
        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          font-size: calc(70px + 1.1vmin);
          line-height: calc(55px + 1.1vmin);
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          font-size: calc(70px + 1.1vmin);
          line-height: calc(55px + 1.1vmin);
        }

        //
        //
      }
    }

    .text-placeholder {
      border-radius: 5px;
      width: 200px;
      height: 20px;
      flex: 1;
    }

    .wrapper_social-bottom {
      height: auto;
      display: flex;
      flex-direction: column;
      //🟠 half page
      width: 50%;

      //
      // ** ---- media query -----
      //
      // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        width: 100%;
      }

      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        width: 100%;
        height: 100%;
      }

      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        width: 100%;
      }

      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        width: 100%;
      }

      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        width: 100%;
      }

      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }

      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        padding: 0 10px;
        justify-content: flex-end;
        align-items: flex-end;
        width: 50%;
        height: 100%;
      }

      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        padding: 0 10px;
        justify-content: flex-end;
        align-items: flex-end;
        width: 50%;
        height: 100%;
      }

      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        padding: 0 10px;
        justify-content: flex-end;
        align-items: flex-end;
        width: 50%;
        height: 100%;
      }

      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        padding: 0 10px;
        justify-content: flex-end;
        align-items: flex-end;
        width: 50%;
        height: 100%;
      }

      .container_social-bottom {
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: 0.7fr 1fr 1fr;
        grid-gap: 0.2px;
        //

        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          grid-template-columns: 0.7fr 1fr 1fr;
          width: 100%;
        }

        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          grid-template-columns: 0.7fr 1fr 1fr;
          width: 100%;
        }

        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          grid-template-columns: 0.7fr 1fr 1fr;
          width: 100%;
        }

        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          grid-template-columns: 0.7fr 1fr 1fr;
          width: 100%;
        }

        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          grid-template-columns: 0.7fr 1fr 1fr;
          width: 100%;
        }

        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          grid-template-columns: 0.7fr 1fr 1fr;
          width: 100%;
        }

        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          grid-template-columns: 1fr 0.5fr 0.5fr;
          width: 97%;
        }

        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          grid-template-columns: 1fr 0.5fr 0.5fr;
          width: 97%;
        }

        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          grid-template-columns: 1fr 0.5fr 0.5fr;
          width: 97%;
        }

        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          grid-template-columns: 1fr 0.5fr 0.5fr;
          width: 97%;
        }

        //
        // ** -------
        //    BOXES
        // ** -------
        .box-nav-link-a {
          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;

          // ** phone
          .container-phone-email {
            padding: 25px 0 30px 25px;
            width: 100%;
            H1 {
              font-family: "NeutralFace-light";

              text-align: left;
              text-transform: uppercase;
              color: #f0eee2f6;
              font-weight: 500;
              letter-spacing: 1px;
              font-size: calc(7px + 1vmin);
              line-height: calc(14px + 1vmin);
            }
          }

          //
          //
          //
          .wrapper-socialLinks {
            padding: 30px 0 10px 0;
            // background-color: gold;
            display: grid;
            width: 100%;

            grid-template-columns: 0.5fr 0.5fr 2fr;
            gap: 5px;
            // ----------
            // ** CIRCLES
            // ----------
            //
            .socialLinks {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 20px;
              width: 30px;
              height: 30px;
              border-radius: 50%;

              border: 2px solid #fff;
              //
              //  a links
              .link_social-nav {
                color: #fff;
              }
              &:hover {
                color: #3a3a3a;
              }

              @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                width: 30px;
                height: 30px;
              }

              @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                width: 30px;
                height: 30px;
              }

              @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                width: 30px;
                height: 30px;
              }

              @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              }

              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                width: 56px;
                height: 56px;
              }

              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                width: 48px;
                height: 48px;
              }

              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              }

              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              }

              @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              }

              @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              }
            }
          }
        }

        .box-nav-link-b {
          width: 100%;

          justify-self: end;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;

          //
          // ((P))
          .copyright-navbar {
            color: #ffffffa3;
            font-family: "NeutralFace-Bold";
            font-family: "Wagon-ExtraLight";
            font-size: calc(12px + 1.1vmin);
            span {
              font-weight: 300;
              color: #faf7e9c2;
            }

            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              font-size: calc(6px + 1.1vmin);
            }

            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              font-size: calc(6px + 1.1vmin);
            }

            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              font-size: calc(7px + 1.1vmin);
            }

            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              font-size: calc(7px + 1.1vmin);
            }

            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            }

            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            }

            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              font-size: calc(12px + 1.1vmin);
            }

            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              font-size: calc(12px + 1.1vmin);
            }

            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            }

            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            }
            //
          }
        }
        .box-nav-link-c {
          width: 100%;

          justify-self: end;
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          //

          #container-svg-navbar {
            height: 30vh;
            padding: 70px 0 0 0;
            border-radius: 45px;

            overflow: hidden;
            border-radius: 45px;

            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              margin-top: 20px;
              padding: 20px 0 0 0;
              height: 13vh;
            }

            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              height: 16vh;
              max-width: 16vh;
            }

            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              height: 16vh;
              max-width: 16vh;
            }

            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              height: 16vh;
              max-width: 16vh;
            }

            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              height: 16vh;
              max-width: 16vh;
            }

            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              height: 16vh;
              max-width: 16vh;
            }

            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              height: 16vh;
              max-width: 16vh;
              height: 14vh;
            }

            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              height: 16vh;
              max-width: 16vh;
            }

            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              height: 14vh;
              max-width: 15vh;
            }

            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              height: 14vh;
              max-width: 15vh;
            }
            //
            //
            .svg-box-navbar {
              justify-self: end;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
              //
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
