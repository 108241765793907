//
// ** button

@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
//

//----------
// SECTION 2
//----------
//
.home_sticky-container {
  position: relative;
  width: 100%;

  //
  //  --------
  //   SECTION 1 🔥
  //  --------
  //
  #section_01 {
    width: 100%;
    height: 90vh;
    min-height: 88vh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // ** 🍭 STICKY
    position: sticky;
    top: 0;
    width: 100%;
    //
    // Fixed Img related
    // the img only is on the jsx

    // -------- **
    // -------- **
    // MOBILE - PORTRAIT
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      // background-color: yellow;
      height: 68vh;
      min-height: 65vh;
    }
    // MOBILE - LANDSCAPE
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      // background-color: blueviolet;
      height: 68vh;
      min-height: 65vh;
    }

    // TABLET1 - PORTRAIT
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      // background-color: orange;
      height: 48vh;
      min-height: 45vh;
    }
    // TABLET1 - LANDSCAPE
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      // background-color: orangered;
      height: 88vh;
      min-height: 85vh;
    }
    //-------------**
    //-------------**
    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      // background-color: sienna;
      height: 48vh;
      min-height: 45vh;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      // background-color: sandybrown;
      height: 68vh;
      min-height: 65vh;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      // background-color: seagreen;
      height: 88vh;
      min-height: 87vh;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      // background-color: olive;
      height: 88vh;
      min-height: 87vh;
    }
    //-------------**
    //-------------**

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      // background: pink;
      height: 90vh;
      min-height: 88vh;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      // background-color: plum;
      height: 90vh;
      min-height: 88vh;
    }
    //-------------**

    #container_section_01 {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      //
      // //
      // h1 {
      //   align-self: center;
      //   justify-self: center;
      //   //STICKY
      //   position: sticky;
      //   top: 0;
      //   font-size: 200px;
      // }
      //

      //-------- btn 🌻
      //
      //
      .container-footer-btn {
        width: 100%;
        height: 100%;
        // background-color: olive;
        .btn_ul-footer {
          min-height: 20vh;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .btn-li-footer {
            a {
              button {
                display: flex;
                justify-content: center;
                align-items: center;
                //
                // NavLink
                // border-radius: 30px;
                text-transform: capitalize;
                font-weight: 300;
                font-weight: 400;
                //
                font-family: "NeutralFace-Bold";
                cursor: pointer;
                background-color: #000000;
                letter-spacing: -0.1px;
                // font-size: calc(6px + 1vmin);
                // line-height: calc(5px + 1vmin);
                border: 1.9px solid #000000;
                color: #fef9f1f1;
                // ** btn --

                // width: 40%;;

                border-radius: 50px;
                // padding: 12px 28px;
                width: 400px;
                padding: 3.4em 3.4em 3.4em 3.4em;

                // ** btn --

                &:hover {
                  padding: 22px 38px;
                  background-color: #f9f6f1;
                  border: 1.9px solid #000000;
                  color: #000000d6;
                }

                // ** ---- media query -----
                //
                // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
                @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                  width: 160px;
                  padding: 17px 32px;
                  border-radius: 50px;

                  font-size: calc(7px + 1vmin);
                  line-height: calc(5px + 1vmin);
                  letter-spacing: 1px;
                  //
                  &:hover {
                    width: 160px;
                    padding: 17px 32px;
                    border-radius: 50px;
                  }
                }
                // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
                @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                  width: 190px;
                  padding: 17px 32px;
                  border-radius: 50px;

                  font-size: calc(7px + 1vmin);
                  line-height: calc(5px + 1vmin);
                  letter-spacing: 1px;
                  //
                  &:hover {
                    width: 190px;
                    padding: 17px 32px;
                    border-radius: 50px;
                  }
                }

                // ------
                // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
                @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                  width: 200px;
                  padding: 20px 32px;
                  border-radius: 50px;
                  letter-spacing: 1px;

                  font-size: calc(5px + 1vmin);
                  line-height: calc(5px + 1vmin);
                  //
                  &:hover {
                    width: 200px;
                    padding: 20px 32px;
                    border-radius: 50px;
                  }
                }
                //
                // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
                @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                  width: 200px;
                  padding: 20px 32px;
                  border-radius: 50px;
                  letter-spacing: 1px;

                  font-size: calc(5px + 1vmin);
                  line-height: calc(5px + 1vmin);
                  &:hover {
                    width: 200px;
                    padding: 20px 32px;
                    border-radius: 50px;
                  }
                }
                //-------------**
                // TABLET2 - PORTRAIT
                @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                  width: 280px;
                  padding: 20px 32px;
                  border-radius: 50px;
                  letter-spacing: 1px;

                  &:hover {
                    width: 280px;
                    padding: 20px 32px;
                    border-radius: 50px;
                  }
                }

                // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                  width: 240px;
                  padding: 22px 32px;
                  border-radius: 50px;
                  letter-spacing: 1px;

                  &:hover {
                    width: 240px;
                    padding: 22px 32px;
                    border-radius: 50px;
                  }
                }
                //-------------**
                //-------------**
                // DESKTOP - PORTRAIT
                @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                  padding: 18px 32px;
                  &:hover {
                    width: 280px;
                    border-radius: 50px;
                    padding: 18px 32px;
                  }
                }

                // DESKTOP - LANDSCAPE
                @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                  padding: 22px 32px;
                  width: 230px;
                  border-radius: 50px;
                  &:hover {
                    width: 230px;
                    border-radius: 50px;
                    padding: 22px 32px;
                  }
                }
                // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
                @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                  padding: 30px 60px;
                  width: 280px;
                  border-radius: 50px;

                  &:hover {
                    padding: 30px 60px;
                    width: 280px;
                    border-radius: 50px;
                  }
                }
                // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
                @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                  padding: 30px 60px;
                  width: 280px;
                  border-radius: 50px;
                  &:hover {
                    padding: 30px 60px;
                    width: 280px;
                    border-radius: 50px;
                  }
                }
              }
            }

            //
            //
            position: relative;
            //
            //  💮 SVG 👁️
            // small circle with svg icon
            .sm-icon-btn-press {
              //
              //

              border-radius: 50%;
              border: 1.9px solid #111;
              background-color: #f1e5cf;
              //
              display: flex;
              justify-content: center;
              align-items: center;
              transition: all 0.5s ease-in-out;

              position: absolute;
              width: 90px;
              height: 90px;
              bottom: -20px;
              right: -35px;

              // ** ---- media query -----
              //
              // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
              @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                width: 35px;
                height: 35px;
                bottom: -10px;
                right: -10px;
              }
              // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
              @media only screen and (max-height: 680px) and (min-width: 140px) and (orientation: landscape) {
                width: 35px;
                height: 35px;
                bottom: -10px;
                right: -10px;
              }
              // TABLET1 - PORTRAIT
              @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                width: 42px;
                height: 42px;
                bottom: -10px;
                right: -14px;
              }
              // TABLET1 - LANDSCAPE
              @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                width: 42px;
                height: 42px;
                bottom: -10px;
                right: -14px;
              }

              // TABLET2 - PORTRAIT
              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                width: 45px;
                height: 45px;
                bottom: -12px;
                right: -18px;
              }

              // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                width: 45px;
                height: 45px;
                bottom: -12px;
                right: -18px;
              }

              // DESKTOP - PORTRAIT
              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                width: 45px;
                height: 45px;
                bottom: -12px;
                right: -18px;
              }

              // DESKTOP - LANDSCAPE
              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                width: 45px;
                height: 45px;
                bottom: -12px;
                right: -18px;
              }

              // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
              @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                width: 60px;
                height: 60px;
                bottom: -15px;
                right: -25px;
              }
              // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
              @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                width: 60px;
                height: 60px;
                bottom: -15px;
                right: -25px;
              }
              // -------- **
              // -------- **
              //
              //
              // ** SVG
              .container-svg-sm-icon-btn-press {
                display: flex;
                justify-content: center;
                align-items: center;

                margin: 0 auto;
                overflow: hidden;
                border-radius: 45px;
                .svg-box-sm-press {
                  width: 55%;
                  height: 55%;
                }
              }
              // will make it move a bit when hovering
              &:hover {
                transform: translateX(5%);
              }
            }
          }
        }
      }
    }
  }

  //
  //----------
  // SECTION 2   🔥
  //----------
  //
  #section_02 {
    width: 100%;
    height: 100vh;
    min-height: 95vh;
    background-color: #000;
    // ** 🍭 STICKY
    position: sticky;
    top: 0;
    width: 100%;
    // background-color: orangered;
    // ** ---- media query -----
    //
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      height: 68vh;
      min-height: 67vh;
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      height: 90vh;
      min-height: 88vh;
    }

    // ------
    // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      height: 68vh;
      min-height: 67vh;
    }
    //
    // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      height: 78vh;
      min-height: 77vh;
    }
    //-------------**
    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      height: 78vh;
      min-height: 77vh;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      height: 78vh;
      min-height: 77vh;
    }
    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      height: 90vh;
      min-height: 88vh;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      height: 90vh;
      min-height: 88vh;
    }
    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      height: 100vh;
      min-height: 95vh;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      height: 100vh;
      min-height: 95vh;
    }

    #container_section_02 {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      background-color: transparent;

      // ** ---- SVG
      .footer-star-svg-white {
        color: rgba(255, 250, 233, 0.918);
        fill: rgba(255, 250, 233, 0.916);
      }
      .footer-star-svg-black {
        color: rgba(35, 35, 35, 0.408);
        fill: rgba(36, 36, 36, 0.408);
        //
      }

      //
      // -------- TEXT
      h1,
      h2,
      h3 {
        &.footer-section2_font-white-h1,
        &.footer-section2_font-black-h1,
        &.footer-section2_font-white-h2,
        &.footer-section2_font-black-h2,
        &.footer-section2_font-white-h3,
        &.footer-section2_font-black-h3 {
          text-align: center;
          text-transform: uppercase;
          word-break: break-all;
          word-wrap: break-word;
          letter-spacing: 0px;
          font-weight: 400;

          //
          // mix-blend-mode: difference;
          // filter: invert(100%);
        }
      }

      // ** COLORS
      // If the BG is BLACK, show then font white
      h1,
      h2,
      h3 {
        &.footer-section2_font-white-h1,
        &.footer-section2_font-white-h2,
        &.footer-section2_font-white-h3 {
          color: rgba(255, 250, 233, 0.916);
        }
      }
      //
      // ** COLORS
      // If the BG is WHITE, show then font white
      h1,
      h2,
      h3 {
        &.footer-section2_font-black-h1,
        &.footer-section2_font-black-h2,
        &.footer-section2_font-black-h3 {
          color: rgba(35, 35, 35, 0.408);
        }
      }
      //
      //
      //
      // ---- H1 - Font sizes
      h1 {
        &.footer-section2_font-white-h1,
        &.footer-section2_font-black-h1 {
          font-size: calc(180px + 1.1vmin);
          padding-top: 150px;
          //
          // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            padding-top: 30px;
            font-size: calc(80px + 1.1vmin);
          }
          // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            padding-top: 30px;
            font-size: calc(80px + 1.1vmin);
          }
          // ------
          // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            padding-top: 40px;
            font-size: calc(120px + 1.1vmin);
            letter-spacing: -2px;
          }
          //
          // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            letter-spacing: -2px;
            padding-top: 30px;
            font-size: calc(80px + 1.1vmin);
          }
          //-------------**
          // TABLET2 - PORTRAIT
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            letter-spacing: -2px;
            padding-top: 30px;
            font-size: calc(100px + 1.1vmin);
          }

          // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            letter-spacing: -2px;
            padding-top: 30px;
            font-size: calc(100px + 1.1vmin);
          }
          //-------------**
          //-------------**
          // DESKTOP - PORTRAIT
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            letter-spacing: -2px;
            padding-top: 30px;
            font-size: calc(120px + 1.1vmin);
          }

          // DESKTOP - LANDSCAPE
          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            letter-spacing: -2px;
            padding-top: 30px;
            font-size: calc(120px + 1.1vmin);
          }
          //-------------**
          //-------------**

          // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            letter-spacing: -2px;
            padding-top: 30px;
            font-size: calc(120px + 1.1vmin);
          }
          // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            letter-spacing: -2px;
            padding-top: 30px;
            font-size: calc(180px + 1.1vmin);
          }
          //-------------**
          // --------
        }

        // &.footer-section2_font-white-h1 {
        //   color: rgba(255, 250, 233, 0.916);
        // }
        // &.footer-section2_font-black-h1 {
        //   color: rgba(35, 35, 35, 0.408);
        // }
      }
      //
      // ---- H2
      //
      h2 {
        &.footer-section2_font-white-h2,
        &.footer-section2_font-black-h2 {
          //

          font-size: calc(5px + 1.1vmin);
          line-height: calc(10px + 1.1vmin);
        }
      }

      //
      // ---- H3
      //
      h3 {
        &.footer-section2_font-white-h3,
        &.footer-section2_font-black-h3 {
          //

          font-size: calc(5px + 1.1vmin);
          letter-spacing: 0px;
          font-weight: 400;
        }
      }

      //
      // --------------------
      //
      // ** SOCIAL LINKS
      //
      .container-social-links {
        margin: 0 auto;

        //   UL
        .ul-social-grid {
          // --------
          overflow: hidden;
          margin: 0 auto; // to center the whole container
          width: 100%;
          height: 100%;

          // ** GRID

          display: grid;
          grid-template-columns: 1fr 1fr; //0.6fr 1fr 0.4fr Here, you specify the columns with different widths, another option: 1fr 1fr 1fr 1fr;, will create 4 columns of the same size, but its not cool for mobile
          gap: 15px; // Adjust the gap between the boxes

          //  LInks side by side
          // -------- **
          // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            grid-template-columns: 1fr 1fr;
            gap: 5px;
          }
          // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            grid-template-columns: 1fr 1fr;
            gap: 5px;
          }
          // --------

          // -------- **
          // -------- **

          // TABLET1 - PORTRAIT
          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            grid-template-columns: 1fr 1fr;
          }
          // TABLET1 - LANDSCAPE
          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            grid-template-columns: 1fr 1fr;
          }
          //-------------**
          //-------------**
          // TABLET2 - PORTRAIT
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            grid-template-columns: 1fr 1fr;
          }

          // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            grid-template-columns: 1fr 1fr;
          }

          //-------------**
          //-------------**
          // DESKTOP - PORTRAIT
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            grid-template-columns: 1fr 1fr;
          }

          // DESKTOP - LANDSCAPE
          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            grid-template-columns: 1fr 1fr;
          }
          //-------------**
          //-------------**

          // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            grid-template-columns: 1fr 1fr;
          }
          // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            grid-template-columns: 1fr 1fr;
            gap: 5px;
          }
          //-------------**

          //
          //
          //
          // LI
          li {
            width: 100%;
            margin: 0 auto;
            overflow: hidden;

            //
            //

            text-align: center;
            text-transform: uppercase;
            word-break: break-all;
            word-wrap: break-word;

            //footer-section2_font-white-a_link

            letter-spacing: 0px;
            font-weight: 400;
            //

            //

            font-size: calc(2px + 1.1vmin);
            line-height: calc(10px + 1.1vmin);
            //
            //
            a {
              cursor: pointer;
              //
              //
              &.footer-section2_font-white-a_link {
                color: rgba(255, 250, 233, 0.916);
              }
              &.footer-section2_font-black-a_link {
                color: rgba(35, 35, 35, 0.408);
              }
            }
          }
        }
      }

      //
      // ** STAR

      // -------- **
      // -------- **
      #container-svg-footer {
        height: 30vh;
        padding: 70px 0 0 0;
        border-radius: 45px;
        //
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 45px;
        // -------- **
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          margin-top: 20px;
          padding: 20px 0 0 0;
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          margin-top: 20px;
          padding: 20px 0 0 0;
        }
        // ------
        // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          margin-top: 20px;
          padding: 20px 0 0 0;
        }
        //
        // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          margin-top: 20px;
          padding: 10px 0 0 0;
        }
        //-------------**
        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          margin-top: 20px;
          padding: 20px 0 0 0;
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          margin-top: 20px;
          padding: 20px 0 0 0;
        }
        //
        //
        .svg-box-footer {
          width: 90%;
          height: 90%;

          // -------- **
          // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            width: 80%;
            height: 80%;
          }
          // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            width: 95%;
            height: 95%;
          }
          // ------
          // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            width: 75%;
            height: 75%;
          }
          //
          // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            width: 90%;
            height: 90%;
          }

          //-------------**
          // TABLET2 - PORTRAIT
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            width: 75%;
            height: 75%;
          }

          // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            width: 65%;
            height: 65%;
          }
        }
      }
    }
  }
}
