.wrapper-img-wide-comp {
  background-color: pink;

  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // --------
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: sienna;

    height: auto;
    padding: 20px 0;
    min-height: 10vh;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: lightblue;
    height: auto;
    padding: 20px 0;
    min-height: 10vh;
  }

  // --------
  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    height: auto;
    padding: 20px 0;
    min-height: 30vh;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: blue;
    height: auto;
    padding: 20px 0;
    min-height: 30vh;
  }

  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: teal;
    height: auto;
    padding: 20px 0;
    min-height: 40vh;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    //  background-color: powderblue;
    // background-color: tan;
    height: auto;
    padding: 20px 0;
    min-height: 40vh;
  }
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: powderblue;
    height: auto;
    padding: 20px 0;
    min-height: 40vh;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olive;
    height: auto;
    padding: 20px 0;
    min-height: 40vh;
  }
  //-------------**
  //-------------**
  //-------------
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: fuchsia;
    height: 100vh;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: yellow;
    min-height: 100vh;
  }
  //-------------**
  //-------------**
  /*





*/
  //--------
  // **  white section
  //--------
  .container-img-wide-comp {
    width: 100%;
    .img-box-wide-comp {
      // will-change: "width, transform";
      // transition: 1s all ease-in-out;
      width: 100%;
      // height: 100%;
      overflow: hidden;
      //
      //
      img {
        border: 3px solid #282828;
        //
        //
        border-top-right-radius: 50px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        //
        //
        display: block;
        width: 100%;
        min-height: auto;
        object-fit: cover;
        // aspect-ratio: 1.2/1;
        // --------
      }
    }
  }
}
