/*
I will add 2 different media queries, since one contains a longer headline, and you cannot implement it in the same way


- the welcome will be a bit smaller , because welcome word and "to meyoko" is longer

- the furies will have a slightest bigger font

SOME you will find them:
http://responsivetesttool.com/?url=http://localhost:7455/


or on the google responsive settings

*/

@mixin Headline_H1_Welcome_and_The {
  /*

          ((H1))
          WELCOME

*/

  // large text WELCOME
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    font-size: calc(45px + 1.1vmin);
    line-height: calc(45px + 1.1vmin);
    letter-spacing: 1px; // this device is ahǵosta/narrow compared to the mobile LANDScape which is wider/ancho
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    font-size: calc(70px + 1.1vmin);
    line-height: calc(85px + 1.1vmin);
    letter-spacing: 1px;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    font-size: calc(80px + 1.1vmin);
    line-height: calc(85px + 1.1vmin);
    letter-spacing: -1px;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    font-size: calc(65px + 1.1vmin);
    line-height: calc(85px + 1.1vmin);
    letter-spacing: -1px;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // color: teal;
    font-size: calc(90px + 1.1vmin);
    line-height: calc(80px + 1.1vmin);
    letter-spacing: -1px;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    font-size: calc(110px + 1.1vmin);
    line-height: calc(130px + 1.1vmin);
    letter-spacing: -1px;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    font-size: calc(140px + 1.1vmin);
    line-height: calc(150px + 1.1vmin);
    letter-spacing: -1px;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    font-size: calc(100px + 1.1vmin);
    line-height: calc(115px + 1.1vmin);
    letter-spacing: -1px;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    font-size: calc(150px + 1.1vmin);
    line-height: calc(170px + 1.1vmin);
    letter-spacing: -1px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    font-size: calc(150px + 1.1vmin);
    line-height: calc(130px + 1.1vmin);
    letter-spacing: -1px;
  }
  //-------------**
  //-------------**
}

/*


          THE FURIES

*/

@mixin Headline_H1_ToMEYOKO_and_Furies {
  // ((H1))
  // ** lARGE text FURIES
  //
  //
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    font-size: calc(42px + 1.1vmin);
    line-height: calc(45px + 1.1vmin);
    letter-spacing: 1px;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: rgb(209, 166, 215);
    font-size: calc(70px + 1.1vmin);
    line-height: calc(85px + 1.1vmin);
    letter-spacing: 1px;
    align-self: end;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    font-size: calc(80px + 1.1vmin);
    line-height: calc(85px + 1.1vmin);
    letter-spacing: 1px;
    align-self: end;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    font-size: calc(65px + 1.1vmin);
    line-height: calc(65px + 1.1vmin);
    letter-spacing: 1px;
    align-self: end;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    font-size: calc(90px + 1.1vmin);
    line-height: calc(80px + 1.1vmin);
    letter-spacing: 1px;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    font-size: calc(110px + 1.1vmin);
    line-height: calc(130px + 1.1vmin);
    letter-spacing: 1px;
  }
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    font-size: calc(180px + 1.1vmin);
    line-height: calc(150px + 1.1vmin);
    letter-spacing: 1px;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    font-size: calc(100px + 1.1vmin);
    line-height: calc(115px + 1.1vmin);
    letter-spacing: 1px;
  }
  //-------------**
  //-------------**
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    font-size: calc(150px + 1.1vmin);
    line-height: calc(170px + 1.1vmin);
    letter-spacing: 1px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // color: red;
    font-size: calc(150px + 1.1vmin);
    line-height: calc(155px + 1.1vmin);
    letter-spacing: 1px;
  }
  //-------------**
  //-------------**
}

/*






RIGHT side or the small texts





                  ((H1))



*/

@mixin Headline_H1_Welcome__titleOfTheCollection {
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    margin-top: 10px;
    font-size: calc(6px + 1.1vmin);
    line-height: calc(10px + 1.1vmin);
    align-self: end;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    margin-top: 10px;
    font-size: calc(6px + 1.1vmin);
    line-height: calc(10px + 1.1vmin);
    align-self: end;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    margin-top: 10px;
    font-size: calc(6px + 1.1vmin);
    line-height: calc(10px + 1.1vmin);
    align-self: end;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    margin-top: 20px;
    letter-spacing: -0.1px;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    font-size: calc(6px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);

    align-self: end;
  }
  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    font-size: calc(7px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT (1650 x 1650) on google , add the values 1650 there
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }

  // DESKTOP - LANDSCAPE (1440 x 900 ) http://responsivetesttool.com/?url=http://localhost:7455/
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    font-size: calc(9px + 1.1vmin);
    line-height: calc(7px + 1.1vmin);
    align-self: end;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    font-size: calc(9px + 1.1vmin);
    line-height: calc(7px + 1.1vmin);
    align-self: end;
  }
}

/*


            ((H2))


01.01.24
*/

@mixin Headline_H2_Welcome__Collection {
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    font-size: calc(5px + 1.1vmin);
    line-height: calc(5px + 1.1vmin);
    align-self: end;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    font-size: calc(5px + 1.1vmin);
    line-height: calc(5px + 1.1vmin);
    align-self: end;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    font-size: calc(7px + 1.1vmin);
    line-height: calc(8px + 1.1vmin);
    align-self: end;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    letter-spacing: -0.1px;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(8px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    font-size: calc(6px + 1.1vmin);
    line-height: calc(5px + 1.1vmin);
    align-self: end;
  }
  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: pink;
    // color: fuchsia;
    font-size: calc(6px + 1.1vmin);
    line-height: calc(5px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT (1650 x 1650) on google , add the values 1650 there
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    font-size: calc(8px + 1.1vmin);
    line-height: calc(12px + 1.1vmin);
    align-self: end;
  }

  // DESKTOP - LANDSCAPE (1440 x 900 ) http://responsivetesttool.com/?url=http://localhost:7455/
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    font-size: calc(8px + 1.1vmin);
    line-height: calc(12px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    font-size: calc(9px + 1.1vmin);
    line-height: calc(12px + 1.1vmin);
    align-self: end;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    font-size: calc(9px + 1.1vmin);
    line-height: calc(12px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
}
/*


            ((H3))


01.01.24
*/

@mixin Headline_H3_Welcome__date {
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    margin: 5px 0;
    font-size: calc(4px + 1.1vmin);
    line-height: calc(5px + 1.1vmin);
    align-self: end;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    margin: 5px 0;
    font-size: calc(4px + 1.1vmin);
    line-height: calc(5px + 1.1vmin);
    align-self: end;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 360 (mobile)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    margin: 5px 0;
    font-size: calc(4px + 1.1vmin);
    line-height: calc(10px + 1.1vmin);
    align-self: end;
  }
  // TABLET1 - LANDSCAPE 768 x 360 (mobile)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    margin: 5px 0;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    margin: 5px 0;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    margin: 5px 0;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT (1650 x 1650) on google , add the values 1650 there
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    margin: 7px 0;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }

  // DESKTOP - LANDSCAPE (1440 x 900 ) http://responsivetesttool.com/?url=http://localhost:7455/
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    margin: 7px 0;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    margin: 10px 0;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    margin: 10px 0;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
}
