@import "../scss/font-sizes-drawingScreen";

#wrapper-drawingScreen {
  // border-top: 10px solid var(--color-border);
  // border-left: 10px solid var(--color-border);
  // border-bottom: 10px solid var(--color-border);

  padding: 20px 0 70px 0;
  // background-color: #cfd3d8;
  // background-color: #e8eaed;
  // background-color: red;
  background-color: var(--bg-color);
  // border-bottom: 2px solid rgb(14, 14, 14);

  // background-color: #f0d4de;
  // background-color: #f6cfc3; // powder pink
  // background-color: #f9d3c72f; // powder pink
  // background-color: #fff0c0; // yellow
  //
  //
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

  margin: 0 auto;

  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    padding: 20px 0 10px 0;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 140px) and (orientation: landscape) {
    padding: 20px 0 10px 0;
  }
  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    padding: 20px 0 10px 0;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    padding: 20px 0 70px 0;
  }

  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    padding: 20px 0 10px 0;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    padding: 20px 0 10px 0;
  }

  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    padding: 20px 0 70px 0;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    padding: 20px 0 70px 0;
  }

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    padding: 20px 0 70px 0;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    padding: 20px 0 70px 0;
  }

  // ---------------- **
  //
  // ** 1) GRID
  // ---------------- **
  .wrapper-grid-TextDrawScreen {
    padding: 0px 0;

    max-width: 2000px;
    width: 100%;
    height: 100%;
    border-bottom: 2px solid #000;

    .container-TextDrawScreen {
      background-color: #cfd3d8;
      background-color: #cfd3d8;

      background-color: var(--bg-color-2);

      padding: 10px 0 0 0;
      width: 100%;
      height: 100%;
      // background-color: lavender;
      display: grid;
      grid-template-columns: 0.2fr 1.2fr 0.2fr;
      gap: 0px;

      // position: relative;
      // overflow: hidden;

      // &::after {
      //   content: "";
      //   width: 250%;
      //   height: 250%;

      //   top: 0;
      //   left: 0;
      //   right: 0;
      //   bottom: 0;
      //   position: absolute;
      //   // z-index: 100;
      //   pointer-events: none;
      //   //
      //   // background-color: rgba(0, 0, 0, 0.901);
      //   z-index: 100;
      //   pointer-events: none;

      //   background: linear-gradient(
      //       0deg,
      //       rgba(0, 25, 117, 0.249),
      //       rgba(207, 220, 220, 0.268)
      //     ),
      //     url(https://grainy-gradients.vercel.app/noise.svg);
      //   filter: contrast(200%) brightness(120%);
      // }
      //
      //

      // -------- **
      // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        grid-template-columns: 1fr;
      }
      // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        grid-template-columns: 1fr;
      }
      // ------
      // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        grid-template-columns: 0.2fr 1fr 0.2fr;
      }
      //
      // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        grid-template-columns: 0.2fr 1fr 0.2fr;
      }

      //-------------**
      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        grid-template-columns: 0.3fr 1fr 0.3fr;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        grid-template-columns: 0.3fr 1fr 0.3fr;
      }
      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        grid-template-columns: 0.3fr 1.2fr 0.3fr;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        grid-template-columns: 0.3fr 1.2fr 0.3fr;
      }

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        grid-template-columns: 0.3fr 1.2fr 0.3fr;
      }
      // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        grid-template-columns: 0.3fr 1.2fr 0.3fr;
      }
      //

      // ------
      //
      .left_TextDrawScreen,
      .right_TextDrawScreen {
        border-top: 2px solid #000;
        padding: 10px 0 0 0;
        //
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .container-star {
          width: 70%;
          height: 100%;

          border: 2px solid #000;
          border-bottom: 0;
          border-top-right-radius: 180px;
          border-top-left-radius: 180px;
          // border: 2px solid #000;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background-color: #e8eaed;

          .circle-box {
            width: 40%;
            height: 120px;
            border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; // egg shape
            border: 2px solid #000;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;

            box-shadow: 2px 20px 50px 2px rgb(255, 252, 224),
              inset 20px 50px 50px 40px #e4e3d9d8;

            // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              display: none;
            }
            // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              display: none;
            }
            // ------
            // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              display: none;
            }
            //
            // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              display: none;
            }

            //-------------**
            // TABLET2 - PORTRAIT
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            }
            //-------------**
            //-------------**
            // DESKTOP - PORTRAIT
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              display: flex;
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              display: flex;
            }

            // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              display: flex;
            }
            // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              display: flex;
            }
          }

          //
          //
          //
          .overlay-left-imgTar,
          .overlay-right-imgTar {
            width: 20%;
            height: 70%;
            border: 2px solid #000;
            border-radius: 180px;
            margin: 5px 0 0 0;
          }
        }
        //   background-color: rgb(245, 237, 227);

        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          display: none;
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          display: none;
        }
        // ------
        // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          display: flex;
        }
        //
        // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          display: flex;
        }

        //-------------**
        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        }
        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        }

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        }
        // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        }
      }

      //
      //  ------ 🌟
      //
      .left_TextDrawScreen {
        // border-right-color: #000;
        // border-right-width: 2px;
        // border-style: solid;
        // border-top: 0;
        // border-left: 0;
        // border-bottom: 0;
        border-right: 2px solid #000;
      }
      //  ------ 🌟
      //
      .right_TextDrawScreen {
        border-left: 2px solid #000;
        // border-left-color: #000;
      }
      //
      //
      //
      //
      .wrapper-TextDrawScreen {
        background-color: #e8eaed;
        border-top: 2px solid #000;
        padding: 70px 0 0 0;

        //
        //
        //
        //
        .container-TextDrawScreen {
          padding: 200px 100px;
          // background-color: #cfd3d8;
          margin: 0 auto;
          width: 70%;
          border-top-right-radius: 580px;
          border-top-left-radius: 580px;

          border-top: 2px solid #000;
          border-left: 2px solid #000;
          border-right: 2px solid #000;
          border-bottom: 0;
          // border: 1px solid #000;
          // border-bottom-left-radius: 500px;
          // border-bottom-right-radius: 500px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            width: 76%;
            padding: 100px 10px;
          }

          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            width: 76%;
            padding: 100px 70px;
          }

          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            width: 90%;
            padding: 100px 70px;
          }

          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            width: 90%;
            padding: 180px 100px;
          }

          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            width: 90%;
            padding: 180px 100px;
          }

          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          }
          // **  ------- desk
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            padding: 200px 100px;
            width: 80%;
          }

          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            padding: 200px 100px;
            width: 80%;
          }

          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            padding: 200px 100px;
            width: 70%;
          }

          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            padding: 200px 100px;
            width: 70%;
          }
          //
          //
          //
          //
          //
          h1 {
            margin: 0 auto;
            padding: 40px 0;
            width: 90%;

            font-family: "Matao-Regular";
            font-family: "Casta-Thin";
            color: #212121;
            font-weight: 500;
            letter-spacing: 1px;
            font-size: calc(15px + 1.1vmin);
            line-height: calc(9px + 1.1vmin);
            text-align: justify;

            //
            //

            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              letter-spacing: -1px;
              width: 90%;
              padding: 20px 0;
              text-align: justify;
              font-size: calc(25px + 1.1vmin);
              line-height: calc(26px + 1.1vmin);
            }

            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              letter-spacing: -0.2px;
              width: 90%;
              padding: 20px 0;
              text-align: justify;
              font-size: calc(25px + 1.1vmin);
              line-height: calc(26px + 1.1vmin);
            }

            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              width: 90%;
              padding: 20px 0;
              text-align: justify;
              font-size: calc(25px + 1.1vmin);
              line-height: calc(26px + 1.1vmin);
            }

            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              width: 90%;
              padding: 20px 0;
              text-align: justify;
              font-size: calc(25px + 1.1vmin);
              line-height: calc(26px + 1.1vmin);
            }

            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              letter-spacing: -0.2px;
              font-size: calc(32px + 1.1vmin);
              line-height: calc(32px + 1.1vmin);
            }

            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              letter-spacing: -0.2px;
              font-size: calc(32px + 1.1vmin);
              line-height: calc(32px + 1.1vmin);
            }
            // **
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              letter-spacing: -0.2px;
              font-size: calc(30px + 1.1vmin);
              line-height: calc(30px + 1.1vmin);
            }

            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              letter-spacing: -0.2px;
              font-size: calc(30px + 1.1vmin);
              line-height: calc(30px + 1.1vmin);
            }

            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              letter-spacing: -0.2px;
              font-size: calc(70px + 1.1vmin);
              line-height: calc(95px + 1.1vmin);
              line-height: calc(95px + 1.1vmin);
            }

            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              letter-spacing: -0.2px;
              font-size: calc(50px + 1.1vmin);
              line-height: calc(52px + 1.1vmin);
            }
            //
            //
          }

          //
          //
          p {
            color: #989185;
            color: #575757a3;

            text-align: center;

            margin: 0 auto;
            font-family: "NotoSans-Regular";
            font-weight: 300;
            font-size: calc(10px + 1.1vmin);
            line-height: calc(18px + 1.1vmin);

            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              letter-spacing: 1px;
              width: 90%;
              text-align: left;
              font-size: calc(13px + 1.1vmin);
              line-height: calc(23px + 1.1vmin);
            }

            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              letter-spacing: 1px;
              font-size: calc(13px + 1.1vmin);
              line-height: calc(23px + 1.1vmin);
            }

            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              letter-spacing: 1px;
              font-size: calc(10px + 1.1vmin);
              line-height: calc(18px + 1.1vmin);
            }

            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              letter-spacing: 1px;
              font-size: calc(10px + 1.1vmin);
              line-height: calc(18px + 1.1vmin);
            }
            //-------------**
            //-------------**

            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              letter-spacing: 1px;

              font-size: calc(10px + 1.1vmin);
              line-height: calc(18px + 1.1vmin);
              font-weight: 200;
            }

            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              letter-spacing: 1px;
              font-size: calc(10px + 1.1vmin);
              line-height: calc(18px + 1.1vmin);
              font-weight: 200;
            }

            //  ** ------
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              font-size: calc(11px + 1.1vmin);
              line-height: calc(18px + 1.1vmin);
              max-width: 640px;
              width: 90%;
            }

            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              font-size: calc(11px + 1.1vmin);
              line-height: calc(18px + 1.1vmin);
              max-width: 640px;
              width: 90%;
            }

            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              font-size: calc(11px + 1.1vmin);
              line-height: calc(18px + 1.1vmin);
              max-width: 780px;
            }

            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              letter-spacing: -0.1px;
              font-size: calc(11px + 1.1vmin);
              line-height: calc(18px + 1.1vmin);
              max-width: 780px;
            }
          }
        }
      }
      //

      // ------ img
    }
  }
  //

  // ---------------
  //
  // ---------------

  #gallery_page {
    margin: 0 auto;
    width: 80%;
    padding: 100px 0 100px 0;
    .gallery_page-container {
      display: flex;
      justify-content: center;
      align-items: center;

      // @include flex-center-col;
      min-height: 90vh;

      .gallery-card-wrapper {
        width: 95%;
        grid-template-columns: 1fr 1fr 1fr;
        display: grid;
        grid-gap: 1em;

        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          grid-template-columns: 1fr;
        }

        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          grid-template-columns: 1fr;
        }

        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          grid-template-columns: 1fr 1fr;
        }

        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          grid-template-columns: 1fr 1fr;
        }
        //-------------**
        //-------------**

        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          grid-template-columns: 1fr 1fr;
        }

        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          grid-template-columns: 1fr 1fr;
        }

        //  ** ------
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        //
        //
        //

        .gallery_page-card {
          border-radius: 50px;
          overflow: hidden;
          // @include box-shadow-grey-gallery-card;
          // @include flex-center;

          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1rem;

          .gallery-page-container {
            color: rgba(40, 34, 34, 0.789);
            width: auto;
            position: relative;
            //
            border: 2px solid #000;
            border-top-right-radius: 480px;
            border-top-left-radius: 480px;
            padding: 10px;
            &::after {
              pointer-events: none;
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;

              // @include gradientCenterOrangeOpacity-v2-transp;
              border-radius: 50px;
            }

            //
            //
            //---------------
            //
            .wrapper-video-and-img {
              overflow: hidden;
              border: 2px solid #000;
              border-top-right-radius: 480px;
              border-top-left-radius: 480px;
              padding: 20px;

              // background-color: #cfd3d86c;
              // background-color: #e8eaed6d;
              // border-radius: 100px;
              // -webkit-box-shadow: #fff 0 -1px 4px, rgb(255, 123, 0) 0 -2px 10px,
              //   inset 5px 5px 42px 17px rgba(244, 227, 201, 0.926);
              // box-shadow: #fff 0 -1px 4px, #ff0 0 -2px 10px,
              //   inset 5px 5px 42px 17px rgba(244, 227, 201, 0.926);

              .video-img-container {
                width: auto;
                display: flex;
                justify-content: center;
                align-items: center;

                .video-img_box {
                  width: 100%;
                  min-height: auto;
                  object-fit: cover;
                  filter: drop-shadow(0 10mm 10mm rgb(255, 255, 255));
                  cursor: pointer;
                }
              }
            }
            //---------------
          }
        }
      }
    }
  }
  //
  //-------------
  //
  //-------------

  // #wrapper-animZ-btn-hover {
  //   width: 100%;
  //   height: 70vh;

  //   .container-animZ-btn-hover {
  //     width: 100%;
  //     height: 100%;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;

  //     .animated-yAxis-on-hover {
  //       width: 180px;
  //       height: 180px;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;

  //       transform: rotateX(51deg) rotateZ(43deg);
  //       transform-style: preserve-3d;
  //       border-radius: 32px;
  //       //-----
  //       // background-color: rgba(190, 190, 192, 0.096);
  //       // box-shadow: 1px 1px 0 1px #f9f9fb, -1px 0 28px 0 rgba(34, 33, 81, 0.01),
  //       //   28px 28px 28px 0 rgba(34, 33, 81, 0.25);
  //       //--------
  //       transition: 0.4s ease-in-out transform, 0.4s ease-in-out box-shadow;

  //       &:hover {
  //         background-color: rgba(190, 190, 192, 0.096);

  //         transform: translate3d(0px, -16px, 0px) rotateX(51deg) rotateZ(43deg);
  //         box-shadow: 1px 1px 0 1px #f9f9fb,
  //           -1px 0 28px 0 rgba(34, 33, 81, 0.01),
  //           54px 54px 28px -10px rgba(34, 33, 81, 0.15);
  //       }

  //       .circle-box {
  //         width: 120px;

  //         border-radius: 50%; // egg shape
  //         padding: 15px 26px;
  //         border: 2px solid #000;
  //         overflow: hidden;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;

  //         // box-shadow: 2px 20px 50px 2px rgb(255, 252, 224),
  //         //   inset 20px 50px 50px 40px #e4e3d9d8;

  //         transform: perspective(900px) rotateX(-40deg) rotateZ(-40deg)
  //           scale(0.7);
  //         // box-shadow: 0px 20px 100px #55555582;
  //         transition: 0.5s ease all;

  //         &:hover {
  //           transform: rotate(45deg) scale(1) translateY(10px);
  //         }
  //       }
  //     }
  //   }
  // }

  //
  //
}
