#allPrints_page2 {
  background-color: #e5e7ea;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  //
  //
  border-top: 3px solid #000;
  border-left: 5px solid #000;
  border-right: 15px solid #000;
  //
  //
  .allPrints_page2 {
    margin-top: 200px;
    width: 97%;

    // MOBILE - PORTRAIT
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      width: 100%;
    }
    // MOBILE - LANDSCAPE
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      width: 100%;
    }
    //-------------**
    //-------------**
    // TABLET1 - PORTRAIT
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      width: 90%;
    }
    // TABLET1 - LANDSCAPE
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      width: 90%;
    }

    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      width: 87%;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      width: 87%;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      width: 85%;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      width: 85%;
    }

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      width: 85%;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      width: 85%;
    }
    //-------------**
    //-------------**
    // background-color: #f1f3f5;
    //
    // border: 3px solid #282828;

    // border-top-right-radius: 900px;
    // border-top-left-radius: 900px;
    // border-bottom-left-radius: 100px;
    // border-bottom-right-radius: 100px;
    //
    //egg
    // border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
    // --------------
    //  GRID the furies
    // --------------
    //
    //
    //   container GRID
    .wrapper_gallery_grid_3 {
      // background-color: #eae8e3;

      //
      padding: 300px 200px 100px 200px;
      margin: 0 auto;
      width: 100%;
      height: auto;
      //   background-color: red;
      // -------- **
      // -------- **
      // MOBILE - PORTRAIT
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        padding: 70px 30px 70px 30px;
      }
      // MOBILE - LANDSCAPE
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        padding: 70px 30px 70px 30px;
      }
      //-------------**
      //-------------**
      // TABLET1 - PORTRAIT
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        padding: 70px 30px 70px 30px;
      }
      // TABLET1 - LANDSCAPE
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        padding: 70px 30px 70px 30px;
      }

      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        padding: 70px 30px 70px 30px;
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        padding: 70px 30px 70px 30px;
      }

      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        padding: 150px 30px 70px 30px;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        padding: 150px 30px 70px 30px;
      }

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        padding: 200px 50px 100px 50px;
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        padding: 200px 50px 100px 50px;
      }
      //-------------**
      //-------------**

      //
      // --------- GRID
      //
      //
      .container_gallery-card_grid-3 {
        .container-images-and-text_3 {
          // grid
          display: grid;
          width: 100%;
          height: 100%;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 0.3em;

          // -------- **
          // -------- **
          // MOBILE - PORTRAIT
          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            grid-template-columns: 0.9fr;
            gap: 15px;
          }
          // MOBILE - LANDSCAPE
          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            grid-template-columns: 0.9fr;
            gap: 15px;
          }
          //-------------**
          //-------------**
          // TABLET1 - PORTRAIT
          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            grid-template-columns: 0.5fr 1fr;
            gap: 15px;
          }
          // TABLET1 - LANDSCAPE
          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            grid-template-columns: 0.5fr 1fr;
            gap: 15px;
          }

          // TABLET2 - PORTRAIT
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            grid-template-columns: 0.5fr 1fr;
            gap: 15px;
          }

          // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            grid-template-columns: 0.5fr 0.3fr 0.5fr;
            gap: 20px;
          }

          //-------------**
          //-------------**
          // DESKTOP - PORTRAIT
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            grid-template-columns: 0.5fr 0.3fr 0.5fr;
            gap: 20px;
          }

          // DESKTOP - LANDSCAPE
          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            grid-template-columns: 0.5fr 0.3fr 0.5fr;
            gap: 20px;
          }

          // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            grid-template-columns: 0.5fr 0.3fr 0.5fr;
            gap: 20px;
          }
          // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            grid-template-columns: 0.5fr 0.3fr 0.5fr;
            gap: 20px;
          }
          //-------------**

          // -------------
          //  IMG
          // -------------
          //
          .img-container-grid-2 {
            width: 100%;
            height: 100%;
            .container_img-box-grid-2 {
              margin: 0 auto;
              overflow: hidden;
              //   border: 3px solid #282828;

              //   border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
              //
              border-top-right-radius: 900px;
              border-top-left-radius: 900px;
              border-bottom-left-radius: 500px;
              border-bottom-right-radius: 500px;
              border: 1px solid #ffffff2c;
              .img-box-grid-2 {
                width: 100%;

                img {
                  //
                  //
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  cursor: pointer;
                  // aspect-ratio: 1.4/1;
                  aspect-ratio: 0.8/1;
                }
              }
            }
          }
          //------

          .description-grid-2 {
            position: -webkit-sticky;
            position: sticky;
            top: 30px;
            // background-color: red;
            height: 120px;

            // -------- **
            // -------- **
            // MOBILE - PORTRAIT
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              height: 70px;
            }
            // MOBILE - LANDSCAPE
            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              height: 70px;
            }
            //-------------**
            //-------------**
            // TABLET1 - PORTRAIT
            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              height: 85px;
            }
            // TABLET1 - LANDSCAPE
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              height: 85px;
            }

            // TABLET2 - PORTRAIT
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              height: 95px;
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              height: 95px;
            }

            //-------------**
            //-------------**
            // DESKTOP - PORTRAIT
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              height: 110px;
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              height: 110px;
            }

            // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              height: 120px;
            }
            // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              height: 120px;
            }
            //-------------**

            //
            //
            //
            h1 {
              position: -webkit-sticky;
              position: sticky;
              top: 100px;
              padding: 10px 0 8px 0;
              //
              margin: 2px 0 0 0;
              // background-color: #111;
              // color: lemonchiffon;
              color: black;
              font-weight: 300;
              font-size: calc(11px + 1.1vmin);
            }
          }
        }
      }
    }

    //
    //  -------
    //
  }
}
