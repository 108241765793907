//-------- btn 🌻
//
//
.wrapper-BTN-eye {
  // background-color: olive;
  .ul_BTN-eye {
    padding-top: 20px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    //
    //
    .li_BTN-eye {
      a {
        button {
          display: flex;
          justify-content: center;
          align-items: center;

          text-transform: capitalize;
          font-weight: 300;
          font-weight: 400;
          //
          font-family: "NeutralFace-Bold";
          cursor: pointer;

          letter-spacing: -0.1px;
          border: 1.9px solid #000000;

          font-size: calc(6px + 1.1vmin);
          padding: 26px 15px;
          border-radius: 50px;
          //
          background-color: #f9f6f1;
          color: #000000d6;
          &:hover {
            padding: 26px 15px;
            background-color: #030303;
            color: #fef9f1f1;
            border: 1.9px solid #000000;
          }
          //
          //
          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            width: 200px;
            letter-spacing: -0.1px;
            font-size: calc(7px + 1.1vmin);
          }

          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            width: 240px;
            letter-spacing: -0.1px;
            font-size: calc(7px + 1.1vmin);
          }

          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            width: 240px;
            letter-spacing: 1px;
            font-size: calc(3px + 1.1vmin);
          }

          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            width: 280px;
            letter-spacing: 1px;
            font-size: calc(4px + 1.1vmin);
          }

          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            width: 280px;
            letter-spacing: 1px;
            font-size: calc(4px + 1.1vmin);
          }

          // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            width: 280px;
            letter-spacing: 1px;
            font-size: calc(4px + 1.1vmin);
          }

          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            width: 280px;
            letter-spacing: 1px;
            font-size: calc(6px + 1.1vmin);
          }

          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            width: 280px;
            letter-spacing: 1px;
            font-size: calc(6px + 1.1vmin);
          }

          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            width: 280px;
            letter-spacing: 1px;
            font-size: calc(6px + 1.1vmin);
          }

          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            width: 280px;
            letter-spacing: 1px;
            font-size: calc(6px + 1.1vmin);
          }
        }
      }

      //
      //💮
      position: relative;
      //
      //
      //
      //    SVG 👁️
      // small circle with svg icon
      .small-SVG-icon-BTN-eye {
        //
        //

        border-radius: 50%;
        border: 1.9px solid #111;
        background-color: #f1e5cf;
        //
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease-in-out;

        position: absolute;
        width: 90px;
        height: 90px;
        bottom: -20px;
        right: -35px;

        // ** ---- media query -----
        //
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          width: 35px;
          height: 35px;
          bottom: -10px;
          right: -10px;
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
        @media only screen and (max-height: 680px) and (min-width: 140px) and (orientation: landscape) {
          width: 35px;
          height: 35px;
          bottom: -10px;
          right: -10px;
        }
        // TABLET1 - PORTRAIT
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          width: 42px;
          height: 42px;
          bottom: -10px;
          right: -14px;
        }
        // TABLET1 - LANDSCAPE
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          width: 42px;
          height: 42px;
          bottom: -10px;
          right: -14px;
        }

        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          width: 45px;
          height: 45px;
          bottom: -12px;
          right: -18px;
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          width: 45px;
          height: 45px;
          bottom: -12px;
          right: -18px;
        }

        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          width: 45px;
          height: 45px;
          bottom: -12px;
          right: -18px;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          width: 45px;
          height: 45px;
          bottom: -12px;
          right: -18px;
        }

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          width: 60px;
          height: 60px;
          bottom: -15px;
          right: -25px;
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          width: 60px;
          height: 60px;
          bottom: -15px;
          right: -25px;
        }
        // -------- **
        // -------- **
        //       #111111
        //   #23232368
        //
        // ** SVG
        .container-small-SVG-icon-BTN-eye {
          display: flex;
          justify-content: center;
          align-items: center;

          margin: 0 auto;
          overflow: hidden;
          border-radius: 45px;

          //   within the svg
          .svg-box-small-BTN-eye {
            width: 55%;
            height: 55%;
          }
        }
        //
        //
        // will make it move a bit when hovering
        &:hover {
          transform: translateX(5%);
        }
      }
    }
  }
}
