#all-prints {
  padding-top: 100px;
  background-color: #d7e1ee;
  // background-color: #1566d0;
  //
  border-top: 3px solid #000;
  border-left: 5px solid #000;
  border-right: 5px solid #000;
  //
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // margin: 0 auto;
  // overflow: hidden;
  width: 100%;
  height: 100%;

  //
  //
  //
  //
  .wrapper-btn-funky {
    //
    .container-btn-funky {
      filter: drop-shadow(5px 15px 7px rgb(35, 35, 35));

      //
      button {
        border-radius: 30px;
        padding: 16px 36px;
        background-color: transparent;
        // border: 1px solid #111;
        border: 0 transparent;
        font-weight: 600;
        font-size: calc(12px + 1.1vmin);
        letter-spacing: -1px;
        //
        //
        position: relative;
        .overlayBtn {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 888;
        }
      }
    }
  }
}
