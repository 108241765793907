.wrapperImg__CircleComp {
  width: 100%;
  min-height: 200vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    height: auto;
    padding: 20px 0;
    min-height: 10vh;
  }
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    height: auto;
    padding: 20px 0;
    min-height: 10vh;
  }
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    height: auto;
    padding: 20px 0;
    min-height: 30vh;
  }
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    height: auto;
    padding: 20px 0;
    min-height: 30vh;
  }
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    height: auto;
    padding: 20px 0;
    min-height: 40vh;
  }

  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    height: auto;
    padding: 20px 0;
    min-height: 40vh;
  }

  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    padding: 20px 0;
    min-height: 30vh;
  }

  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    padding: 20px 0;
    min-height: 30vh;
  }

  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    padding: 20px 0;
    min-height: 30vh;
  }

  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    padding: 20px 0;
    min-height: 30vh;
  }

  /*





*/
  //--------
  // **  white section
  //--------

  .container-circleImg {
    width: 100%;
    height: 100%;

    //
    //
    .containerImg__CircleComp {
      padding: 100px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      width: 70%;
      height: 70%;

      .img-box__CircleComp {
        border: 3px solid #282828;
        // transform: skewY(-10deg);
        // background-color: plum;
        // will-change: "width, transform";
        // transition: 1s all ease-in-out;
        border-radius: 100%;
        height: 100%;
        width: 100%;
        // height: 100%;
        overflow: hidden;
        //
        //
        img {
          border-top-right-radius: 50px;
          border-top-left-radius: 50px;
          border-bottom-left-radius: 30px;
          border-bottom-right-radius: 30px;
          border-radius: 100%;
          //
          display: block;
          width: 100%;
          min-height: auto;
          object-fit: cover;
          // aspect-ratio: 1.2/1;
          // --------
        }
      }
    }
  }
}
