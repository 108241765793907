.wrapper-HeadlineCollectionOption2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .container-HeadlineCollectionOption2 {
    h1 {
      width: 100%;
      font-family: "Matao-Regular";
      font-family: "Kenoky-Light";
      font-style: normal;
      color: #040404;
      font-weight: 500;
      letter-spacing: 1px;

      font-size: calc(130px + 1.1vmin);
      line-height: calc(125px + 1.1vmin);
      //
      // center
      text-align: center;
      align-self: center;
      margin: 0 auto;

      //👾
      // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        font-size: calc(28px + 1.1vmin);
        line-height: calc(35px + 1.1vmin);
      }
      // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        font-size: calc(40px + 1.1vmin);
        line-height: calc(45px + 1.1vmin);
      }

      // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        font-size: calc(52px + 1.1vmin);
        line-height: calc(45px + 1.1vmin);
      }
      //
      // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        font-size: calc(58px + 1.1vmin);
        line-height: calc(45px + 1.1vmin);
      }

      // TABLET2 - PORTRAIT 994 x 1367
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        font-size: calc(70px + 1.1vmin);
        line-height: calc(95px + 1.1vmin);
      }

      //   TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        font-size: calc(70px + 1.1vmin);
        line-height: calc(95px + 1.1vmin);
      }
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        font-size: calc(110px + 1.1vmin);
        line-height: calc(115px + 1.1vmin);
      }
      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        font-size: calc(110px + 1.1vmin);
        line-height: calc(115px + 1.1vmin);
      }

      // 💚 DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        font-size: calc(130px + 1.1vmin);
        line-height: calc(135px + 1.1vmin);
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        font-size: calc(130px + 1.1vmin);
        line-height: calc(135px + 1.1vmin);
      }
    }

    h2 {
      color: #040404;
      margin: 0 auto;
      text-align: center;

      font-style: normal;
      font-family: "Fjord One", serif;

      font-weight: 400;
      font-size: calc(35px + 1vmin);
      // color: #3b3b3b90;

      //
      // * SPAN
      .underline_H2-pattern_HeadlineCollectionOption2 {
        font-weight: 500;
        font-style: oblique;
        font-family: "Fjord One", serif;
        color: #040404;

        font-size: calc(40px + 1vmin);
        margin: 0 15px;

        // **  -------- BORDER
        // border under text (no animation
        border-bottom: solid #040404;
        border-bottom-width: 0.05em;
        // wavy (option)
        // text-decoration-line: underline;
        // text-decoration-style: wavy;
      }

      //
      // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        font-size: calc(25px + 1.1vmin);
        line-height: calc(20px + 1.1vmin);

        // * SPAN
        .underline_H2-pattern_HeadlineCollectionOption2 {
          font-size: calc(23px + 1.1vmin);
          margin: 0 5px;
        }
      }
      // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        font-size: calc(28px + 1.1vmin);
        line-height: calc(20px + 1.1vmin);

        // * SPAN
        .underline_H2-pattern_HeadlineCollectionOption2 {
          font-size: calc(26px + 1.1vmin);
          margin: 0 5px;
        }
      }

      // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        font-size: calc(15px + 1.1vmin);
        line-height: calc(30px + 1.1vmin);

        // * SPAN
        .underline_H2-pattern_HeadlineCollectionOption2 {
          font-size: calc(15px + 1vmin);
          margin: 0 5px;
          border-bottom-width: 0.08em;
        }
      }
      //
      // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        font-size: calc(20px + 1.1vmin);
        line-height: calc(30px + 1.1vmin);

        // * SPAN
        .underline_H2-pattern_HeadlineCollectionOption2 {
          font-size: calc(20px + 1vmin);
          margin: 0 5px;
          border-bottom-width: 0.08em;
        }
      }

      // TABLET2 - PORTRAIT 994 x 1367
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        font-size: calc(20px + 1.1vmin);
        line-height: calc(30px + 1.1vmin);

        // * SPAN
        .underline_H2-pattern_HeadlineCollectionOption2 {
          font-size: calc(22px + 1vmin);
          margin: 0 5px;
          border-bottom-width: 0.08em;
        }
      }

      //   TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        font-size: calc(24px + 1.1vmin);
        line-height: calc(30px + 1.1vmin);

        // * SPAN
        .underline_H2-pattern_HeadlineCollectionOption2 {
          font-size: calc(26px + 1vmin);
          margin: 0 5px;
          border-bottom-width: 0.08em;
        }
      }
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        font-size: calc(35px + 1.1vmin);
        line-height: calc(30px + 1.1vmin);
        // * SPAN
        .underline_H2-pattern_HeadlineCollectionOption2 {
          font-size: calc(32px + 1vmin);
          margin: 0 5px;
        }
      }
      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        font-size: calc(35px + 1.1vmin);
        line-height: calc(30px + 1.1vmin);

        // * SPAN
        .underline_H2-pattern_HeadlineCollectionOption2 {
          font-size: calc(32px + 1vmin);
          margin: 0 5px;
        }
      }

      // 💚 DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        font-size: calc(35px + 1.1vmin);
        line-height: calc(30px + 1.1vmin);

        // * SPAN
        .underline_H2-pattern_HeadlineCollectionOption2 {
          font-size: calc(32px + 1vmin);
          margin: 0 5px;
        }
      }
      // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        font-size: calc(35px + 1.1vmin);
        line-height: calc(30px + 1.1vmin);

        // * SPAN
        .underline_H2-pattern_HeadlineCollectionOption2 {
          font-size: calc(32px + 1vmin);
          margin: 0 5px;
        }
      }
    }
  }
}
