// ** div wrapping some components
// Desktop and UP
#container-components {
  // background-color: lightblue;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  //
  width: 85%;
  height: 100%;
  margin: 0 auto;
  // -------- **
  // -------- **
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    background-color: rgb(193, 220, 217);
    width: 98%;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    background-color: rgb(0, 0, 255);
    width: 95%;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    width: 87%;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: rgb(204, 144, 32);
    width: 80%;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT 994 x 1367 (on google) or (1024 x 600 [ Desktop/Laptop ])  http://responsivetesttool.com/?url=http://localhost:6255/
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: teal;
    width: 80%;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: rgb(255, 225, 0);
    width: 80%;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT  ( row , desktop > )
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: purple;
    width: 90%;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
    width: 90%;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    width: 97%;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: rgb(9, 87, 230);
    width: 97%;
  }
  //-------------**
  //-------------**
  //-------------**
  //-------------**
  //
  //
}
