@mixin Headline_H1_Welcome_and_The {
  /*
  🟧 DANAE page font STYLES

          ((H1))
          WELCOME

*/

  // large text WELCOME
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    font-size: calc(35px + 1.1vmin);
    line-height: calc(35px + 1.1vmin);
    letter-spacing: -4px; // this device is ahǵosta/narrow compared to the mobile LANDScape which is wider/ancho
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: purple;
    font-size: calc(35px + 1.1vmin);
    line-height: calc(35px + 1.1vmin);
    letter-spacing: -5px;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    font-size: calc(80px + 1.1vmin);
    line-height: calc(85px + 1.1vmin);
    letter-spacing: -7px;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: orange;
    font-size: calc(75px + 1.1vmin);
    line-height: calc(75px + 1.1vmin);
    letter-spacing: -7px;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: olivedrab;
    // color: teal;
    font-size: calc(90px + 1.1vmin);
    line-height: calc(80px + 1.1vmin);
    letter-spacing: 1px;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: pink;
    // color: fuchsia;
    font-size: calc(90px + 1.1vmin);
    line-height: calc(80px + 1.1vmin);
    letter-spacing: -1px;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // color: purple;
    // background-color: rgb(238, 217, 238);
    font-size: calc(140px + 1.1vmin);
    line-height: calc(150px + 1.1vmin);
    letter-spacing: -1px;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // color: rgb(0, 128, 255);
    font-size: calc(100px + 1.1vmin);
    line-height: calc(115px + 1.1vmin);
    letter-spacing: -1px;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    font-size: calc(150px + 1.1vmin);
    line-height: calc(170px + 1.1vmin);
    letter-spacing: -10px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    font-size: calc(140px + 1.1vmin);
    line-height: calc(140px + 1.1vmin);
    letter-spacing: -10px;
  }
  //-------------**
  //-------------**
}

/*


          THE FURIES

*/

@mixin Headline_H1_ToMEYOKO_and_Furies {
  // ((H1))
  // ** lARGE text FURIES
  //
  //
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    font-size: calc(35px + 1.1vmin);
    line-height: calc(35px + 1.1vmin);
    letter-spacing: -4px;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: rgb(209, 166, 215);
    font-size: calc(35px + 1.1vmin);
    line-height: calc(35px + 1.1vmin);
    letter-spacing: -5px;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    font-size: calc(80px + 1.1vmin);
    line-height: calc(85px + 1.1vmin);
    letter-spacing: -5px;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: orange;
    font-size: calc(75px + 1.1vmin);
    line-height: calc(75px + 1.1vmin);
    letter-spacing: -7px;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: olive;
    // color: teal;
    font-size: calc(90px + 1.1vmin);
    line-height: calc(80px + 1.1vmin);
    letter-spacing: -2px;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: pink;
    // color: fuchsia;
    font-size: calc(90px + 1.1vmin);
    line-height: calc(100px + 1.1vmin);
    letter-spacing: -1px;
  }
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // color: purple;
    // background-color: rgb(238, 217, 238);
    font-size: calc(140px + 1.1vmin);
    line-height: calc(150px + 1.1vmin);
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // color: rgb(0, 128, 255);
    font-size: calc(100px + 1.1vmin);
    line-height: calc(115px + 1.1vmin);
    letter-spacing: -1px;
  }
  //-------------**
  //-------------**
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // color: green;
    // background-color: green;
    font-size: calc(150px + 1.1vmin);
    line-height: calc(170px + 1.1vmin);
    letter-spacing: -1px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // color: red;
    font-size: calc(140px + 1.1vmin);
    line-height: calc(140px + 1.1vmin);
    letter-spacing: -10px;
  }
  //-------------**
  //-------------**
}

/*






RIGHT side or the small texts





                  ((H1))



*/

@mixin Headline_H1_Welcome__titleOfTheCollection {
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    margin-top: 10px;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(10px + 1.1vmin);
    align-self: flex-start;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: rgb(209, 166, 215);
    margin-top: 10px;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(10px + 1.1vmin);
    align-self: flex-start;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    margin-top: 10px;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: flex-start;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: orange;
    margin-top: 10px;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: flex-start;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: olive;
    // color: teal;

    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);

    align-self: end;
  }
  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: pink;
    // color: fuchsia;

    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT (1650 x 1650) on google , add the values 1650 there
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // color: purple;
    // background-color: rgb(155, 155, 92);

    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }

  // DESKTOP - LANDSCAPE (1440 x 900 ) http://responsivetesttool.com/?url=http://localhost:7455/
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: hotpink;
    // color: rgb(0, 128, 255);

    font-size: calc(8px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // color: green;
    // background-color: rgb(172, 216, 172);

    font-size: calc(9px + 1.1vmin);
    line-height: calc(7px + 1.1vmin);
    align-self: end;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: saddlebrown;
    // color: red;
    font-size: calc(9px + 1.1vmin);
    line-height: calc(7px + 1.1vmin);
    align-self: end;
  }
}

/*


            ((H2))


01.01.24
*/

@mixin Headline_H2_Welcome__Collection {
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    font-size: calc(8px + 1.1vmin);
    line-height: calc(10px + 1.1vmin);
    align-self: flex-start;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: rgb(209, 166, 215);
    font-size: calc(8px + 1.1vmin);
    line-height: calc(10px + 1.1vmin);
    align-self: flex-start;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;

    font-size: calc(8px + 1.1vmin);
    line-height: calc(10px + 1.1vmin);
    align-self: flex-start;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: orange;

    font-size: calc(10px + 1.1vmin);
    line-height: calc(10px + 1.1vmin);
    align-self: flex-start;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: olive;
    // color: teal;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(10px + 1.1vmin);
    align-self: end;
  }
  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: pink;
    // color: fuchsia;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(10px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT (1650 x 1650) on google , add the values 1650 there
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // color: purple;
    // background-color: rgb(155, 155, 92);
    font-size: calc(8px + 1.1vmin);
    line-height: calc(12px + 1.1vmin);
    align-self: end;
  }

  // DESKTOP - LANDSCAPE (1440 x 900 ) http://responsivetesttool.com/?url=http://localhost:7455/
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: hotpink;
    // color: rgb(0, 128, 255);
    font-size: calc(8px + 1.1vmin);
    line-height: calc(12px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // color: green;
    // background-color: rgb(172, 216, 172);
    font-size: calc(9px + 1.1vmin);
    line-height: calc(12px + 1.1vmin);
    align-self: end;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: saddlebrown;
    // color: red;
    font-size: calc(9px + 1.1vmin);
    line-height: calc(12px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
}
/*


            ((H3))


01.01.24
*/

@mixin Headline_H3_Welcome__date {
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    margin: 5px 0;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: flex-start;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: rgb(209, 166, 215);
    margin: 5px 0;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: flex-start;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 360 (mobile)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    margin: 5px 0;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: flex-start;
  }
  // TABLET1 - LANDSCAPE 768 x 360 (mobile)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: orange;
    margin: 5px 0;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: flex-start;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: olive;
    // color: teal;
    margin: 5px 0;
    font-size: calc(6px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: pink;
    // color: fuchsia;
    margin: 5px 0;
    font-size: calc(6px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT (1650 x 1650) on google , add the values 1650 there
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // color: purple;
    // background-color: rgb(155, 155, 92);
    margin: 7px 0;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }

  // DESKTOP - LANDSCAPE (1440 x 900 ) http://responsivetesttool.com/?url=http://localhost:7455/
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: hotpink;
    // color: rgb(0, 128, 255);
    margin: 7px 0;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // color: green;
    // background-color: rgb(172, 216, 172);
    margin: 10px 0;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: saddlebrown;
    // color: red;
    margin: 10px 0;
    font-size: calc(7px + 1.1vmin);
    line-height: calc(15px + 1.1vmin);
    align-self: end;
  }
  //-------------**
  //-------------**
}
