@mixin mediaQueries___container__drawingScreen {
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    width: 100%;
    flex-direction: column;
    &:nth-child(even) {
      flex-direction: column;
    }
  }

  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    width: 100%;
    flex-direction: column;
    &:nth-child(even) {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    width: 85%;
    flex-direction: column;
    &:nth-child(even) {
      flex-direction: column;
    }
  }

  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    width: 85%;
    flex-direction: column;
    &:nth-child(even) {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    flex-direction: column;
    &:nth-child(even) {
      flex-direction: column;
    }
  }

  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    flex-direction: column;
    &:nth-child(even) {
      flex-direction: column;
    }
  }

  // ----------------
  //     desktop
  // ----------------
  //
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    width: 100%;
    padding: 40px 0 0 0;
    display: flex;
    margin: 0 auto;

    &:nth-child(odd) {
      flex-direction: row;
    }
  }

  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    width: 100%;
    padding: 40px 0 0 0;
    display: flex;
    margin: 0 auto;
    &:nth-child(odd) {
      flex-direction: row;
    }
  }

  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    width: 100%;
    padding: 50px 0 0 0;
    display: flex;
    margin: 0 auto;
    &:nth-child(odd) {
      flex-direction: row;
    }
  }

  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    width: 100%;
    padding: 50px 0 0 0;
    display: flex;
    margin: 0 auto;
    &:nth-child(odd) {
      flex-direction: row;
    }
  }
}

@mixin mediaQueries___fullImg__drawingScreen {
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    padding: 100px 20px 20px 20px;
    border-right: 0;
  }

  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    padding: 100px 20px 20px 20px;
    border-right: 0;
  }

  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    padding: 100px 20px 20px 20px;
    border-right: 0;
  }

  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    padding: 100px 20px 20px 20px;
    border-right: 0;
  }

  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    padding: 100px 20px 20px 20px;
  }

  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    padding: 100px 20px 20px 20px;
  }

  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    border-right: 2px solid #000;
    padding: 20px 20px 20px 20px;
    margin: 10px 0;
    height: 100vh;
    min-height: 130vh;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: sticky;
    position: -webkit-sticky;
    top: 0%;

    overflow: hidden;
    overflow-y: scroll;
    //
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    border-right: 2px solid #000;
    padding: 20px 20px 20px 20px;

    height: 100vh;
    min-height: 130vh;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: sticky;
    position: -webkit-sticky;
    top: 0%;

    overflow: hidden;
    overflow-y: scroll;
    //
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    border-right: 2px solid #000;
    height: 100vh;
    min-height: 130vh;
    padding: 20px 20px 20px 20px;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: sticky;
    position: -webkit-sticky;
    top: 0%;

    overflow: hidden;
    overflow-y: scroll;
    //
    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    padding: 20px 20px 20px 20px;
    border-right: 2px solid #000;
    height: 100vh;
    min-height: 130vh;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: sticky;
    position: -webkit-sticky;
    top: 0%;

    overflow: hidden;
    overflow-y: scroll;
    //
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@mixin imgs___DrawingScreen {
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    width: 85%;
  }
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    width: 80%;
  }
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    width: 80%;
  }

  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    width: 80%;
  }

  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    width: 80%;
  }

  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    width: 80%;
  }

  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    width: 100%;
  }

  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    width: 100%;
  }

  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    width: 100%;
  }

  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    width: 100%;
  }
}

@mixin mediaQueries__container_TEXT-drawingScreen {
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    padding: 20px 0px 10px 0px;
  }

  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    padding: 20px 0px 10px 0px;
  }

  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    padding: 20px 0px 10px 0px;
  }

  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    padding: 20px 0px 10px 0px;
  }

  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    padding: 20px 0px 10px 0px;
  }

  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
  }

  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    padding: 20px 40px 10px 30px;
  }

  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    padding: 20px 40px 10px 30px;
  }

  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    padding: 20px 40px 10px 40px;
  }

  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    padding: 20px 40px 10px 40px;
  }
}

@mixin Headline_H1__DrawingTitle_DrawingScreen {
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    font-weight: 600;
    letter-spacing: -1px;
    font-size: calc(40px + 1.1vmin);
    line-height: calc(34px + 1.1vmin);

    text-align: left;
    align-self: flex-start;
  }

  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    text-align: left;
    align-self: flex-start;
    font-weight: 600;
    letter-spacing: -0.2px;
    font-size: calc(55px + 1.1vmin);
    line-height: calc(60px + 1.1vmin);
  }

  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    text-align: left;
    align-self: flex-start;
    font-weight: 600;
    letter-spacing: -0.2px;
    font-size: calc(60px + 1.1vmin);
    line-height: calc(60px + 1.1vmin);
  }

  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    text-align: left;
    align-self: flex-start;
    font-weight: 600;
    font-size: calc(60px + 1.1vmin);
    line-height: calc(65px + 1.1vmin);
  }

  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    text-align: left;
    align-self: flex-start;
    font-weight: 600;
    letter-spacing: -0.2px;
    font-size: calc(60px + 1.1vmin);
    line-height: calc(60px + 1.1vmin);
  }

  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    font-weight: 600;
    letter-spacing: -0.2px;
    font-size: calc(60px + 1.1vmin);
    line-height: calc(60px + 1.1vmin);
  }
  // **
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    font-weight: 600;
    text-align: left;
    align-self: flex-start;
    letter-spacing: -0.2px;
    font-size: calc(70px + 1.1vmin);
    line-height: calc(95px + 1.1vmin);
  }

  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    text-align: left;
    align-self: flex-start;
    font-weight: 600;
    letter-spacing: -0.2px;
    font-size: calc(60px + 1.1vmin);
    line-height: calc(70px + 1.1vmin);
  }

  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    text-align: left;
    align-self: flex-start;
    font-weight: 600;
    letter-spacing: -0.2px;
    font-size: calc(70px + 1.1vmin);
    line-height: calc(95px + 1.1vmin);
    line-height: calc(95px + 1.1vmin);
  }

  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    text-align: left;
    align-self: flex-start;
    font-weight: 600;
    letter-spacing: -0.2px;
    font-size: calc(70px + 1.1vmin);
    line-height: calc(95px + 1.1vmin);
  }
}

@mixin Headline_H2__DrawingTitle_DrawingScreen {
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    font-size: calc(11px + 1.1vmin);
    text-align: left;
    align-self: flex-start;

    display: flex;
    flex-direction: row;
    span {
      margin: 0 5px;
    }
  }

  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    font-size: calc(11px + 1.1vmin);
    text-align: left;
    align-self: flex-start;

    display: flex;
    flex-direction: row;
    span {
      margin: 0 5px;
    }
  }

  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    font-size: calc(11px + 1.1vmin);
    text-align: left;
    align-self: flex-start;

    display: flex;
    flex-direction: row;
    span {
      margin: 0 5px;
    }
  }

  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    font-size: calc(11px + 1.1vmin);
    text-align: left;
    align-self: flex-start;

    display: flex;
    flex-direction: row;
    span {
      margin: 0 5px;
    }
  }

  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    font-size: calc(11px + 1.1vmin);
    text-align: left;
    align-self: flex-start;

    display: flex;
    flex-direction: row;
    span {
      margin: 0 5px;
    }
  }

  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    font-size: calc(11px + 1.1vmin);
    text-align: left;
    align-self: flex-start;

    display: flex;
    flex-direction: row;
    span {
      margin: 0 5px;
    }
  }

  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    font-size: calc(12px + 1.1vmin);
    text-align: left;
    align-self: flex-start;

    display: flex;
    flex-direction: row;
    span {
      margin: 0 5px;
    }
  }

  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    font-size: calc(12px + 1.1vmin);
    text-align: left;
    align-self: flex-start;

    display: flex;
    flex-direction: row;
    span {
      margin: 0 5px;
    }
  }

  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    font-size: calc(14px + 1.1vmin);
    text-align: left;
    align-self: flex-start;

    display: flex;
    flex-direction: row;
    span {
      margin: 0 5px;
    }
  }

  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    font-size: calc(14px + 1.1vmin);

    text-align: left;
    align-self: flex-start;

    display: flex;
    flex-direction: row;
    span {
      margin: 0 5px;
    }
  }
}

@mixin Headline_H3__DrawingTitle_DrawingScreen {
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    text-align: left;
    align-self: flex-start;
    letter-spacing: -0.2px;
    font-size: calc(8px + 1.1vmin);
  }

  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    text-align: left;
    align-self: flex-start;
    letter-spacing: -0.2px;
    font-size: calc(8px + 1.1vmin);
  }

  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    text-align: left;
    align-self: flex-start;
    letter-spacing: -0.2px;
    font-size: calc(7px + 1.1vmin);
  }

  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    text-align: left;
    align-self: flex-start;
    letter-spacing: -0.2px;
    font-size: calc(8px + 1.1vmin);
  }

  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    text-align: left;
    align-self: flex-start;
    letter-spacing: -0.2px;
    font-size: calc(8px + 1.1vmin);
  }

  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    text-align: left;
    align-self: flex-start;
    letter-spacing: -0.2px;
    font-size: calc(7px + 1.1vmin);
  }

  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    padding-top: 5px;
    align-self: center;
    letter-spacing: 1px;
    font-size: calc(7px + 1.1vmin);
  }

  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    align-self: flex-start;
    padding-top: 5px;
    letter-spacing: 1px;
    font-size: calc(7px + 1.1vmin);
  }

  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    align-self: flex-start;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: calc(8px + 1.1vmin);
  }

  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    align-self: flex-start;
    font-weight: 600;
    letter-spacing: 1px;
    font-size: calc(8px + 1.1vmin);
  }
}

@mixin Headline_P__DrawingTitle_DrawingScreen {
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    letter-spacing: 1px;
    font-size: calc(13px + 1.1vmin);
    line-height: calc(23px + 1.1vmin);
    padding: 10px;
  }

  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    letter-spacing: 1px;
    font-size: calc(13px + 1.1vmin);
    line-height: calc(23px + 1.1vmin);
    padding: 10px;
  }

  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    letter-spacing: 1px;
    font-size: calc(13px + 1.1vmin);
    line-height: calc(23px + 1.1vmin);
    padding: 20px 50px 10px 50px;
  }

  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    letter-spacing: 1px;
    font-size: calc(13px + 1.1vmin);
    line-height: calc(23px + 1.1vmin);
    padding: 20px 50px 10px 50px;
  }
  //-------------**
  //-------------**

  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    letter-spacing: 1px;
    padding: 20px 100px 10px 100px;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
    font-weight: 200;
  }

  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    padding: 20px 80px 10px 80px;
    letter-spacing: 1px;
    font-size: calc(12px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
    font-weight: 200;
  }

  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    margin: 2px 0;
    font-size: calc(9px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    max-width: 640px;
    padding: 10px 20px 10px 0px;
  }

  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    margin: 2px 0;
    font-size: calc(9px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
    max-width: 640px;
    padding: 10px 20px 10px 0px;
  }

  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    margin: 2px 0;
    font-size: calc(11px + 1.1vmin);
    line-height: calc(14px + 1.1vmin);
    max-width: 780px;
    padding: 10px 20px 10px 0px;
  }

  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    margin: 2px 0;
    letter-spacing: -0.1px;
    font-size: calc(14px + 1.1vmin);
    line-height: calc(24px + 1.1vmin);
    max-width: 780px;
    padding: 10px 20px 10px 0px;
  }
}

@mixin LI__listGroupTags__DrawingTitle_DrawingScreen {
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    font-weight: 400;
    letter-spacing: 1px;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(2px + 1.1vmin);
  }

  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    font-weight: 400;
    letter-spacing: 1px;
    font-size: calc(8px + 1.1vmin);
    line-height: calc(2px + 1.1vmin);
  }

  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
  }

  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
  }

  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
  }

  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    font-size: calc(6px + 1.1vmin);
    line-height: calc(2px + 1.1vmin);
  }

  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    font-size: calc(6px + 1.1vmin);
    line-height: calc(2px + 1.1vmin);
  }

  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    font-weight: 400;
    letter-spacing: 1px;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(2px + 1.1vmin);
  }

  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    font-weight: 400;
    letter-spacing: 1px;
    font-size: calc(5px + 1.1vmin);
    line-height: calc(2px + 1.1vmin);
  }
}
