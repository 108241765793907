#wrapper-imgTaro {
  // background-color: pink;

  width: 100%;
  height: auto;
  min-height: auto;

  //   border: 1px solid #000;
  //
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  //   ----------
  .headlineH1Box-Bouncing {
    // background-color: honeydew;
    overflow: hidden;

    // ** H1  text
    .headlineh1_Taro {
      padding: 50px 0 25px 0;

      font-family: "Wagon-Bold";
      font-family: "Wagon-ExtraLight";
      font-family: "Casta-Thin";
      font-family: "Kenoky-Light";

      font-size: calc(300px + 1.1vmin);
      color: #0c0c0c;

      // -------- **
      // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        padding: 100px 0 25px 0;
        font-size: calc(70px + 1.1vmin);
      }
      // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        padding: 100px 0 25px 0;
        font-size: calc(70px + 1.1vmin);
      }
      // ------
      // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        padding: 70px 0 25px 0;
        font-size: calc(90px + 1.1vmin);
      }
      //
      // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        padding: 70px 0 25px 0;
        font-size: calc(90px + 1.1vmin);
      }

      //-------------**
      // TABLET2 - PORTRAIT
      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        padding: 100px 0 25px 0;
        font-size: calc(90px + 1.1vmin);
      }

      // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        padding: 100px 0 25px 0;
        font-size: calc(90px + 1.1vmin);
      }
      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        padding: 70px 0 25px 0;
        font-size: calc(70px + 1.1vmin);
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        padding: 100px 0 25px 0;
        font-size: calc(150px + 1.1vmin);
      }

      // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        padding: 70px 0 25px 0;
        font-size: calc(180px + 1.1vmin);
      }
      // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        padding: 70px 0 25px 0;
        font-size: calc(200px + 1.1vmin);
      }
    }
  }
  //
  //
  //
  //
  .container-imgTaro {
    width: 100%;

    border-top: 2px solid rgb(14, 14, 14);
    border-bottom: 3px solid rgb(14, 14, 14);
    //
    //
    //
    // ** 1) GRID
    .wrapper-grid-imgTaro {
      border-bottom: 3px solid rgb(14, 14, 14);
      width: 100%;
      height: 100%;
      //   background-color: goldenrod;
      .container-grid-imgTaro {
        width: 100%;
        height: 100%;
        // background-color: lavender;
        display: grid;
        grid-template-columns: 0.2fr 1.2fr 0.2fr;
        gap: 0px;

        //
        //

        // -------- **
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          grid-template-columns: 0.1fr 1.2fr 0.1fr;
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          grid-template-columns: 0.1fr 1.2fr 0.1fr;
        }
        // ------
        // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        }
        //
        // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        }

        //-------------**
        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        }
        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          grid-template-columns: 0.3fr 1.2fr 0.3fr;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          grid-template-columns: 0.3fr 1.2fr 0.3fr;
        }

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          grid-template-columns: 0.3fr 1.2fr 0.3fr;
        }
        // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          grid-template-columns: 0.3fr 1.2fr 0.3fr;
        }
        //

        // ------
        //
        .left_imgTaro,
        .right_imgTaro {
          width: 100%;
          height: 100%;
          align-self: flex-end;
          .container-star {
            width: 100%;
            height: 100%;
          }
          //   background-color: rgb(245, 237, 227);
        }

        //
        //  ------ 🌟
        //
        .left_imgTaro {
          .container-star {
            position: relative;

            //
            //   --------
            // ** OVERLAY
            //   --------
            //
            .overlay-left-imgTar {
              justify-content: center;
              align-items: center;
              background-color: rgb(245, 237, 227);
              // ----------------- 🔴
              // it wont be shown on higher devices, it will only be shown on the media queries below
              display: none;
              // ----------------- 🔴

              justify-content: center;
              align-items: center;

              border: 1px solid #000;
              border-radius: 50%;
              // border-radius: 12px 32px; // petal shape
              //  border-radius: 50%; // round
              //   border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; // egg shape
              overflow: hidden;
              // -------- **
              //
              //
              // ** MEDIA queries
              //
              // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
              @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                display: flex;

                position: absolute;
                top: 6%;
                left: 55%;
                transform: translateY(-6%);
                transform: translateX(-10%);

                width: 30px;
                height: 80px;
                border-top-left-radius: 150px;
                border-top-right-radius: 150px;
                border-bottom-left-radius: 150px;
                border-bottom-right-radius: 150px;
              }
              // MOBILE - LANDSCAPE 663 x 662
              @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                display: flex;
                position: absolute;
                top: 7%;
                left: 55%;
                transform: translateY(-7%);
                transform: translateX(-10%);

                width: 42px;
                height: 120px;
                border-top-left-radius: 150px;
                border-top-right-radius: 150px;
                border-bottom-left-radius: 150px;
                border-bottom-right-radius: 150px;
              }
              // ------
              // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
              @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                display: flex;
                position: absolute;
                top: 7%;
                left: 150%;
                transform: translateY(-7%);
                transform: translateX(-150%);

                width: 42px;
                height: 135px;
                border-top-left-radius: 150px;
                border-top-right-radius: 150px;
                border-bottom-left-radius: 150px;
                border-bottom-right-radius: 150px;
              }
              //
              // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
              @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                display: flex;
                position: absolute;
                top: 7%;
                left: 55%;
                transform: translateY(-7%);
                transform: translateX(-10%);

                width: 42px;
                height: 120px;
                border-top-left-radius: 150px;
                border-top-right-radius: 150px;
                border-bottom-left-radius: 150px;
                border-bottom-right-radius: 150px;
              }

              //-------------**
              // TABLET2 - PORTRAIT
              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                display: flex;
                position: absolute;

                top: 5%;
                left: 190%;
                transform: translateY(-5%);
                transform: translateX(-190%);

                width: 72px;
                height: 180px;
                border-top-left-radius: 150px;
                border-top-right-radius: 150px;
                border-bottom-left-radius: 150px;
                border-bottom-right-radius: 150px;
              }

              // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                display: flex;
                position: absolute;

                top: 5%;
                left: 100%;
                transform: translateY(-5%);
                transform: translateX(-60%);

                width: 72px;
                height: 180px;
                border-top-left-radius: 150px;
                border-top-right-radius: 150px;
                border-bottom-left-radius: 150px;
                border-bottom-right-radius: 150px;
              }
              //-------------**
              //-------------**
              // DESKTOP - PORTRAIT
              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                display: flex;
                position: absolute;
                top: 7%;
                left: 105%;
                transform: translateY(-7%);
                transform: translateX(-75%);

                width: 82px;
                height: 250px;
                border-top-left-radius: 150px;
                border-top-right-radius: 150px;
                border-bottom-left-radius: 150px;
                border-bottom-right-radius: 150px;
              }

              // DESKTOP - LANDSCAPE
              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                display: flex;
                position: absolute;

                top: 5%;
                left: 100%;
                transform: translateY(-5%);
                transform: translateX(-60%);

                width: 72px;
                height: 150px;
                border-top-left-radius: 150px;
                border-top-right-radius: 150px;
                border-bottom-left-radius: 150px;
                border-bottom-right-radius: 150px;
              }

              // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
              @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                display: flex;
                position: absolute;
                top: 5%;
                left: 100%;
                transform: translateY(-5%);
                transform: translateX(-60%);

                width: 72px;
                height: 150px;
                border-top-left-radius: 150px;
                border-top-right-radius: 150px;
                border-bottom-left-radius: 150px;
                border-bottom-right-radius: 150px;
              }
              // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
              @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                display: flex;
                position: absolute;

                width: 92px;
                height: 152px;

                top: 7%;
                left: 120%;
                transform: translateY(-7%);
                transform: translateX(-120%);

                border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; // egg shape
              }
            }
          }
        }
        //
        //
        //
        //
        //  ------ 🌟
        //
        .right_imgTaro {
          .container-star {
            position: relative;
            //
            //   --------
            // ** OVERLAY
            //   --------
            //
            .overlay-right-imgTar {
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: rgb(245, 237, 227);

              // ----------------- 🔴
              // it wont be shown on higher devices, it will only be shown on the media queries below
              display: none;
              // ----------------- 🔴

              border: 1px solid #000;
              border-radius: 50%;
              // border-radius: 12px 32px; // petal shape
              //  border-radius: 50%; // round
              //   border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; // egg shape
              overflow: hidden;
              // -------- **
              //
              //
              // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
              @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                display: flex;
                position: absolute;
                width: 40px;
                height: 55px;

                top: 50%;
                left: 20%;
                transform: translateY(-50%);
                transform: translateX(-80%);
                border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; // egg shape
              }
              // MOBILE - LANDSCAPE 663 x 662
              @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                display: flex;
                position: absolute;
                width: 50px;
                height: 70px;

                top: 50%;
                left: 0%;
                transform: translateY(-50%);
                transform: translateX(-50%);
                border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; // egg shape
              }
              // ------
              // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
              @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                display: flex;
                position: absolute;
                width: 52px;
                height: 72px;

                top: 50%;
                left: 0%;
                transform: translateY(-50%);
                transform: translateX(-50%);
                border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; // egg shape
              }
              //
              // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
              @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                display: flex;
                position: absolute;
                width: 65px;
                height: 90px;

                top: 50%;
                left: 140%;
                transform: translateY(-50%);
                transform: translateX(-140%);
                border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; // egg shape
              }

              //-------------**
              // TABLET2 - PORTRAIT
              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                display: flex;
                position: absolute;

                width: 65px;
                height: 90px;

                top: 50%;
                left: -15%;
                transform: translateY(-50%);
                transform: translateX(-15%);
                border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; // egg shape
              }

              // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                display: flex;
                position: absolute;

                top: 55%;
                left: -14%;
                transform: translateY(-55%);
                transform: translateX(-14%);

                width: 80px;
                height: 100px;
                border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
              }
              //-------------**
              //-------------**
              // DESKTOP - PORTRAIT
              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                display: flex;
                position: absolute;

                top: 55%;
                left: -14%;
                transform: translateY(-55%);
                transform: translateX(-14%);

                width: 120px;
                height: 160px;
                border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
              }

              // DESKTOP - LANDSCAPE
              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                display: flex;
                position: absolute;
                top: 55%;
                left: -14%;
                transform: translateY(-55%);
                transform: translateX(-14%);

                width: 80px;
                height: 120px;
                border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
              }

              // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
              @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                display: flex;
                position: absolute;

                width: 100px;
                height: 120px;

                top: 60%;
                left: -18%;
                transform: translateY(-60%);
                transform: translateX(-10%);

                border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; // egg shape
              }
              // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
              @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                display: flex;
                position: absolute;

                width: 100px;
                height: 120px;

                top: 60%;
                left: -18%;
                transform: translateY(-60%);
                transform: translateX(-10%);

                border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; // egg shape
              }
            }
          }
        }
        //
        //
        //
        //
        //
        // ** ------ img
        .wrapper-imgBox-imgTar {
          border-left: 3px solid #000;
          border-right: 3px solid #000;

          display: flex;
          justify-content: center;
          align-items: center;
          padding: 120px 30px;
          //
          //
          //
          // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            padding: 30px 10px;
          }
          // MOBILE - LANDSCAPE 663 x 662
          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            padding: 50px 30px;
          }
          //
          //
          //

          .container-imgBox-imgTar {
            margin: 0 auto;

            width: 80%;

            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: khaki;

            border-top-left-radius: 1800px;
            border-top-right-radius: 1800px;
            border-bottom-left-radius: 1500px;
            border-bottom-right-radius: 1500px;
            //

            // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              width: 100%;
            }
            // MOBILE - LANDSCAPE 663 x 662
            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              width: 100%;
            }
            // ------
            // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              width: 100%;
            }
            //
            // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              width: 100%;
            }

            //-------------**
            // TABLET2 - PORTRAIT
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              width: 100%;
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              width: 100%;
            }
            //-------------**
            //-------------**
            // DESKTOP - PORTRAIT
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            }

            // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            }
            // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            }

            //
            position: relative;
            //
            //
            //   --------
            // ** OVERLAY
            //   --------
            // title
            .overlay-middle-imgTar {
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              //
              background-color: rgb(245, 237, 227);
              //   background-color: rgb(207, 207, 207);

              position: absolute;
              bottom: -2%;
              left: 50%;
              transform: translateY(-2%);
              transform: translateX(-50%);
              z-index: 777;
              //   btn

              border: 2px solid #000;
              border-radius: 30px;
              // border-radius: 12px 32px; // petal shape
              //  border-radius: 50%; // round
              //   border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%; // egg shape
              min-width: 280px;
              max-width: 280px;
              width: 280px;
              padding: 16px 32px;
              button {
                cursor: pointer;
                color: #000;
                background-color: transparent;
                border: 0;
                //
                font-family: "Kenoky-Light";
                font-family: "Casta-Thin";
                font-family: "Wagon-ExtraLight";
                font-family: "Wagon-Bold";
                letter-spacing: 1px;
                text-transform: capitalize;
                //
                font-size: calc(12px + 1.1vmin);
              }
              //
              //
              // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
              @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                bottom: -2%;
                left: 50%;
                transform: translateY(-2%);
                transform: translateX(-50%);

                width: 120px;
                min-width: 120px;
                max-width: 120px;

                padding: 10px 32px;
                //   btn
                button {
                  font-size: calc(7px + 1.1vmin);
                }
              }
              // MOBILE - LANDSCAPE 663 x 662
              @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                bottom: -2%;
                left: 50%;
                transform: translateY(-2%);
                transform: translateX(-50%);

                width: 165px;
                min-width: 165px;
                max-width: 165px;
                padding: 10px 32px;
                //   btn
                button {
                  font-size: calc(7px + 1.1vmin);
                }
              }
              // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
              @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                bottom: -3%;
                left: 50%;
                transform: translateY(-3%);
                transform: translateX(-50%);

                width: 180px;
                min-width: 180px;
                max-width: 180px;
                padding: 11px 32px;
                //   btn
                button {
                  font-size: calc(7px + 1.1vmin);
                }
              }
              //
              // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
              @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                bottom: -1.5%;
                left: 50%;

                transform: translateY(-1.5%);
                transform: translateX(-50%);
                width: 200px;
                min-width: 200px;
                max-width: 200px;
                padding: 11px 32px;
                //   btn
                button {
                  font-size: calc(7px + 1.1vmin);
                }
              }
              //-------------**
              // TABLET2 - PORTRAIT
              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                bottom: -3%;
                left: 50%;
                transform: translateY(-3%);
                transform: translateX(-50%);

                width: 235px;
                min-width: 235px;
                max-width: 235px;
                padding: 11px 32px;
                //   btn
                button {
                  font-size: calc(7px + 1.1vmin);
                }
              }

              // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                bottom: -1.5%;
                left: 50%;
                transform: translateY(-1.5%);
                transform: translateX(-50%);

                width: 235px;
                min-width: 250px;
                max-width: 250px;
                padding: 13px 32px;
                //   btn
                button {
                  font-size: calc(7px + 1.1vmin);
                }
              }
              //

              //-------------**
              //-------------**
              // DESKTOP - PORTRAIT
              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                bottom: -1.5%;
                left: 50%;
                transform: translateY(-1.5%);
                transform: translateX(-50%);

                width: 235px;
                min-width: 250px;
                max-width: 250px;
                padding: 13px 32px;
                //   btn
                button {
                  font-size: calc(10px + 1.1vmin);
                }
              }

              // DESKTOP - LANDSCAPE
              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                bottom: -1.5%;
                left: 50%;
                transform: translateY(-1.5%);
                transform: translateX(-50%);

                width: 300px;
                padding: 13px 32px;
                //   btn
                button {
                  font-size: calc(10px + 1.1vmin);
                }
              }

              // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
              @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                letter-spacing: 1px;
                text-transform: capitalize;
                //

                min-width: 260px;
                max-width: 260px;
                width: 260px;
                padding: 16px 32px; //
                button {
                  font-size: calc(12px + 1.1vmin);
                }
              }
              // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
              @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                letter-spacing: 1px;
                text-transform: capitalize;
                //

                width: 220px;
                min-width: 280px;
                max-width: 280px;
                width: 280px;
                padding: 16px 32px;
                button {
                  font-size: calc(12px + 1.1vmin);
                }
              }
            }
            //
            //
            // overflow: hidden;
            //------------
            //------------
            .imgBox-imgTar {
              overflow: hidden;

              //
              width: 70%;
              padding: 30px;
              border: 2px solid #000;
              background-color: transparent;
              border-top-left-radius: 1800px;
              border-top-right-radius: 1800px;
              border-bottom-left-radius: 1500px;
              border-bottom-right-radius: 1500px;

              // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
              @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                padding: 5px;
              }
              // MOBILE - LANDSCAPE 663 x 662
              @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                padding: 5px;
              }
              // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
              @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                padding: 5px;
              }
              //
              // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
              @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                padding: 5px;
              }

              //-------------**
              // TABLET2 - PORTRAIT
              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                padding: 5px;
              }

              // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                padding: 5px;
              }
              //-------------**
              //-------------**
              // DESKTOP - PORTRAIT
              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                padding: 5px;
              }

              // DESKTOP - LANDSCAPE
              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                padding: 5px;
              }

              // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
              @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                padding: 10px;
              }
              // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
              @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                padding: 10px;
              }
              //
              //
              //
              //
              img {
                cursor: grabbing;
                perspective: 2000;
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                aspect-ratio: 0.59/1;

                border-top-left-radius: 1800px;
                border-top-right-radius: 1800px;
                border-bottom-left-radius: 1500px;
                border-bottom-right-radius: 1500px;
              }
            }
          }
        }
        // ------ img
      }
    }
    //
    //
    //
    //--------------
    //      text 🟠
    //--------------
    .wrapper-grid-imgLgCardTar-text {
      //
      .container-imgLgCardTar-text {
        width: 100%;
        height: 100%;
        // background-color: lavender;
        display: grid;
        grid-template-columns: 0.4fr 0.9fr 0.4fr;
        gap: 0px;
        // ------
        //
        .left_imgLgCardTar-text {
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 2px solid #000;
          //
        }
        .middle_imgLgCardTar-text {
          display: flex;
          justify-content: center;
          align-items: center;
          //   padding: 20px 50px;

          p {
            color: #0c0c0c;
            letter-spacing: 2px;
            font-weight: 500;
            text-transform: capitalize;
            font-family: "Casta-Thin";
            padding: 10px 20px;
          }

          // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            p {
              padding: 10px;
              font-size: calc(7px + 1.1vmin);
              line-height: calc(11px + 1.1vmin);
            }
          }
          // MOBILE - LANDSCAPE 663 x 662
          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            p {
              padding: 10px;
              font-size: calc(7px + 1.1vmin);
              line-height: calc(11px + 1.1vmin);
            }
          }
          // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            p {
              padding: 10px;
              font-size: calc(8px + 1.1vmin);
              line-height: calc(10.5px + 1.1vmin);
            }
          }
          //
          // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            p {
              padding: 15px;
              font-size: calc(8px + 1.1vmin);
              line-height: calc(10.5px + 1.1vmin);
            }
          }

          //-------------**
          // TABLET2 - PORTRAIT
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            p {
              padding: 15px;
              font-size: calc(8px + 1.1vmin);
              line-height: calc(10.5px + 1.1vmin);
            }
          }

          // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            p {
              padding: 15px;
              font-size: calc(9px + 1.1vmin);
              line-height: calc(13px + 1.1vmin);
            }
          }

          // DESKTOP - PORTRAIT
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            p {
              padding: 15px;
              font-size: calc(8px + 1.1vmin);
              line-height: calc(13px + 1.1vmin);
            }
          }

          // DESKTOP - LANDSCAPE
          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            p {
              padding: 15px;
              font-size: calc(8px + 1.1vmin);
              line-height: calc(13px + 1.1vmin);
            }
          }

          // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            p {
              padding: 15px;
              font-size: calc(8px + 1.1vmin);
              line-height: calc(13px + 1.1vmin);
            }
          }
          // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            p {
              padding: 15px;
              font-size: calc(8px + 1.1vmin);
              line-height: calc(13px + 1.1vmin);
            }
          }
        }

        //
        //
        //
        .right_imgLgCardTar-text {
          display: flex;
          justify-content: center;
          align-items: center;
          border-left: 2px solid #000;
          //
        }
      }
    }

    //
    //
    //
  }
}
