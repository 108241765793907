@mixin memento-page_container-description {
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);

    padding: 50px 10px 100px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: turquoise;
    padding: 50px 20px 100px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    padding: 50px 40px 100px 40px;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: rgb(204, 144, 32);
    padding: 50px 10px 100px 10px;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: rgb(162, 255, 0);
    padding: 50px 10px 100px 10px;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: rgb(211, 208, 182);
    padding: 50px 10px 100px 10px;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT  ( row , desktop > )
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: purple;
    padding: 50px 40px 200px 40px;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
    padding: 50px 40px 200px 40px;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    padding: 50px 40px 200px 40px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;
    padding: 50px 40px 200px 40px;
  }
  //-------------**
  //-------------**
}

/*


            H1💮



*/

@mixin Headline_H1_mementoPage-desc {
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    letter-spacing: 1px;
    font-size: calc(50px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
    word-spacing: 7px;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: turquoise;
    letter-spacing: 1px;
    font-size: calc(70px + 1.1vmin);
    line-height: calc(40px + 1.1vmin);
    word-spacing: 1px;
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    letter-spacing: 1px;
    font-size: calc(90px + 1.1vmin);
    line-height: calc(40px + 1.1vmin);
    word-spacing: 1px;
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: rgb(204, 144, 32);
    letter-spacing: 1px;
    font-size: calc(90px + 1.1vmin);
    line-height: calc(40px + 1.1vmin);
    word-spacing: 1px;
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: rgb(162, 255, 0);
    letter-spacing: 1px;
    font-size: calc(90px + 1.1vmin);
    line-height: calc(40px + 1.1vmin);
    word-spacing: 1px;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: rgb(211, 208, 182);
    letter-spacing: 1px;
    font-size: calc(100px + 1.1vmin);
    line-height: calc(40px + 1.1vmin);
    word-spacing: 1px;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT  ( row , desktop > )
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: purple;
    letter-spacing: 1px;
    font-size: calc(100px + 1.1vmin);
    line-height: calc(40px + 1.1vmin);
    word-spacing: 1px;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
    letter-spacing: 1px;
    font-size: calc(100px + 1.1vmin);
    line-height: calc(40px + 1.1vmin);
    word-spacing: 1px;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;
  }
  //-------------**
  //-------------**
}

/*


            H2🍁



*/

@mixin Headline_H2_mementoPage-desc {
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    padding: 20px 0px 30px 0px;
    width: 97%;
    letter-spacing: 1px;
    font-size: calc(18px + 1.1vmin);
    line-height: calc(21px + 1.1vmin);
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: turquoise;
    padding: 20px 0px 30px 0px;
    width: 97%;
    letter-spacing: 1px;
    font-size: calc(20px + 1.1vmin);
    line-height: calc(26px + 1.1vmin);
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;
    padding: 20px 0px 30px 0px;
    width: 80%;
    letter-spacing: 1px;
    font-size: calc(20px + 1.1vmin);
    line-height: calc(26px + 1.1vmin);
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: rgb(204, 144, 32);
    width: 80%;
    letter-spacing: 1px;
    font-size: calc(22px + 1.1vmin);
    line-height: calc(30px + 1.1vmin);
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: rgb(162, 255, 0);
    width: 80%;
    letter-spacing: 1px;
    font-size: calc(22px + 1.1vmin);
    line-height: calc(28px + 1.1vmin);
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: rgb(211, 208, 182);
    width: 75%;
    letter-spacing: 1px;
    font-size: calc(28px + 1.1vmin);
    line-height: calc(35px + 1.1vmin);
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT  ( row , desktop > )
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: purple;
    padding: 30px 0px 30px 0px;
    width: 75%;
    letter-spacing: 1px;
    font-size: calc(28px + 1.1vmin);
    line-height: calc(35px + 1.1vmin);
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
    padding: 30px 0px 30px 0px;
    width: 70%;
    letter-spacing: 1px;
    font-size: calc(32px + 1.1vmin);
    line-height: calc(40px + 1.1vmin);
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    padding: 30px 0px 30px 0px;
    // background-color: pink;
    width: 60%;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    padding: 30px 0px 30px 0px;
    // background-color: plum;
    width: 60%;
  }
  //-------------**
  //-------------**
}

/*


            P 🥬



*/

@mixin Headline_P_mementoPage-desc {
  // -------- **
  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    // background-color: rgb(193, 220, 217);
    width: 95%;

    color: #fffef988;
    padding: 10px 0px;

    letter-spacing: 1px;

    font-size: calc(12px + 1.1vmin);
    line-height: calc(22px + 1.1vmin);
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    // background-color: turquoise;
    width: 90%;
    padding: 10px 0px;
    letter-spacing: 1px;
    font-size: calc(12px + 1.1vmin);
    line-height: calc(22px + 1.1vmin);
  }

  // -------- **
  // -------- **

  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    // background-color: orangered;

    width: 80%;
    padding: 10px 0px;
    letter-spacing: 1px;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(18px + 1.1vmin);
  }
  //
  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    // background-color: rgb(204, 144, 32);
    width: 80%;
    padding: 10px 0px;
    letter-spacing: 1px;
    font-size: calc(12px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
  }
  //-------------**
  //-------------**
  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    // background-color: rgb(162, 255, 0);
    width: 80%;
    padding: 10px 0px;
    letter-spacing: 1px;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    // background-color: rgb(211, 208, 182);
    width: 70%;
    padding: 10px 0px;
    letter-spacing: 1px;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT  ( row , desktop > )
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    // background-color: purple;
    width: 70%;
    padding: 10px 0px;
    letter-spacing: 1px;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    // background-color: olivedrab;
    width: 60%;
    padding: 10px 0px;
    letter-spacing: 1px;
    font-size: calc(10px + 1.1vmin);
    line-height: calc(20px + 1.1vmin);
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    // background-color: pink;
    width: 60%;

    padding: 10px 0px;
    letter-spacing: 1px;
    font-size: calc(13px + 1.1vmin);
    line-height: calc(22px + 1.1vmin);
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    // background-color: plum;

    width: 60%;
    padding: 10px 0px;
    letter-spacing: 1px;
    font-size: calc(13px + 1.1vmin);
    line-height: calc(22px + 1.1vmin);
  }
  //-------------**
  //-------------**
}
