.wrapper-press {
  padding-top: 180px;
  // background-color: #212121;
  width: 100%;
  height: 100%;
  background-color: #fdfbf3;

  // -------- **
  // MOBILE - PORTRAIT
  @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
    padding-top: 140px;
  }
  // MOBILE - LANDSCAPE
  @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
    padding-top: 140px;
  }

  //-------------**
  //-------------**

  // TABLET1 - PORTRAIT
  @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
    padding-top: 140px;
  }
  // TABLET1 - LANDSCAPE
  @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
    padding-top: 140px;
  }

  // TABLET2 - PORTRAIT
  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
    padding-top: 140px;
  }

  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
    padding-top: 140px;
  }

  //-------------**
  //-------------**
  // DESKTOP - PORTRAIT
  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
    padding-top: 180px;
  }

  // DESKTOP - LANDSCAPE
  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
    padding-top: 180px;
  }
  //-------------**
  //-------------**

  // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
  @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
    padding-top: 250px;
  }
  // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
  @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
    padding-top: 250px;
  }
  //-------------**
  //
  //
  //
  .container-press {
    padding: 30px 0;

    width: 100%;
    height: 100%;
    margin: 0 auto;

    // -------- **
    // MOBILE - PORTRAIT
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      width: 92%;
    }
    // MOBILE - LANDSCAPE
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      width: 92%;
    }

    //-------------**
    //-------------**

    // TABLET1 - PORTRAIT
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      width: 85%;
    }
    // TABLET1 - LANDSCAPE
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      width: 85%;
    }

    // TABLET2 - PORTRAIT
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      width: 80%;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      width: 80%;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      width: 95%;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      width: 95%;
    }
    //-------------**
    //-------------**

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      width: 87%;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      width: 87%;
    }
    //-------------**
    //
    // ---------
    //  headline
    // ---------
    .headline-press {
      .headline-container-press {
        padding-top: 50px 0 20px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;

        overflow: hidden;

        // GALLERY
        // -------- **
        // -------- **
        // MOBILE - PORTRAIT
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
        }
        // MOBILE - LANDSCAPE
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
        }

        //-------------**
        //-------------**

        // TABLET1 - PORTRAIT
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
        }
        // TABLET1 - LANDSCAPE
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
        }

        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-end;
        }

        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-end;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-end;
        }
        //-------------**
        //-------------**

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-end;
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: flex-end;
        }
        //-------------**

        // -------- **
        //
        //
        //
        h1 {
          padding: 0;
          margin: 0;
          color: rgb(5, 5, 5);
          font-size: calc(48px + 1.1vmin);
          letter-spacing: -1px;
          font-family: "Wagon-Bold";
          text-transform: capitalize;

          font-weight: 400;
          letter-spacing: -12px;

          //
          // large text PRESS
          // -------- **
          // -------- **
          // MOBILE - PORTRAIT
          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            // background-color: rgb(193, 220, 217);
            font-size: calc(48px + 1.1vmin);
            line-height: calc(55px + 1.1vmin);
            letter-spacing: -1px;
            text-align: center;
          }
          // MOBILE - LANDSCAPE
          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            // background-color: purple;
            font-size: calc(48px + 1.1vmin);
            line-height: calc(55px + 1.1vmin);
            letter-spacing: -1px;
            text-align: center;
          }

          // -------- **
          // -------- **

          // TABLET1 - PORTRAIT
          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            // background-color: orangered;
            font-size: calc(58px + 1.1vmin);
            line-height: calc(85px + 1.1vmin);
            letter-spacing: -7px;
            text-align: center;
          }
          // TABLET1 - LANDSCAPE
          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            // background-color: orange;
            font-size: calc(58px + 1.1vmin);
            line-height: calc(75px + 1.1vmin);
            letter-spacing: -7px;
            text-align: center;
          }
          //-------------**
          //-------------**
          // TABLET2 - PORTRAIT
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            // background-color: olivedrab;
            // color: teal;
            font-size: calc(78px + 1.1vmin);
            line-height: calc(80px + 1.1vmin);
            letter-spacing: -1px;
            text-align: center;
          }

          // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            // background-color: pink;
            // color: fuchsia;
            font-size: calc(78px + 1.1vmin);
            line-height: calc(80px + 1.1vmin);
            letter-spacing: -1px;

            margin: 0 auto;
          }

          //-------------**
          //-------------**
          // DESKTOP - PORTRAIT
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            // color: purple;
            // background-color: rgb(238, 217, 238);
            font-size: calc(100px + 1.1vmin);
            line-height: calc(110px + 1.1vmin);
            letter-spacing: -1px;
            margin: 0 auto;
          }

          // DESKTOP - LANDSCAPE
          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            // color: rgb(0, 128, 255);
            font-size: calc(100px + 1.1vmin);
            line-height: calc(110px + 1.1vmin);
            letter-spacing: -1px;
            margin: 0 auto;
          }
          //-------------**
          //-------------**

          // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            font-size: calc(120px + 1.1vmin);
            line-height: calc(120px + 1.1vmin);
            letter-spacing: -5px;
            margin: 0 auto;
          }
          // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            font-size: calc(120px + 1.1vmin);
            line-height: calc(120px + 1.1vmin);
            letter-spacing: -5px;
            margin: 0 auto;
          }
          //-------------**
        }
      }
    }
    //
    //
    //
    .container-grid-press {
      border-top: 2px solid #111111;

      // background-color: seagreen;
      padding: 50px 0 300px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      //
      //
      //   UL
      .press-grid {
        // --------
        overflow: hidden;
        margin: 0 auto; // to center the whole container
        width: 100%;
        height: 100%;

        // ** GRID

        display: grid;
        grid-template-columns: 1fr 1fr 1fr; //0.6fr 1fr 0.4fr Here, you specify the columns with different widths, another option: 1fr 1fr 1fr 1fr;, will create 4 columns of the same size, but its not cool for mobile
        gap: 10px; // Adjust the gap between the boxes

        // GALLERY
        // -------- **
        // -------- **
        // MOBILE - PORTRAIT
        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          grid-template-columns: 0.8fr;
        }
        // MOBILE - LANDSCAPE
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          grid-template-columns: 0.8fr 0.3fr;
        }

        // -------- **
        // -------- **

        // TABLET1 - PORTRAIT
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          grid-template-columns: 0.3fr 0.3fr 1fr;
        }
        // TABLET1 - LANDSCAPE
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          grid-template-columns: 0.3fr 0.3fr 1fr;
        }
        //-------------**
        //-------------**
        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          grid-template-columns: 0.5fr 0.5fr 1fr;
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          grid-template-columns: 0.5fr 0.5fr 1fr;
        }

        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          grid-template-columns: 0.5fr 0.5fr 1fr;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          grid-template-columns: 0.5fr 0.5fr 1fr;
        }
        //-------------**
        //-------------**

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          grid-template-columns: 0.5fr 0.5fr 1fr;
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          grid-template-columns: 0.5fr 0.5fr 1fr;
        }
        //-------------**

        //
        //
        //
        // LI
        .container-imgPress {
          width: 100%;
          margin: 0 auto;
          overflow: hidden;

          //
          //
          .imgPress-box {
            overflow: hidden;
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              min-height: auto;
              object-fit: cover;
              aspect-ratio: 0.69/1;
              display: block;
            }
          }
        }
      }
    }
    //
    //
  }
}
