/*---------Poppins-Light---------*/
@font-face {
  src: url(../fonts/Poppins-Light.ttf);
  font-family: "Poppins-Light";
}
/*---------PoppinsThin---------*/
@font-face {
  src: url(../fonts/Poppins-Thin.ttf);
  font-family: "PoppinsThin";
}
/*--------- CASTA ---------*/
@font-face {
  src: url(../fonts/Casta-Thin.otf);
  font-family: "Casta-Thin";
}
/*---------PoppinsThin---------*/
@font-face {
  src: url(../fonts/Casta-ThinSlanted.otf);
  font-family: "Casta-Thin-oblique";
}

/*---------FJORD----------------------*/
@font-face {
  src: url(../fonts/FjordOne-Regular.ttf);
  font-family: "Fjord One", serif;
}
/*---------LATO----------------------*/
@font-face {
  src: url(../fonts/Lato-Regular.ttf);
  font-family: "Lato-Regular";
}

@font-face {
  src: url(../fonts/Lato-Light.ttf);
  font-family: "Lato-light";
}

/*---------Briar----------------------*/
@font-face {
  src: url(../fonts/BriarDisplay.woff);
  font-family: "BriarDisplay";
}
@font-face {
  src: url(../fonts/BriarNeue.woff);
  font-family: "BriarNeue";
}
/*---------Syncopate---niceo-ne------------------*/
@font-face {
  src: url(../fonts/Syncopate-Regular.ttf);
  font-family: "Syncopate-Regular";
}

@font-face {
  src: url(../fonts/Syncopate-Bold.ttf);
  font-family: "Syncopate-Bold";
}

/*---------Rajdhani----------------------*/

@font-face {
  src: url(../fonts/Rajdhani-Medium.ttf);
  font-family: "Rajdhani-Medium";
}

//
/*---------Kenoky----------------------*/

@font-face {
  src: url(../fonts/KenokyLight.ttf);
  font-family: "Kenoky-Light";
}
/*---------NEUTRALFAcE----------------------*/

// bold
@font-face {
  src: url(../fonts/NeutralFace-Bold.otf);
  font-family: "NeutralFace-Bold";
}
// light
@font-face {
  src: url(../fonts/NeutralFace.otf);
  font-family: "NeutralFace-light";
}

/*---------Wagon ----------------------*/

@font-face {
  src: url(../fonts/Wagon-Bold.otf);
  font-family: "Wagon-Bold";
}
@font-face {
  src: url(../fonts/Wagon-ExtraLight.otf);
  font-family: "Wagon-ExtraLight";
}
@font-face {
  src: url(../fonts/Wagon-ExtraLightItalic.otf);
  font-family: "Wagon-ExtraLightItalic";
}
/*---------NEUE METANA ----------------------*/

@font-face {
  src: url(../fonts/NeueMetana-Bold.otf);
  font-family: "NeueMetana-Bold";
}
//

@font-face {
  src: url(../fonts/NeueMetana-Regular.otf);
  font-family: "NeueMetana-Regular";
}
/*------- Noto -------*/
/*------- Noto -------*/

@font-face {
  src: url(../fonts/NotoSans_Condensed-Bold.ttf);
  font-family: "NotoSans-Condensed-Bold";
}
@font-face {
  src: url(../fonts/NotoSans_Condensed-BoldItalic.ttf);
  font-family: "NotoSans_Condensed-BoldItalic";
}
@font-face {
  src: url(../fonts/NotoSans-Regular.ttf);
  font-family: "NotoSans-Regular";
}
// ------------------------------
//   ../fonts/MATAO FreeDemo.otf
@font-face {
  src: url(../fonts/MATAO-Free-Demo.otf);
  font-family: "Matao-Regular";
}
