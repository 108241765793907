@import "../../scss/font-sizes-memento_page";

//
//
//
#memento-page-wrapper {
  background-color: rgb(11, 11, 11);
  padding-top: 50px;
  /* 3 images at the bottom of the home page */

  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  //
  // ------------
  // ------------ 3D card
  // ------------
  .banner3d {
    width: 100%;
    height: auto;
    padding: 100px 0 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    perspective: 800px;

    .card-memento {
      border-radius: 30px;

      min-height: var(--min-height);
      width: var(--min-width);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .bannerImgBox {
        width: 100%;
        border: 6px solid #000;

        //-------------**
        //-------------**

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          aspect-ratio: 0.7/1;
        }
      }
    }
  }

  // ------------
  // ------------

  .memento-page_container-description {
    // background-color: pink;
    padding: 50px 40px 300px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // **  -------- * --------
    //  MEDIA queries
    @include memento-page_container-description;
    // **  -------- * --------
    // **  -------- * --------
    //
    //
    //
    //
    //
    //------------
    // ** MEMENTO
    h1 {
      color: #fffef9f8;
      letter-spacing: -7px;
      font-weight: 600;
      // font-family: "NotoSans-Condensed-Bold";
      font-family: "Kenoky-Light";
      padding: 30px 0;
      text-transform: uppercase;
      font-size: calc(140px + 1.1vmin);
      line-height: calc(40px + 1.1vmin);
      // **  -------- * --------
      //  MEDIA queries
      @include Headline_H1_mementoPage-desc;
      // **  -------- * --------
      // **  -------- * --------
      //
      // ** SPAN (mori)
      .memento-page-span {
        letter-spacing: -1px;
        font-family: "Poppins-Light";
        font-weight: 200;
        font-size: calc(15px + 1.1vmin);

        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          // background-color: rgb(193, 220, 217);
          letter-spacing: 1px;
          font-size: calc(9px + 1.1vmin);
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          // background-color: turquoise;
          letter-spacing: 1px;
          font-size: calc(10px + 1.1vmin);
        }

        // -------- **
        // -------- **

        // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          // background-color: orangered;
          letter-spacing: 1px;
          font-size: calc(9px + 1.1vmin);
        }
        //
        // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          // background-color: rgb(204, 144, 32);
          letter-spacing: 1px;
          font-size: calc(9px + 1.1vmin);
        }
        //-------------**
        //-------------**
        // TABLET2 - PORTRAIT
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          // background-color: rgb(162, 255, 0);
          letter-spacing: 1px;
          font-size: calc(8px + 1.1vmin);
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          // background-color: rgb(211, 208, 182);
          letter-spacing: 1px;
          font-size: calc(8px + 1.1vmin);
        }

        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT  ( row , desktop > )
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          // background-color: purple;
          font-size: calc(8px + 1.1vmin);
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          // background-color: olivedrab;
          font-size: calc(8px + 1.1vmin);
        }
        //-------------**
        //-------------**

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          // background-color: pink;
          font-size: calc(8px + 1.1vmin);
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          // background-color: plum;
          font-size: calc(8px + 1.1vmin);
        }
        //-------------**
        //-------------**
      }
    }
    //----
    // ** H2
    .memento-page-H2-desc {
      padding: 50px 0px 50px 0px;
      // min-width: 1400px;
      // max-width: 2300px;
      //
      width: 60%;
      //
      font-family: "Lato-Regular";
      color: #fffef9f8;
      width: 70%;
      letter-spacing: -1px;
      font-weight: 100;
      font-size: calc(45px + 1.1vmin);

      // **  -------- * --------
      //  MEDIA queries
      @include Headline_H2_mementoPage-desc;
      // **  -------- * --------
      // **  -------- * --------
      //
    }

    // ----- P -----------
    .memento-page-paragraph_1,
    .memento-page-paragraph_2,
    .memento-page-paragraph_3 {
      width: 50%;
      // min-width: 1400px;
      // max-width: 1800px;
      // color: rgb(123, 123, 123);
      color: #fffef984;
      padding: 10px 0px;

      letter-spacing: 1px;
      font-size: 100;
      font-family: "Poppins-Light";
      font-size: calc(22px + 1.1vmin);
      line-height: calc(38px + 1.1vmin);

      // **  -------- * --------
      //  MEDIA queries
      @include Headline_P_mementoPage-desc;
      // **  -------- * --------
      // **  -------- * --------
      //
    }
  }

  //
  //

  .containerMemento-images-random {
    // --------
    overflow: hidden;
    margin: 0 auto; // to center the whole container
    width: 100%;
    height: 100%;

    // ** GRID

    display: grid;
    grid-template-columns: 0.3fr 0.5fr 0.3fr; //0.6fr 1fr 0.4fr Here, you specify the columns with different widths, another option: 1fr 1fr 1fr 1fr;, will create 4 columns of the same size, but its not cool for mobile
    gap: 120px; // Adjust the gap between the boxes
    //
    //
    // ---------------
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
      // background-color: rgb(220, 202, 193);
      width: 100%;
      grid-template-columns: 1fr;
      gap: 50px;
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
    @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
      // background-color: rgb(160, 95, 158);
      width: 100%;
      grid-template-columns: 1fr 0.6fr;
      gap: 80px;
    }
    //-------------**
    //-------------**
    // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
      // background-color: orangered;
      width: 100%;
      grid-template-columns: 1fr 0.6fr;
      gap: 80px;
    }
    //
    // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
    @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
      // background-color: rgb(143, 225, 225);
      width: 100%;
      grid-template-columns: 0.8fr 0.6fr 1fr;
      gap: 80px;
    }
    //-------------**
    //-------------**
    // TABLET2 - PORTRAIT 994 x 1367
    @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
      // background-color: burlywood;
      width: 100%;
      grid-template-columns: 0.6fr 0.9fr;
      gap: 80px;
    }

    // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
    @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
      width: 100%;
      grid-template-columns: 0.6fr 0.9fr 0.3fr;
      gap: 80px;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT (1379 x 1500)
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      // background-color: rgb(46, 46, 9);
      padding: 0 50px;
      width: 100%;
      grid-template-columns: 0.6fr 0.9fr;
      gap: 80px;
    }

    // DESKTOP - LANDSCAPE (1440 x 900) Desktop Laptop
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      // background-color: olivedrab;
      width: 100%;
      grid-template-columns: 0.6fr 0.9fr;
      gap: 80px;
    }
    //-------------**
    //-------------**

    // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
    @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
      // background-color: rgb(128, 53, 65);

      width: 100%;
      grid-template-columns: 0.5fr 0.3fr 0.7fr;
      gap: 20px;
    }
    // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
    @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
      // background-color: plum;
      width: 100%;
      grid-template-columns: 0.5fr 0.3fr 0.7fr;
      gap: 20px;
    }

    //
    //
    // ---------------
    //
    //
    .mediaMemento-wrapper {
      // background-color: rgba(80, 175, 45, 0.695);
      width: calc(var(--img-width) * 2%);
      margin-left: calc(var(--image-margin) * 1%);
      //   width: 98%;

      //
      // ** UL
      .wrapperMemento-img-random-card {
        //   background-color: rgb(202, 120, 32);

        width: 99%;

        //  ** LI
        .containerMemento-img-random {
          overflow: hidden;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: 0.58/1;
            display: block;
          }

          //
          //
          // ------------
          //  Text about img
          // ------------
          .desc_drawing-memento-wrapper {
            // background-color: lavender;

            display: flex;
            justify-content: space-between;
            align-items: center;
            word-spacing: 2px;

            h1 {
              padding: 8px 0;
              margin: 0;
              letter-spacing: -1px;
              font-size: calc(9px + 1.1vmin);
              font-weight: 500;
              color: #4b4b4b9d;

              // SPAN
              .desc_drawing-memento-date {
                color: rgb(123, 123, 123);
                font-weight: 200;
                letter-spacing: -1px;
                font-size: calc(6px + 1.1vmin);
                margin: 0 20px;
              }
            }
          }
        }
      }
    }

    //
    //
  }
}
