#wrapper__archComponent {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .container___archComponent {
    width: 100%;
    height: 100%;

    .wrapper-Grid_Top_About {
      width: 100%;
      max-width: 2000px;
      height: 100%;

      padding: 180px 0 60px 0;

      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      background-color: rgb(207, 207, 207);

      border-top: solid #000000d6;
      border-top-width: 3px;

      border-top-right-radius: 500px;
      border-top-left-radius: 500px;

      border-left: solid #000000d6;
      border-left-width: 3px;

      border-right: solid #000000d6;
      border-right-width: 3px;

      border-bottom: solid #000000d6;
      border-bottom-width: 3px;
      border-bottom-left-radius: 280px;
      border-bottom-right-radius: 280px;

      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        padding: 150px 0 0px 0;
        width: 97%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        padding: 150px 0 0px 0;
        width: 97%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        margin-top: 30px;
        padding: 180px 0 0px 0;
        width: 97%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        margin-top: 30px;
        padding: 180px 0 0px 0;
        width: 97%;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        width: 90%;
        padding: 180px 0 0px 0;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        width: 90%;
        padding: 180px 0 0px 0;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        width: 90%;
        padding: 180px 0 0px 0;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        width: 92%;
        padding: 180px 0 0px 0;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 2800px;
        border-top-left-radius: 2800px;
      }

      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        width: 95%;
        padding: 180px 0 0px 0;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        width: 85%;
        padding: 180px 0 0px 0;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      overflow: hidden;

      .container_Grid_Top_About {
        border-bottom: solid #000000;
        border-bottom-width: 80px;

        display: grid;
        width: 85%;
        height: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0.2px;

        padding: 150px 50px 20px 50px;

        margin: 20px 0;

        border-top: 0;
        border-bottom: 0px;

        border-left: 0;
        border-right: 0;

        border-top-left-radius: 500px;
        border-top-right-radius: 500px;
        border-bottom-left-radius: 500px;
        border-bottom-right-radius: 500px;

        border-bottom: solid #d4d9eb;
        border-bottom-width: 30px;

        border-top: solid #d4d9eb;
        border-top-width: 30px;

        border-left: solid #d4d9eb;
        border-left-width: 1px;
        border-right: solid #d4d9eb;
        border-right-width: 1px;

        box-shadow: 0 0 0 2px transparent, inset 0 1px 0 5px #000000;

        position: relative;

        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          width: 100%;
          grid-template-columns: 1fr;
          padding: 10px 0px 0px 0px;
        }

        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          width: 100%;
          grid-template-columns: 1fr;
          padding: 10px 0px 0px 0px;
        }

        // tablet 1 💓
        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          grid-template-columns: 1fr;
          grid-template-columns: 0.3fr 1fr 0.3fr;
          width: 95%;
        }

        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          grid-template-columns: 1fr;
          grid-template-columns: 0.3fr 1fr 0.3fr;
          width: 95%;
        }

        // /tablet 2
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          grid-template-columns: 0.3fr 1fr 0.3fr;
        }

        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          grid-template-columns: 0.3fr 1fr 0.3fr;
        }
        //  ----- desk
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          grid-template-columns: 0.3fr 0.5fr 0.3fr;
          grid-gap: 0.2px;
          width: 90%;
        }

        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          grid-gap: 0.2px;
          grid-template-columns: 0.3fr 0.5fr 0.3fr;
          width: 90%;
        }

        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          grid-template-columns: 1fr 1fr 1fr;
        }

        //
        //
        //
        // ------------------
        //** 🥚  EGG center bottom 1
        // ------------------
        //

        .star-absolute1 {
          background-color: rgb(207, 207, 207);

          position: absolute;
          top: 7%;
          left: 50%;
          z-index: 777;
          transform: translateY(-7%);
          transform: translateX(-50%);

          // egg radius
          border-radius: 50%;
          border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
          padding: 30px;
          border: 2px solid #000;

          p {
            font-size: calc(70px + 1.1vmin);
            color: #111;
          }

          //
          //
          // ---------  absolute EGG top

          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            top: -4%;
            left: 50%;
            transform: translateY(-4%);
            transform: translateX(-50%);
            padding: 15px;
            p {
              font-size: calc(17px + 1.1vmin);
            }
          }

          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            transform: translateY(5%);

            top: -2%;
            left: 50%;
            transform: translateY(-2%);
            transform: translateX(-50%);

            padding: 15px;
            p {
              font-size: calc(17px + 1.1vmin);
            }
          }

          // tablet 1
          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            top: -5%;
            left: 50%;
            transform: translateY(-5%);
            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }

          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            top: 16%;
            left: 50%;
            transform: translateY(-16%);
            transform: translateX(-50%);
            padding: 15px;
            p {
              font-size: calc(17px + 1.1vmin);
            }
          }

          // tablet 2
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            top: -5%;
            left: 50%;
            transform: translateY(-5%);
            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }

          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            top: 12%;
            left: 50%;
            transform: translateY(-12%);
            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }
          //  ---- desk
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            top: 12%;
            left: 50%;
            transform: translateY(-12%);
            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }

          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            top: 12%;
            left: 50%;
            transform: translateY(-12%);
            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }

          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            top: 12%;
            left: 50%;
            transform: translateY(-12%);
            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }

          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            top: 12%;
            left: 50%;
            transform: translateY(-12%);
            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }
        }

        //
        // ------------------
        //** 🥚  EGG center bottom 2
        // ------------------
        //
        .stari-absolute2 {
          background-color: rgb(207, 207, 207);
          border: 2px solid #000;
          position: absolute;
          top: 90%;
          left: 50%;
          transform: translateY(-90%);
          transform: translateX(-50%);
          z-index: 777;
          border-radius: 50%;
          border-radius: 50% 50% 50% 50% / 60% 60% 40% 40%;
          padding: 30px;

          p {
            font-size: calc(50px + 1.1vmin);
            color: #111;
          }

          //
          //  ------ media queries
          //
          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            top: 90%;
            transform: translateY(-90%);
            left: 50%;

            transform: translateX(-50%);
            p {
              font-size: calc(15px + 1.1vmin);
            }
          }

          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            top: 93%;
            transform: translateY(-93%);
            left: 50%;

            transform: translateX(-50%);
            p {
              font-size: calc(20px + 1.1vmin);
            }
          }

          // tablet 1
          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            top: 90%;
            transform: translateY(-90%);
            left: 50%;

            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }

          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            top: 90%;
            transform: translateY(-90%);
            left: 50%;

            transform: translateX(-50%);
            padding: 15px;
            p {
              font-size: calc(17px + 1.1vmin);
            }
          }

          // tablet 2
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            top: 90%;
            transform: translateY(-90%);
            left: 50%;

            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }

          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            top: 90%;
            transform: translateY(-90%);
            left: 50%;

            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }
          //  ---- desk
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            top: 93%;
            transform: translateY(-93%);
            left: 50%;

            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }

          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            top: 93%;
            transform: translateY(-93%);
            left: 50%;

            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }

          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            top: 90%;
            transform: translateY(-90%);
            left: 50%;

            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }

          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            top: 90%;
            transform: translateY(-90%);
            left: 50%;

            transform: translateX(-50%);
            padding: 20px;
            p {
              font-size: calc(25px + 1.1vmin);
            }
          }
        }

        //
        //
        //
        //
        .star-left_tryptic_arch-img,
        .star-right_tryptic_arch-img {
          border-bottom-right-radius: 2800px;
          border-bottom-left-radius: 2800px;
          border-top-left-radius: 2800px;
          border-top-right-radius: 2800px;
          display: flex;
          justify-content: center;
          align-items: center;

          // --------
          box-shadow: 0 0 0 10px #cfd3d8, inset 0 0 0 -200px transparent;
          filter: drop-shadow(5px 15px 7px rgb(35, 35, 35));
          // ---------

          //
          //
          //
        }

        .star-left_tryptic_arch-img {
          display: flex;
          justify-content: center;
          align-items: center;

          background-color: #d4d9eb;

          border: 2px solid #000;

          // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            display: none;
          }
          // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            display: none;
          }
          // ------
          // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            display: flex;
          }
          //
          // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            display: flex;
          }

          // TABLET2 - PORTRAIT
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          }

          // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          }

          // DESKTOP - PORTRAIT
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            width: 70%;
            margin: 0 auto;
          }

          // DESKTOP - LANDSCAPE
          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            width: 70%;
            margin: 0 auto;
          }

          // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          }
          // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          }
        }

        .star-right_tryptic_arch-img {
          background-color: #d4d9eb;

          display: flex;
          justify-content: center;
          align-items: center;

          border: 2px solid #000;
          background-color: #d4d9eb;

          // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            display: none;
          }
          // MOBILE - LANDSCAPE 663 x 662 ( google responsive ) or 568 x 320 responsiveTool, but to reach it you have to first look for the 375 x 667 then rotate the phone on their option
          @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
            display: none;
          }
          // ------
          // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
            display: flex;
          }
          //
          // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
            display: flex;
          }

          // TABLET2 - PORTRAIT
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          }

          // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            width: 70%;
          }

          // DESKTOP - PORTRAIT
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            width: 70%;
            margin: 0 auto;
          }

          // DESKTOP - LANDSCAPE
          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            width: 70%;
            margin: 0 auto;
          }

          // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
            width: 70%;
          }
          // DESKTOP large / 1920 x 1075 (mine) - LANDSCAPE
          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
            width: 70%;
          }
        }

        .wrapper__img_topAbout {
          width: 90%;
          height: 100%;
          margin: 0 auto;
          border: 2px solid #000;
          background-color: none;
          //😀
          border-top-left-radius: 500px;
          border-top-right-radius: 500px;
          border-bottom-left-radius: 500px;
          border-bottom-right-radius: 500px;

          box-shadow: 0 0 0 10px #d7e1ee, inset 0 0 0 200px rgb(255, 235, 213);
          filter: drop-shadow(5px 15px 7px rgb(35, 35, 35));
          overflow: hidden;

          @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
            width: 75%;
          }

          @media only screen and (max-height: 675px) and (min-width: 170px) and (orientation: landscape) {
            width: 75%;
          }

          @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          }

          @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          }

          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          }

          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          }

          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          }

          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          }

          @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          }

          @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          }

          //
          //
          //
          .container__img_topAbout {
            width: 100%;
            padding: 70px 0;
            background-color: #d4d9eb;

            margin: 0 auto;

            // -----------
            //
            // overflow: hidden;
            // -----------

            // -------- **
            .imgBox_topAbout {
              width: 70%;
              padding: 20px;

              margin: 0 auto;

              border-top-right-radius: 500px;
              border-top-left-radius: 500px;
              border-bottom-right-radius: 500px;
              border-bottom-left-radius: 500px;
              // background-color: rgb(0, 0, 0);

              // border: 2px solid #111;
              border-bottom-width: 10px;
              border-top-width: 10px;

              //
              // -----------
              //
              // overflow: hidden;
              // -----------

              @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                width: 85%;
              }

              @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                width: 85%;
              }

              @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                width: 80%;
              }

              @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                width: 80%;
                border-top-width: 12px;
                border-top-left-radius: 500px;
                border-top-right-radius: 500px;
                border-bottom-left-radius: 500px;
                border-bottom-right-radius: 500px;
                border-bottom-width: 7px;
              }

              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                width: 100%;
              }

              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                width: 100%;
              }

              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                width: 70%;

                border-top-width: 12px;
                border-top-left-radius: 500px;
                border-top-right-radius: 500px;
                border-bottom-left-radius: 500px;
                border-bottom-right-radius: 500px;
                border-bottom-width: 7px;
              }

              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                width: 70%;

                border-top-width: 12px;
                border-top-left-radius: 500px;
                border-top-right-radius: 500px;
                border-bottom-left-radius: 500px;
                border-bottom-right-radius: 500px;
                border-bottom-width: 7px;
              }

              @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                width: 85%;
              }

              @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                width: 85%;
              }

              img {
                cursor: pointer;
                border-radius: 800px;
                width: 100%;
                height: 100%;
                object-fit: cover;

                border: 3px solid #000;
                box-shadow: 0 0 0 10px #cfd3d8, inset 0 0 0 -200px transparent;

                aspect-ratio: 0.65/1;
                display: block;
              }
            }
          }
        }
      }

      .wrapper-description__about-screen {
        margin: 0 auto;
        width: 100%;
        height: 100%;
        // border-bottom: 0px solid #b71f1f;
        padding: 30px 0 0 0;

        .container-text-pattern-1 {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin: 0 auto;
          width: 100%;

          .headlineH1_3box_text {
            // border-bottom: 0px solid #b71f1f;
            // background-color: #000;

            //
            margin: 0 auto;
            display: grid;
            width: 100%;
            height: 100%;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0.2px;

            @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
              grid-template-columns: 0.3fr 1fr 0.3fr;

              border-bottom: 1px solid transparent;
            }

            @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
              grid-template-columns: 0.3fr 1fr 0.3fr;

              border-bottom: 1px solid transparent;
            }

            @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
              grid-template-columns: 0.3fr 1fr 0.3fr;
            }

            @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
              grid-template-columns: 0.3fr 1fr 0.3fr;
            }

            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              grid-template-columns: 0.3fr 1fr 0.3fr;
            }

            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              grid-template-columns: 0.3fr 1fr 0.3fr;
            }

            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              grid-template-columns: 1fr 2fr 1fr;
              grid-gap: 0.2px;
            }

            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              grid-gap: 0.2px;
              grid-template-columns: 1fr 2fr 1fr;
            }

            @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
              grid-template-columns: 1fr 1fr 1fr;
            }

            @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
              grid-template-columns: 1fr 1fr 1fr;
            }

            .headlineBlock_a,
            .headlineBlock_b {
              background-color: #cdd0d5;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: calc(20px + 1.1vmin);
            }

            .headlineBlock_a {
              border: 1px solid #040404;
              border-top-left-radius: 300px;

              border-top-right-radius: 150px;
              border-bottom-right-radius: 150px;

              // shadow to the RIGHT
              filter: drop-shadow(5px 15px 7px rgb(35, 35, 35));
            }
            .headlineBlock_b {
              border: 1px solid #040404;
              border-top-right-radius: 300px;

              border-top-left-radius: 150px;
              border-bottom-left-radius: 150px;

              // shadow to the LEFT
              filter: drop-shadow(-5px 15px 7px rgb(35, 35, 35));
            }

            .h1-box-3box {
              background-color: #cdd0d5;
              border: 1px solid #040404;
              border-radius: 150px;

              h1 {
                margin: 10px 0;
                text-align: center;
                align-self: center;

                font-family: "Matao-Regular";
                font-family: "Kenoky-Light";

                color: #0e0e0efc;
                font-weight: 500;
                letter-spacing: 1px;
                font-size: calc(45px + 1.1vmin);
                line-height: calc(45px + 1.1vmin);

                @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
                  font-weight: 600;
                  letter-spacing: -0.1px;
                  font-size: calc(23px + 1.1vmin);
                  line-height: calc(34px + 1.1vmin);

                  text-align: center;
                  align-self: center;
                }

                @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
                  text-align: center;
                  align-self: center;
                  font-weight: 600;
                  letter-spacing: -0.2px;
                  font-size: calc(30px + 1.1vmin);
                  line-height: calc(60px + 1.1vmin);
                }

                @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
                  text-align: center;
                  align-self: center;
                  font-weight: 600;
                  letter-spacing: -0.2px;
                  font-size: calc(30px + 1.1vmin);
                  line-height: calc(50px + 1.1vmin);
                }

                @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
                  // background-color: rgb(143, 225, 225);
                  text-align: center;
                  align-self: center;
                  font-weight: 600;
                  font-size: calc(30px + 1.1vmin);
                  line-height: calc(50px + 1.1vmin);
                }

                @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                  text-align: center;
                  align-self: center;
                  font-weight: 600;
                  letter-spacing: -0.2px;
                  font-size: calc(44px + 1.1vmin);
                  line-height: calc(50px + 1.1vmin);
                }

                @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                  font-weight: 600;
                  letter-spacing: -0.2px;
                  font-size: calc(44px + 1.1vmin);
                  line-height: calc(50px + 1.1vmin);
                }

                @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                  font-weight: 600;
                  text-align: center;
                  align-self: center;
                  letter-spacing: -0.2px;
                  font-size: calc(34px + 1.1vmin);
                  line-height: calc(50px + 1.1vmin);
                }

                @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                  text-align: center;
                  align-self: center;
                  font-weight: 600;
                  letter-spacing: -0.2px;
                  font-size: calc(34px + 1.1vmin);
                  line-height: calc(50px + 1.1vmin);
                }

                @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
                  text-align: center;
                  align-self: center;
                  font-weight: 600;
                  letter-spacing: -0.2px;

                  font-size: calc(34px + 1.1vmin);
                  line-height: calc(50px + 1.1vmin);
                }

                @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
                  text-align: center;
                  align-self: center;
                  font-weight: 600;
                  letter-spacing: 1px;
                  font-size: calc(34px + 1.1vmin);
                  line-height: calc(50px + 1.1vmin);
                }
              }
            }
          }
        }
      }
    }

    //
    //
    //
    //
    //
    //
    // ------------ 🌽
    .container-P_arch {
      padding: 180px 50px 100px 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
        padding: 70px 10px 100px 10px;
      }

      @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
        padding: 70px 30px 100px 30px;
      }

      @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
        padding: 100px 30px 100px 30px;
      }

      @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
        padding: 90px 30px 100px 30px;
      }

      @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        padding: 100px 30px 100px 30px;
      }

      @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        padding: 100px 30px 100px 30px;
      }

      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        padding: 150px 50px 100px 50px;
      }

      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        padding: 150px 50px 100px 50px;
      }

      @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        padding: 180px 50px 100px 50px;
      }
      @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        padding: 180px 50px 100px 50px;
      }

      H1 {
        margin: 0 0 70px 0;
        width: 80%;
        max-width: 1700px;
        text-align: center;
        color: #191919af;
        font-weight: 500;
        font-size: calc(35px + 1vmin);
        line-height: calc(45px + 1vmin);
        font-style: oblique;

        .underlineP-pattern {
          font-weight: 500;
          font-style: oblique;
          font-family: "Fjord One", serif;
          letter-spacing: -0.1px;

          color: #191919c6;
          font-size: calc(40px + 1vmin);
          margin: 0 15px;

          border-bottom: solid #191919d6;
          border-bottom-width: 0.05em;
        }

        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          width: 90%;
          max-width: 90%;
          font-size: calc(20px + 1vmin);
          line-height: calc(32px + 1vmin);

          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(24px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          width: 75%;
          max-width: 75%;
          font-size: calc(22px + 1vmin);
          line-height: calc(35px + 1vmin);

          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(25px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          width: 90%;
          max-width: 90%;
          font-size: calc(25px + 1vmin);
          line-height: calc(42px + 1vmin);

          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(28px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          width: 75%;
          max-width: 75%;
          font-size: calc(25px + 1vmin);
          line-height: calc(43px + 1vmin);

          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(28px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          width: 92%;
          max-width: 92%;
          font-size: calc(35px + 1vmin);
          line-height: calc(45px + 1vmin);

          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(38px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          width: 85%;
          max-width: 85%;
          font-size: calc(20px + 1vmin);
          line-height: calc(30px + 1vmin);

          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(23px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          width: 92%;
          max-width: 1300px;
          font-size: calc(37px + 1vmin);
          line-height: calc(45px + 1vmin);
        }

        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          width: 92%;
          max-width: 1000px;

          font-size: calc(35px + 1vmin);
          line-height: calc(48px + 1vmin);
          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(38px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          width: 92%;
          max-width: 1200px;
          font-size: calc(35px + 1vmin);
          line-height: calc(45px + 1vmin);
        }

        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          width: 92%;
          max-width: 1200px;
          font-size: calc(35px + 1vmin);
          line-height: calc(45px + 1vmin);
        }
      }

      p {
        margin: 0 auto;
        width: 60%;
        max-width: 1400px;
        text-align: justify;

        color: #303030b2;

        font-weight: 400;
        font-size: calc(13px + 1vmin);
        line-height: calc(28px + 1vmin);
        font-style: oblique;

        .underlineP-pattern {
          font-weight: 500;
          font-style: oblique;
          font-family: "Fjord One", serif;
          color: #303030c0;

          font-size: calc(16px + 1vmin);
          margin: 0 15px;

          border-bottom: solid #191919d6;
          border-bottom-width: 0.05em;
        }

        @media only screen and (max-width: 680px) and (min-width: 170px) and (orientation: portrait) {
          text-align: justify;
          width: 90%;
          max-width: 90%;

          font-size: calc(17px + 1vmin);
          line-height: calc(28px + 1vmin);
          color: #191919ba;

          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(18px + 1vmin);

            border-bottom-width: 0.08em;
            color: #191919ba;
          }
        }

        @media only screen and (max-height: 680px) and (min-width: 170px) and (orientation: landscape) {
          text-align: justify;

          width: 75%;
          max-width: 75%;
          font-size: calc(17px + 1vmin);
          line-height: calc(30px + 1vmin);

          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(19px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-width: 992px) and (min-width: 681px) and (orientation: portrait) {
          text-align: center;

          width: 75%;
          max-width: 75%;
          font-size: calc(19px + 1vmin);
          line-height: calc(38px + 1vmin);

          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(22px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-height: 992px) and (min-width: 681px) and (orientation: landscape) {
          text-align: center;

          width: 75%;
          max-width: 75%;
          font-size: calc(17px + 1vmin);
          line-height: calc(30px + 1vmin);

          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(19px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          text-align: center;
          width: 75%;
          max-width: 75%;
          font-size: calc(25px + 1vmin);
          line-height: calc(45px + 1vmin);

          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(29px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          width: 75%;
          max-width: 75%;
          font-size: calc(19px + 1vmin);
          line-height: calc(40px + 1vmin);

          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(21px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          max-width: 700px;
          font-size: calc(37px + 1vmin);
          line-height: calc(45px + 1vmin);
        }

        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          width: 65%;
          max-width: 700px;
          font-size: calc(15px + 1vmin);
          line-height: calc(30px + 1vmin);
          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(18px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }

        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
          font-size: calc(15px + 1vmin);
          line-height: calc(25px + 1vmin);
          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(18px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
          width: 80%;
          max-width: 900px;
          font-size: calc(18px + 1vmin);
          line-height: calc(32px + 1vmin);
          .underlineP-pattern {
            margin: 0 5px;
            font-size: calc(20px + 1vmin);

            border-bottom-width: 0.08em;
          }
        }
      }
    }
  }
}
